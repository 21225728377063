import React from "react";
import {
  ButtonWrap,
  Radio,
  Input,
  Top,
  TopTitle,
  IconWrap,
  DetailsWrap,
  LeftD,
  Text,
  RightD,
  FieldWrapD,
} from "./style";
import GeneralModal from "../../components/Modal/GeneralModal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import PrimaryButton from "../../components/Buttons/Button";

interface SpecialModalProps {
  handleClose: any;
  selectedType: any;
  orderID: any;
  accountNumber: any;
  modalDetails: any;
  handleRadioChange: any;
  isLoading: any;
  isDisabled: any;
  submitForm: any;
}
const specialModal = ({
  handleClose,
  selectedType,
  orderID,
  accountNumber,
  modalDetails,
  handleRadioChange,
  isLoading,
  isDisabled,
  submitForm,
}: SpecialModalProps) => {
  return (
    <GeneralModal general>
      <Top>
        <TopTitle>Special Collection Information</TopTitle>
        <IconWrap>
          <CloseIcon onClick={handleClose} />
        </IconWrap>
      </Top>
      <FieldWrapD>
        {selectedType !== "No ID" && (
          <DetailsWrap>
            <LeftD>
              <Text>Order Id</Text>{" "}
            </LeftD>
            <RightD>
              <Text>{orderID}</Text>{" "}
            </RightD>
          </DetailsWrap>
        )}

        <DetailsWrap>
          <LeftD>
            <Text>Amount</Text>{" "}
          </LeftD>
          <RightD>
            <Text>
              {" "}
              {new Intl.NumberFormat("ja-JP", {
                style: "currency",
                currency: modalDetails?.currency,
              }).format(modalDetails?.amount as any)}
            </Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Currency</Text>{" "}
          </LeftD>
          <RightD>
            <Text>{modalDetails?.currency}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Teller Number</Text>{" "}
          </LeftD>
          <RightD>
            <Text>{modalDetails?.tellerNumber}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Account Number</Text>{" "}
          </LeftD>
          <RightD>
            <Text>{accountNumber}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Depositor</Text>{" "}
          </LeftD>
          <RightD>
            <Text>{modalDetails?.depositorName}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Phone Number</Text>{" "}
          </LeftD>
          <RightD>
            <Text> {modalDetails?.depositorPhoneNo}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Product Type</Text>{" "}
          </LeftD>
          <RightD>
            <Text> {modalDetails?.productType}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Narration</Text>{" "}
          </LeftD>
          <RightD>
            <Text> {modalDetails?.narration}</Text>{" "}
          </RightD>
        </DetailsWrap>
        <DetailsWrap>
          <LeftD>
            <Text>Remark</Text>{" "}
          </LeftD>
          <RightD>
            <Text> {modalDetails?.remark}</Text>{" "}
          </RightD>
        </DetailsWrap>
      </FieldWrapD>
      <Radio>
        <Input
          type="radio"
          id="html"
          name="fav_language"
          value="acc"
          onChange={handleRadioChange}
        />
         {" "}
        <label>
          I have read through all details displayed above and have ensured the
          information is accurate.
        </label>
      </Radio>
      <ButtonWrap>
        <div>
          <PrimaryButton
            background={"#5c2684"}
            color={"#fff"}
            width={100}
            title={"Post Transaction"}
            icon={""}
            isLoading={isLoading}
            loading={false}
            isDisabled={isDisabled}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={submitForm}
            leftIcon={false}
            border={""}
          />
        </div>
      </ButtonWrap>
    </GeneralModal>
  );
};

export default specialModal;
