import styled from "styled-components";
interface cardProps {
  width: string;
}
export const CardComponent = styled.div<cardProps>`
  background: white;
  width: ${(props) => props.width};
  border-radius: 20px;
  margin-bottom: 24px;
  color: #2d2235;

  &:hover {
    background: #5c2684;
    cursor: pointer;
    color: white !important;
  }
`;
export const CardWrap = styled.div`
  padding: 0px 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;
export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 29px;
  line-height: 120%;
  color: #2d2235;
`;
export const CardContents = styled.div`
  padding: 20px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
export const Bold = styled.b`
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
`;
export const CardTitle = styled.p`
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
`;
export const CardPrice = styled.p`
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
`;
export const CardImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
`;
export const Image = styled.img`
  width: 100%;
  height: auto;
`;
export const CardCount = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CardCountSpan = styled.span`
  font-weight: 400;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
`;
export const CountWrap = styled.div`
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #5c2684;
  justify-content: center;
  width: 30px;
  padding: 2px 9px;
`;
