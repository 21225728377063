import React, { useState, useEffect } from "react";
import PrimaryInput from "../../components/PrimaryInput";
import { FieldWrap } from "../CashDeposit/style";
import { ModalHeaderText, UserButtonWrap } from "./styles/styles";
import PrimaryButton from "../../components/Buttons/Button";
import toast from "react-hot-toast";
import DropDownC from "../../components/PrimaryInput/dropDown";
import {
  approveOptions,
  ErrorHandler,
  updateRoleSchema,
} from "../../utils/config";
import { useUpdateRoleMutation } from "../../services/admin/adminService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const UpdateRole: React.FC<{
  id: string | number;
  roleList: any;
  setIsRoleUpdatedModal: any;
}> = ({ id, roleList, setIsRoleUpdatedModal }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateRoleSchema),
  });

  //states
  const [approve, setApprove] = useState("");
  const [optionValue, setOptionValue] = useState(false);

  //endpoint
  const [updateRole, { isLoading }] = useUpdateRoleMutation();

  useEffect(() => {
    const findDataById = roleList.find((role: any) => role.id === id);

    if (findDataById) {
      setValue("roleName", findDataById?.roleName, {
        shouldValidate: true,
      });
      setValue("roleDescription", findDataById?.roleDescription, {
        shouldValidate: true,
      });
      setValue("debitLimt", findDataById?.debitLimit, {
        shouldValidate: true,
      });
      setValue("debitLimtFCY", findDataById?.debitLimitFCY, {
        shouldValidate: true,
      });
      setValue("creditLimit", findDataById?.creditLimit, {
        shouldValidate: true,
      });
      setValue("creditLimitFCY", findDataById?.creditLimitFCY, {
        shouldValidate: true,
      });
    }
  }, [id, roleList, setValue]);

  const onSubmit = async (formData: any) => {
    const payload = {
      id,
      ...formData,
      canApprove: optionValue,
      tillAccount: !optionValue,
    };

    try {
      const response = await updateRole(payload).unwrap();
      if (response?.statusCode === 200) {
        toast.success(response?.message);
        setIsRoleUpdatedModal(false);
        // setSelectedRoles({
        //   value: "",
        //   label: "",
        // });
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  return (
    <section>
      <ModalHeaderText>Update Role</ModalHeaderText>
      <FieldWrap>
        <PrimaryInput
          name="roleName"
          type="text"
          placeholder="Role Name"
          rightIcon={<></>}
          error={errors?.roleName?.message}
          label="Role Name"
          register={register}
          value={undefined}
          onChange={() => {}}
        />
        <PrimaryInput
          name="roleDescription"
          type="text"
          placeholder="Role Description"
          register={register}
          rightIcon={<></>}
          error={errors?.roleDescription?.message}
          label="Role Description"
          value={undefined}
          onChange={() => {}}
        />
      </FieldWrap>
      <FieldWrap>
        <PrimaryInput
          name="debitLimt"
          type="number"
          inputMode="numeric"
          min={0}
          placeholder="Debit Limit"
          register={register}
          validator={true}
          rightIcon={<></>}
          error={errors?.debitLimt?.message}
          label="Debit Limit"
          value={undefined}
          onChange={() => {}}
        />
        <PrimaryInput
          name="debitLimtFCY"
          type="number"
          inputMode="numeric"
          min={0}
          placeholder="DebitLimit FCY"
          rightIcon={<></>}
          register={register}
          validator={true}
          error={errors?.debitLimtFCY?.message}
          label="DebitLimit FCY"
          value={undefined}
          onChange={() => {}}
        />
      </FieldWrap>
      <FieldWrap>
        <PrimaryInput
          name="creditLimit"
          type="number"
          min={0}
          inputMode="numeric"
          placeholder="Credit Limit"
          rightIcon={<></>}
          validator={true}
          register={register}
          error={errors?.creditLimit?.message}
          label="Credit Limit"
          value={undefined}
          onChange={() => {}}
        />
        <PrimaryInput
          name="creditLimitFCY"
          type="number"
          min={0}
          validator={true}
          inputMode="numeric"
          placeholder="Credit Limit FCY"
          rightIcon={<></>}
          error={errors?.creditLimitFCY?.message}
          label="Credit Limit FCY"
          register={register}
          value={undefined}
          onChange={() => {}}
        />
      </FieldWrap>
      <FieldWrap>
        <DropDownC
          value={approve}
          placeholder={"Select"}
          options={approveOptions}
          errorMessage={""}
          label={"Can Approve"}
          setSelectedItem={() => {}}
          setOptionValue={setOptionValue}
          setSelectedItems={setApprove}
        />
        <PrimaryInput
          value={!optionValue}
          placeholder={"Select"}
          label={"Till Account"}
          name={""}
          type={"input"}
          rightIcon={<></>}
          check={""}
          disabled={true}
          padding={""}
          leftIcon={<></>}
        />
      </FieldWrap>
      <UserButtonWrap>
        <PrimaryButton
          background={""}
          color={""}
          width=""
          title="Update Role"
          loading={false}
          isLoading={isLoading}
          type="submit"
          leftIconName={<></>}
          rightIconName={<></>}
          onClick={handleSubmit(onSubmit)}
          border={"#5C2684"}
        />
      </UserButtonWrap>
    </section>
  );
};

export default UpdateRole;
