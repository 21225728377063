import React, { useState, useMemo } from "react";
import { FaCaretSquareDown, FaCaretSquareUp } from "react-icons/fa";
import { Oval } from "react-loading-icons";
import { ButtonProps } from "../../types/Types";
import {
  ButtonWrapper,
  LeftIconContainer,
  IconContainer,
  DropDown,
  ListItem,
  ListItems,
  InvisibleBackDrop,
} from "./ButtonStyles";

const PrimaryButton = ({
  width,
  color,
  background,
  isLoading,
  leftIconName,
  rightIconName,
  leftIcon,
  rightIcon,
  onClick,
  title,
  border,
  item,
  action,
  options,
  type,
  isDisabled,
  loaderColor,
  print,
}: ButtonProps) => {
  const [openDrop, setOpenDrop] = useState<boolean>(false);

  const handleGeneralClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick();
    setOpenDrop(!openDrop);
  };

  const memoizedIcon = useMemo(() => {
    if (action) {
      return openDrop ? <FaCaretSquareUp /> : <FaCaretSquareDown />;
    } else {
      return rightIconName;
    }
  }, [openDrop, action, rightIconName]);

  if (isDisabled) {
    return null; // Don't render the button if it's disabled
  }

  return (
    <>
      {print ? (
        <>
          <ButtonWrapper
            width={width}
            color={color}
            background={background}
            type={type}
            border={border}
            disabled={isDisabled}
          >
            {isLoading ? (
              <>
                <Oval
                  stroke={loaderColor ? loaderColor : "#ffffff"}
                  fill="white"
                  width={24}
                  height={24}
                />
              </>
            ) : (
              <>
                {leftIcon && (
                  <LeftIconContainer>{leftIconName}</LeftIconContainer>
                )}
                {title}
                {rightIcon && <IconContainer>{memoizedIcon}</IconContainer>}
              </>
            )}
          </ButtonWrapper>
        </>
      ) : (
        <>
          <ButtonWrapper
            width={width}
            color={color}
            background={background}
            type={type}
            onClick={handleGeneralClick}
            border={border}
            disabled={isDisabled}
          >
            {isLoading ? (
              <>
                <Oval
                  stroke={loaderColor ? loaderColor : "#ffffff"}
                  fill="white"
                  width={24}
                  height={24}
                />
              </>
            ) : (
              <>
                {leftIcon && (
                  <LeftIconContainer>{leftIconName}</LeftIconContainer>
                )}
                {title}
                {rightIcon && <IconContainer>{memoizedIcon}</IconContainer>}
              </>
            )}

            {openDrop && (
              <>
                <DropDown>
                  <ListItems>
                    {options?.map((option: any, index: any) => (
                      <ListItem key={index} onClick={option.action}>
                        {option.title}
                      </ListItem>
                    ))}
                  </ListItems>
                </DropDown>
              </>
            )}
          </ButtonWrapper>
        </>
      )}
    </>
  );
};

export default PrimaryButton;
