import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { generateHeader } from '../../utils/headers';

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface LoginResponse {
  message: string;
  responseDescription: string;
  data: {
    accessToken: string;
    expiresIn: number;
    tokenType: string;
    user: {
      managerDepartment: string;
      managerName: string;
      staffName: string;
      displayName: string;
      email: string;
      department: string;
      groups: string;
      mobileNo: string;
      staffId: string;
      role: string;
      response: string;
      responseMessage: string;
    };
  };
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Allow-Origin", "*");
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
    },
  }),

  endpoints: (builder) => ({
    //login
    login: builder.mutation<
      LoginResponse,
      { username: string; password: string; token: string }
    >({
      query: (body) => ({
        url: "/user/login",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          password: body.password, // Set the password in the header
        },
        body: {
          username: body.username,
          password: body.password,
          token: body.token,
        },
      }),
    }),

    // get reset token
    getToken: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/user/generate-reset-token?user=${username}`,
        method: "GET",
      }),
    }),

    //reset
    resetUser: builder.mutation<any, { username: string; token: string }>({
      query: (body) => ({
        url: "/user/reset-user",
        method: "POST",
        body,
      }),
    }),

    //logout all
    logoutAll: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/user/logout-all?username=${username}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetTokenMutation,
  useResetUserMutation,
  useLogoutAllMutation,
} = authApi;
