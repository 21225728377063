import React from "react";

export const InterTransfer = ({
  colorIcon = "#FCBF24",
  iconBg = "#FFE198",
}) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24.5" r="24.5" fill={iconBg} fill-opacity="0.6" />
      <path
        d="M22.25 22.03C22.25 22.57 22.4 22.65 22.74 22.77L23.25 22.95V21.25H22.95C22.57 21.25 22.25 21.6 22.25 22.03Z"
        fill={colorIcon}
      />
      <path
        d="M24.75 26.7508H25.05C25.44 26.7508 25.75 26.4008 25.75 25.9708C25.75 25.4308 25.6 25.3508 25.26 25.2308L24.75 25.0508V26.7508Z"
        fill={colorIcon}
      />
      <path
        d="M31.58 17.48L29.53 19.53C29.38 19.68 29.19 19.75 29 19.75C28.81 19.75 28.62 19.68 28.47 19.53C28.18 19.24 28.18 18.76 28.47 18.47L30.52 16.42C28.76 14.92 26.49 14 24 14C18.48 14 14 18.48 14 24C14 29.52 18.48 34 24 34C29.52 34 34 29.52 34 24C34 21.51 33.08 19.24 31.58 17.48ZM25.75 23.82C26.39 24.05 27.25 24.51 27.25 25.98C27.25 27.23 26.26 28.26 25.05 28.26H24.75V28.51C24.75 28.92 24.41 29.26 24 29.26C23.59 29.26 23.25 28.92 23.25 28.51V28.26H23.17C21.84 28.26 20.75 27.14 20.75 25.76C20.75 25.34 21.09 25 21.5 25C21.91 25 22.25 25.34 22.25 25.75C22.25 26.3 22.66 26.75 23.17 26.75H23.25V24.53L22.25 24.18C21.61 23.95 20.75 23.49 20.75 22.02C20.75 20.77 21.74 19.74 22.95 19.74H23.25V19.5C23.25 19.09 23.59 18.75 24 18.75C24.41 18.75 24.75 19.09 24.75 19.5V19.75H24.83C26.16 19.75 27.25 20.87 27.25 22.25C27.25 22.66 26.91 23 26.5 23C26.09 23 25.75 22.66 25.75 22.25C25.75 21.7 25.34 21.25 24.83 21.25H24.75V23.47L25.75 23.82Z"
        fill={colorIcon}
      />
      <path
        d="M34.69 13.71C34.61 13.53 34.47 13.38 34.28 13.3C34.19 13.27 34.1 13.25 34 13.25H30C29.59 13.25 29.25 13.59 29.25 14C29.25 14.41 29.59 14.75 30 14.75H32.19L30.52 16.42C30.9 16.75 31.25 17.1 31.58 17.48L33.25 15.81V18C33.25 18.41 33.59 18.75 34 18.75C34.41 18.75 34.75 18.41 34.75 18V14C34.75 13.9 34.73 13.81 34.69 13.71Z"
        fill={colorIcon}
      />
    </svg>
  );
};
