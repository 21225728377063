import React, { useState } from "react";
import PrimaryButton from "../../../components/Buttons/Button";
import GeneralModal from "../../../components/Modal/GeneralModal";
import PrimaryInput from "../../../components/PrimaryInput";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { ReactComponent as ExIcon } from "../../../assets/svg/f.svg";
import {
  ButtonWrap,
  TopTitle,
  MTop,
  IconWrap,
  FieldWrapD,
  LeftD,
  RightD,
  DetailsWrap,
  Text,
  FieldWrap,
  ViewMandateBTN,
  FieldTitle,
} from "./style";
import {
  useApproveDepositLimitMutation,
  useApproveWithdrawalLimitMutation,
  useApproveTransferLimitMutation,
} from "../../../services/approval/approval";
import toast from "react-hot-toast";
import SuccessModal from "../../../components/Modal/SuccessModal";
import { useGetReceiptMutation } from "../../../services/transaction/transaction";
import { useApproveDeclineMutation } from "../../../services/collection/collection";
import { ErrorHandler } from "../../../utils/config";
interface TModal {
  details: any;
  handleClose: any;
  type: string;
  setOpenView: any;
  handleFetch: () => {};
}
const TransactionModal: React.FC<TModal> = ({
  details,
  handleClose,
  type,
  setOpenView,
  handleFetch,
}) => {
  //states
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [proceed, setProceed] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [action, setAction] = useState<string>("");
  const [remark, setRemark] = useState<string>("");

  //save reciept data
  //endpoints
  const [approveDeposit, depositMutation] = useApproveDepositLimitMutation();
  const [approveWithdrawal, withdrawalMutation] =
    useApproveWithdrawalLimitMutation();
  const [approveTransfer, transferMutation] = useApproveTransferLimitMutation();
  const [approveCollection, collectionMutation] = useApproveDeclineMutation();

  const endpoint =
    type === "Deposit"
      ? approveDeposit
      : type === "Transfer"
      ? approveTransfer
      : type === "Collection"
      ? approveCollection
      : approveWithdrawal;

  const approveFunc = async (payload: any) => {
    try {
      const response = await endpoint(payload).unwrap();
      setOpenView(false);
      setOpenSuccessModal(true);
      if (response?.statusCode === 200) {
        toast.success(response?.message);
        setOpenSuccessModal(true);
        setSuccessMessage(response?.message);
        handleFetch();
      }
      setOpenSuccessModal(true);
    } catch (error: any) {
      setProceed(false);
      handleClose();
      ErrorHandler(error);
    }
  };

  const handleApprove = async () => {
    setAction("Approve");
    setProceed(true);
  };

  const handleDecline = async () => {
    setAction("Decline");
    setProceed(true);
  };

  const isLoading =
    type === "Deposit"
      ? depositMutation?.isLoading
      : type === "Transfer"
      ? transferMutation?.isLoading
      : type === "Collection"
      ? collectionMutation?.isLoading
      : withdrawalMutation?.isLoading;

  const handleCloseModal = () => {
    setOpenSuccessModal(false);

    // setIsOpenTransModal(false);
    // setTransactionStatus(false);
  };

  const handleProceed = async () => {
    if (type === "Transfer" || type === "Withdrawal") {
      // add token
      if (action === "Approve") {
        const payload = {
          transactionId: details.id,
          approved: true,
          remark: remark,
          token: token,
        };
        approveFunc(payload);
      } else if (action === "Decline") {
        const payload = {
          transactionId: details.id,
          approved: false,
          remark: remark,
          token: token,
        };
        approveFunc(payload);
      }
    } else {
      /// remove token

      if (action === "Approve") {
        const payload = {
          transactionId:
            type === "Collection" ? details.transactionId : details.id,
          approved: true,
          remark: remark,
        };
        approveFunc(payload);
      } else if (action === "Decline") {
        const payload = {
          transactionId:
            type === "Collection" ? details.transactionId : details.id,
          approved: false,
          remark: remark,
        };
        approveFunc(payload);
      }
    }
  };
  return (
    <>
      <GeneralModal general>
        <MTop>
          <TopTitle>Transaction Details</TopTitle>
          <IconWrap>
            <CloseIcon onClick={handleClose} />
          </IconWrap>
        </MTop>

        {type === "Transfer" && (
          <>
            <FieldWrapD>
              <FieldTitle>Customer Information</FieldTitle>
              <FieldWrap>
                <PrimaryInput
                  disabled={true}
                  name={""}
                  type={"text"}
                  placeholder={""}
                  leftIcon={<ExIcon />}
                  value={details?.debitAccountNumber}
                  label={"Debit Accouunt Number"}
                />

                <PrimaryInput
                  disabled={true}
                  name={""}
                  type={"text"}
                  leftIcon={<ExIcon />}
                  placeholder={""}
                  value={details?.debitAccountName}
                  label={"Debit Account Name"}
                />
              </FieldWrap>
              <FieldWrap>
                <PrimaryInput
                  leftIcon={<ExIcon />}
                  disabled={true}
                  name={""}
                  type={"text"}
                  placeholder={""}
                  value={details?.creditAccountBvn}
                  label={"BVN"}
                />
                <ViewMandateBTN>
                  <PrimaryButton
                    background={"#ffffff"}
                    color={"#5c2684"}
                    width={150}
                    title={"View Mandate"}
                    icon={""}
                    loading={false}
                    leftIconName={<></>}
                    rightIconName={<></>}
                    onClick={(event: any) => console.log(event.target.value)}
                    border={"1px solid #5c2684"}
                  />
                </ViewMandateBTN>
              </FieldWrap>
            </FieldWrapD>
            <FieldWrapD>
              <FieldTitle>Receiver's Information</FieldTitle>
              <FieldWrap>
                <PrimaryInput
                  disabled={true}
                  name={""}
                  type={"text"}
                  placeholder={""}
                  leftIcon={<ExIcon />}
                  value={details?.debitAccountNumber}
                  label={"Debit Account Number"}
                />

                <PrimaryInput
                  disabled={true}
                  name={""}
                  type={"text"}
                  leftIcon={<ExIcon />}
                  placeholder={""}
                  value={details?.creditAccountNumber}
                  label={"Debit Account Name"}
                />
              </FieldWrap>
              <FieldWrap>
                <PrimaryInput
                  disabled={true}
                  name={""}
                  leftIcon={<ExIcon />}
                  type={"text"}
                  placeholder={""}
                  value={details?.creditAccountName}
                  label={"Debit Account Number"}
                />

                <PrimaryInput
                  disabled={true}
                  name={""}
                  type={"text"}
                  leftIcon={<ExIcon />}
                  placeholder={""}
                  value={details?.interBankCode}
                  label={"Debit Account Name"}
                />
              </FieldWrap>
            </FieldWrapD>
          </>
        )}

        <FieldWrapD>
          {type === "Transfer" && <FieldTitle>Other Information</FieldTitle>}
          <DetailsWrap>
            <LeftD>
              <Text>
                {type === "Transfer"
                  ? "Debit Account Name"
                  : type === "Deposit"
                  ? "Account Name"
                  : "Drawee's Name"}
              </Text>{" "}
            </LeftD>
            <RightD>
              <Text>
                {type === "Transfer"
                  ? details?.debitAccountName
                  : type === "Deposit"
                  ? details?.beneficiaryName
                  : type === "Collection"
                  ? details?.depositorName
                  : details?.draweeName}
              </Text>{" "}
            </RightD>
          </DetailsWrap>
          <DetailsWrap>
            <LeftD>
              <Text>
                {type === "Transfer"
                  ? "Debit Account Number"
                  : "Account Number"}
              </Text>{" "}
            </LeftD>
            <RightD>
              <Text>
                {type === "Collection"
                  ? details?.bankNumber
                  : type === "Transfer"
                  ? details?.creditAccountNumber
                  : details.accountNumber}
              </Text>{" "}
            </RightD>
          </DetailsWrap>
          <DetailsWrap>
            <LeftD>
              <Text>Amount</Text>{" "}
            </LeftD>
            <RightD>
              <Text>
                {new Intl.NumberFormat("ja-JP", {
                  style: "currency",
                  currency: details?.currency,
                }).format(details?.amount)}
              </Text>{" "}
            </RightD>
          </DetailsWrap>
          <DetailsWrap>
            <LeftD>
              <Text>Branch Code</Text>{" "}
            </LeftD>
            <RightD>
              <Text>{details?.initiatorBranchCode}</Text>{" "}
            </RightD>
          </DetailsWrap>
          <DetailsWrap>
            <LeftD>
              <Text>Transaction Date</Text>{" "}
            </LeftD>
            <RightD>
              <Text>{details?.dateCreated.slice(0, 10)}</Text>{" "}
            </RightD>
          </DetailsWrap>
          <DetailsWrap>
            <LeftD>
              <Text>Teller name</Text>{" "}
            </LeftD>
            <RightD>
              <Text>{details?.createdBy}</Text>{" "}
            </RightD>
          </DetailsWrap>
        </FieldWrapD>
        <ButtonWrap>
          <PrimaryButton
            background={"#E21A1A"}
            color={"#ffffff"}
            width={300}
            title={"Decliine"}
            icon={""}
            isDisabled={proceed}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleDecline}
            leftIcon={false}
            border={"1px solid #E21A1A"}
          />
          <PrimaryButton
            background={"#01B272"}
            color={"#ffffff"}
            width={200}
            title={"Approve"}
            icon={""}
            isDisabled={proceed}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={handleApprove}
            leftIcon={false}
            border={"1px solid #01B272"}
          />
        </ButtonWrap>

        {proceed && (
          <>
            <FieldWrapD>
              {type === "Transfer" ||
                (type === "Withdrawal" && (
                  <PrimaryInput
                    name={"token"}
                    type={"text"}
                    placeholder={"Enter your token"}
                    label="Token"
                    onChange={(e: any) => setToken(e.target.value)}
                  />
                ))}

              <PrimaryInput
                name={"remark"}
                type={"text"}
                placeholder={"Enter your remark"}
                label="Remark"
                onChange={(e: any) => setRemark(e.target.value)}
              />
            </FieldWrapD>
            <PrimaryButton
              background={"#01B272"}
              color={"#ffffff"}
              width={200}
              title={"Proceed"}
              icon={""}
              loading={false}
              leftIconName={<></>}
              rightIconName={<></>}
              isLoading={isLoading}
              onClick={handleProceed}
              leftIcon={false}
              border={"1px solid #01B272"}
            />
          </>
        )}
      </GeneralModal>

      {openSuccessModal && (
        <SuccessModal
          message={successMessage}
          approval
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default TransactionModal;
