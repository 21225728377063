import CryptoJS from "crypto-js";

const subscription_key = process.env.REACT_APP_SUBSCRIPTION_KEY;
const client_id = process.env.REACT_APP_CLIENT_ID;
const token_password = process.env.REACT_APP_X_TOKEN_PASSWORD;

export function createGuid() {
  function s4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (s4() + s4() + "-" + s4() + "-4" + s4().substr(0, 3) + "-" + s4() + "-" + s4() + s4() + s4()).toLowerCase();
}

export const generateHeader = () => {
  const dateToUse = new Date();
  const UTCTimestamp = dateToUse.toISOString().replace("Z", "");

  const dateInToken = UTCTimestamp.replace("T", "")
    .replace(":", "")
    .replace(":", "")
    .substring(0, UTCTimestamp.length - 7);
  const shaOneEncrypt = CryptoJS.SHA512(dateInToken + client_id + token_password);

  const apiHeader = {
    client_id: client_id,
    "x-correlation-id": createGuid(),
    "x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
    "Ocp-Apim-Subscription-Key": subscription_key,
    "Ocp-Apim-Trace": true,
    UTCTimestamp: UTCTimestamp,
    "Cross-Origin-Opener-Policy": "same-origin",
    "X-Frame-Options": "SAMEORIGIN",
    "X-Content-Type-Options": "nosniff",
    "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
  };

  return apiHeader;
};
