import React from "react";
import { BoldText, ComingBtn, Main, ParagraphText } from "./styled";
import { useNavigate } from "react-router-dom";
import { FaGithubAlt } from "react-icons/fa";

interface NotFoundProps {
  coming?: boolean;
  notFound?: boolean;
  message?: string;
}
const NotFound: React.FC<NotFoundProps> = ({ coming, notFound, message }) => {
  const navigate = useNavigate();
  return (
    <Main>
      <FaGithubAlt size={100} color="#5C2684" />
      {coming && (
        <>
          <BoldText>Coming Soon...</BoldText>
          <ParagraphText>
            Oh!, our apologies. The page you’re looking for is unavailable at
            the moment.
          </ParagraphText>
          <ComingBtn onClick={() => navigate("/")}>Back to Dashboard</ComingBtn>
        </>
      )}

      {notFound && (
        <>
          <BoldText>{message}</BoldText>
        </>
      )}
    </Main>
  );
};

export default NotFound;
