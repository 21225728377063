import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrimaryButton from "../Buttons/Button";
import { styled } from "styled-components";
import ReactToPrint from "react-to-print";

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30%;
  flex-direction: column;
`;
interface PDFComponentType {
  componentToRender: React.ReactNode;
}
const PDFGenerator: React.FC<PDFComponentType> = ({ componentToRender }) => {
  // const pdfRef = useRef(null);
  const componentRef = useRef(null);

  // const generatePDF = async () => {
  //   const pdf = new jsPDF();
  //   const pdfElement = pdfRef.current;

  //   if (pdfElement) {
  //     const canvas = await html2canvas(pdfElement);
  //     const imageData = canvas.toDataURL("image/png");

  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imageWidth = 180;
  //     const imageHeight = (imageWidth * canvas.height) / canvas.width;
  //     const x = (pdfWidth - imageWidth) / 2;
  //     const y = (pdfHeight - imageHeight) / 2;

  //     pdf.addImage(imageData, "SVG", x, y, imageWidth, imageHeight);
  //     pdf.save("receipt.pdf");
  //   }
  // };

  return (
    <div>
      <div ref={componentRef}>{componentToRender}</div>
      <ReactToPrint
        trigger={() => (
          <ButtonWrap>
            {/* <PrimaryButton
              background={""}
              color={""}
              width={"150px"}
              height="48px"
              title={"Print"}
              loading={false}
              type="button"
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={()}
              border={"#5C2684"}
            /> */}
            <PrimaryButton
              background={""}
              color={""}
              width={"150px"}
              print
              height="48px"
              title={"Print"}
              leftIconName={<></>}
              rightIconName={<></>}
              border={"#5C2684"}
            />
          </ButtonWrap>
        )}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default PDFGenerator;
