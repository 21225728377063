import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import {
  ComponentWrapper,
  LoaderContainer,
  Top,
  DataDiv,
  TopLeftContainer,
  TopRightContainer,
  TopTitle,
  TableBtn,
  TopSelect,
  Container,
} from "./style";
import PrimaryButton from "../../../components/Buttons/Button";
import PrimaryTable from "../../../components/Table";
import { useDepositApprovalMutation } from "../../../services/approval/approval";
import { ApprovalResponse, Datum } from "../../../services/approval/type";
import PuffLoader from "../../../components/Loader";
import TransactionModal from "../Modal/TransactionModal";
import NotFound from "../../../components/Not Found";
import toast from "react-hot-toast";
import { ErrorHandler } from "../../../utils/config";

const DepositLimitApproval = () => {
  const [dataContainer, setDataContainer] = useState<
    ApprovalResponse | undefined
  >();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [openView, setOpenView] = useState<boolean>(false);
  const [details, setDetails] = useState<Datum>();
  const [depositApproval, mutationState] = useDepositApprovalMutation();

  const handleFetch = async () => {
    const param = {
      pageNumber: 1,
      pageSize: itemsPerPage,
    };

    try {
      const response = await depositApproval(param).unwrap();
      if (response?.statusCode === 200) {
        setDataContainer(response);
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const handleClose = () => {
    setOpenView(false);
  };

  const option = [
    {
      id: 1,
      value: 5,
    },
    {
      id: 2,
      value: 10,
    },
    {
      id: 3,
      value: 20,
    },
    {
      id: 4,
      value: 50,
    },
    {
      id: 5,
      value: 100,
    },
  ];

  const handleSelect = (e: any) => {
    const selectedOption = e.target.value;
    setItemsPerPage(selectedOption);
  };

  const handleView = async (id: number) => {
    dataContainer?.data?.data?.filter((item: Datum) => {
      if (item.id === id) {
        setDetails(item);
        setOpenView(true);
      }
      return null;
    });
  };

  const tableHeader: any = [
    {
      id: 1,
      title: "S/N",
    },
    {
      id: 2,
      title: "Account Number",
    },
    {
      id: 3,
      title: "Amount",
    },
    {
      id: 4,
      title: "Created By",
    },
    {
      id: 5,
      title: "Date",
    },
    {
      id: 6,
      title: "Narration",
    },
    {
      id: 7,
      title: "Action",
    },
  ];

  const dataBody = dataContainer?.data?.data?.map((item: any, index: any) => [
    index + 1,
    <DataDiv key={item.accountNumber}>{item.accountNumber}</DataDiv>,
    // item.currency,
    <>
      {new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: item?.currency,
      }).format(item?.amount)}
    </>,
    item.createdBy,
    item.dateCreated.slice(0, 10),
    item.narration,
    <TableBtn>
      <PrimaryButton
        background={"#ffffff"}
        color={"#5c2684"}
        width={150}
        title={"View"}
        icon={""}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleView(item.id)}
        leftIcon={false}
        border={"1px solid #5c2684"}
      />
    </TableBtn>,
  ]);

  return (
    <>
      <Header title="Limit Approval / Deposit" />
      <ComponentWrapper>
        <Top>
          <TopLeftContainer>
            <TopTitle>Transaction Table</TopTitle>
          </TopLeftContainer>
          <TopRightContainer>
            {/* <PrimaryInput
              name={"search"}
              type={"text"}
              placeholder={"Search"}
              rightIcon={<></>}
              label={""}
              check={""}
              padding={""}
              leftIcon={<></>}
              password={false}
            /> */}
            Show
            <TopSelect onChange={handleSelect} value={itemsPerPage}>
              {option?.map((option) => (
                <option value={option.value}>{option.value}</option>
              ))}
            </TopSelect>
            Entries
          </TopRightContainer>
        </Top>

        {mutationState.isLoading ? (
          <LoaderContainer>
            <PuffLoader />
          </LoaderContainer>
        ) : (
          <>
            {dataContainer?.statusCode === 200 ? (
              <PrimaryTable
                header={tableHeader}
                body={dataBody}
                arrOfObject={true}
                itemsPerPage={itemsPerPage}
              />
            ) : (
              <Container>
                <NotFound notFound message="No Record Found!." />
              </Container>
            )}
          </>
        )}
      </ComponentWrapper>
      {openView && (
        <TransactionModal
          details={details}
          handleClose={handleClose}
          type="Deposit"
          setOpenView={setOpenView}
          handleFetch={handleFetch}
        />
      )}
    </>
  );
};

export default DepositLimitApproval;
