import styled from "styled-components";

export const HeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #5c2684;
  width: 100%;
  justify-content: space-between;
  padding: 35px;
  padding-right: 55px !important;
  align-items: center;
`;
export const Avatar = styled.img`
  width: 40px;
  height: 40px;
`;
export const SideHeaderTexts = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const HeaderText = styled.p`
  font-family: "Gotham";
  font-weight: bold;
  font-size: 40px;
  color: white;
`;
export const AvatarName = styled.div`
  font-family: "Gotham";
  font-size: 15px;
  color: white;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  /* box-shadow: 0px 30px 12px rgba(48, 27, 59, 0.01),
    0px 17px 10px rgba(48, 27, 59, 0.05), 0px 7px 7px rgba(48, 27, 59, 0.09),
    0px 2px 4px rgba(48, 27, 59, 0.1), 0px 0px 0px rgba(48, 27, 59, 0.1);
  border-radius: 17px; */
  justify-content: space-between;
  padding: 17px 0px;
  /* background: #5c2684; */
`;

export const LeftContainer = styled.div`
  padding-top: 10px;
`;
export const LeftHeader = styled.p`
  font-weight: 600;
  font-size: 32px;
  color: #2d2235;
`;

export const LeftInputWrapper = styled.div`
  display: flex;
  background: #ffffff;
  width: 50%;
  border-radius: 3px;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
`;
export const Input = styled.input`
  width: 85%;
  border: none;
  outline: none;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;
`;

export const BellContainer = styled.div`
  border-left: 1px solid #7a8699;
  padding-inline: 8px;
  display: flex;
  align-items: center;
  border-right: 1px solid #7a8699;
`;
export const CurrentContainer = styled.div`
  padding-inline: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const CurrentText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #2d2235;
`;
export const UserContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;
`;
export const ImageContainer = styled.div`
  border: solid #5c2684;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  font-size: 24px;
`;
export const ImageC = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
export const Username = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #2d2235;
`;

export const DropDown = styled.div`
  display: flex;
  align-items: center;
`;
