import toast from "react-hot-toast";
import { transactionApi } from "../services/transaction/transaction";
import { store } from "../store/store";
import { DeProp } from "../types/Types";

export function generateYearsBetween(
  startYear = 1990,
  endYear = new Date().getFullYear()
) {
  return Array.from(Array(endYear - startYear + 1)).map(
    (_, index) => startYear + index
  );
}

export const formatDate = (date: Date): string => {
  const dateValue = new Date(date);
  let month: string = String(dateValue.getMonth() + 1).padStart(2, "0");
  let day: string = String(dateValue.getDate()).padStart(2, "0");
  const year: number = dateValue.getFullYear();

  return `${year}-${month}-${day}`;
};

export const formatCurrency = (number = 0) => {
  const _number = +number;
  return _number
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const handleLogout = async (logout: any, navigate: any) => {
  // clear localstorage
  try {
    const response = await logout().unwrap();
    if (response?.statusCode === 200) {
      localStorage.clear();
      // clear all cache
      store.dispatch(transactionApi.util.resetApiState());
      toast.success(response?.message);
      navigate("/login");
    }
  } catch (err: any) {
    toast.error(err.data?.message);
  }

  //navigate
};

export const calculateTotalPrice = (itemContainer: DeProp[]) => {
  if (itemContainer?.length > 0) {
    const totalPrice = itemContainer?.reduce((accumulator, denomination) => {
      return accumulator + denomination.amount;
    }, 0);
    return totalPrice;
  }
};
