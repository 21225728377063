import React from "react";
import {
  Modal as ModalComponent,
  TransitionablePortal,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

const Modal = ({
  children,
  size,
  open,
  style,
  closeIcon,
  contentPadding,
  onClose,
  title,
  className,
  ...otherProps
}: any) => {
  if (!open) return null;
  return (
    <>
      <TransitionablePortal {...{ open }}>
        <ModalComponent
          size={size || "small"}
          open
          closeIcon={closeIcon ? true : false}
          onClose={onClose}
          style={style}
          className={className}
          {...otherProps}
        >
          {title && <ModalComponent.Header>{title}</ModalComponent.Header>}
          <div className="" style={{ padding: contentPadding || "1.5rem" }}>
            {children}
          </div>
        </ModalComponent>
      </TransitionablePortal>
    </>
  );
};

export default Modal;
