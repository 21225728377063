import React, { useCallback, useState } from "react";
import PrimaryInput from "../../components/PrimaryInput";
import { FlexWrapper, ModalHeaderText, UserButtonWrap } from "./styles/styles";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import PrimaryButton from "../../components/Buttons/Button";
//import { usePostNewUserMutation } from "./service/adminApi";
import toast from "react-hot-toast";
//import { uid } from "../../utils/uids";
import { FormProvider, useForm } from "react-hook-form";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { useAddUserMutation, useGetAllUsersQuery, useGetRolesQuery } from "../../services/admin/adminService";
import { Oval } from "react-loading-icons";
import SearchCard from "../../components/SearchCard";

const ViewAddUser = () => {
  //const [addUser, addUserProps] = usePostNewUserMutation();
  const [userName, setUserName] = useState("");
  const getAllUsers = useGetAllUsersQuery({pageNumber: 2});
  const [,setSelectedId] = useState<number>(0);
  const [proceed, setProceed] = useState(false);
  const { data } = useGetRolesQuery();
  const [error, setError] = useState<{
    userName: string;
    roleName: string;
  }>({
    userName: "",
    roleName: ""
  })
  const [roleName, setRoleName] = useState("");
  const [addUser, {isLoading}] = useAddUserMutation();
  const [roleValue, setRoleValue] = useState("");
  const listofRoles = data ? data?.data?.map((item: any) => ({
    value: item.id,
    label: item.roleName,
  })) : [];


  const onOpenTransDetail = useCallback(() => {
    const updatedError: any = {};
    if(!userName){
      updatedError.userName = "Username required"
    }
    if (!roleName) {
      updatedError.roleName = "Select role";
    }
    setError(updatedError);

    if (Object.keys(updatedError).length === 0){
     setProceed(true);
    }
  }, [roleName, userName]);



  const onSubmit = async () => {
    onOpenTransDetail();
    const payload = {
      username: userName,
      isActive: true,
      roleId: roleValue
    };

    
    try {
      const response = await addUser(payload).unwrap();
      if (response?.code === 201 || response) {
        toast.success(response?.Message);
        setUserName("");
        setRoleValue("");
        getAllUsers.refetch();
      }
    } catch (err: any) {
    err?.data?.errors === undefined ?
    toast.error(err?.data?.message) :
     toast.error(err?.data?.errors?.Username[0])
    
    }
  }

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

 
  return (
    <div>
      <ModalHeaderText>Add New User</ModalHeaderText>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexWrapper>
          <PrimaryInput
            name=""
            type="text"
            placeholder="Username"
            rightIcon={<></>}
            error={error.userName}
            label="User Name"
            check={""}
            padding={""}
            leftIcon={<InputClose />}
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
            // register={register}
          />
          <DropDownC 
           value={roleName}
           placeholder={"Select Roles"}
           options={listofRoles}
           errorMessage={error.roleName}
           label={"Role"}
           //onChange={(event: any) => setRoleName(event.target.value)}
           setSelectedItems={setRoleName} 
           setSelectedItem={() => {}}
           setOptionValue={setRoleValue}
           //setSelectedItem={setRoleName}         
          />
          
          
          </FlexWrapper>

          <UserButtonWrap>
            <PrimaryButton
              background={""}
              color={""}
              width="150px"
              title="Add New User"
              loading={false}
              type="submit"
              isLoading={isLoading}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={proceed ? onSubmit : onOpenTransDetail}
              border={"#5C2684"}
            />
          </UserButtonWrap>
        </form>
      </FormProvider>
    </div>
  );
};

export default ViewAddUser;
