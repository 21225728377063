import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { generateHeader } from '../../utils/headers';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Allow-Origin", "*");
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get reports
    getReports: builder.mutation<any, any>({
      query: (body) => ({
        url: `/report/deposit-report`,
        method: "POST",
        body,
      }),
    }),

    getWithdrawalReports: builder.mutation<any, any>({
      query: (body) => ({
        url: `/report/withdrawal-report`,
        method: "POST",
        body,
      }),
    }),

    getTransferReports: builder.mutation<any, any>({
      query: (body) => ({
        url: `/report/transfer-report`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetReportsMutation,
  useGetWithdrawalReportsMutation,
  useGetTransferReportsMutation,
} = reportsApi;
