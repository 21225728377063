import React, { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import {
  CheckInputBox,
  DropDown,
  DropDownWrapper,
  ListItem,
  ListItems,
  ShowList,
  ShowListIcon,
  MultipleItem,
  InvisibleBackDrop,
  LabelText,
  Checkmark,
  PurpleCheckbox,
} from "./style";
import { InputProps } from "../../types/Types";

const MultipleDropDown = ({
  options,
  list,
  handleClick,
  checkedItems,
}: InputProps) => {
  const [open, setOpen] = useState(false);

  const handleOpenDropdown = () => {
    setOpen(!open);
  };

  return (
    <DropDownWrapper>
      <div
        style={{
          width: "100%",
          display: "flex",
          height: "30px",
        }}
      >
        <ShowList>
          {list.length > 0 ? (
            <>
              {list?.map((item: any, index: number) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MultipleItem key={index}>{item}</MultipleItem>
                </div>
              ))}
            </>
          ) : (
            <p
              style={{
                color: "#a6a6a8",
                fontSize: "12px",
              }}
            >
              Select options
            </p>
          )}
        </ShowList>
        <ShowListIcon onClick={handleOpenDropdown}>
          {" "}
          {open ? <HiChevronUp size={24} /> : <HiChevronDown size={24} />}
        </ShowListIcon>
      </div>
      {open && (
        <>
          <InvisibleBackDrop onClick={() => setOpen(false)} />

          <DropDown>
            <ListItems>
              {options?.map((option: any, index: number) => (
                <ListItem key={index}>
                  <PurpleCheckbox>
                    <CheckInputBox
                      type="checkbox"
                      value={option.label}
                      onClick={handleClick}
                      checked={checkedItems.includes(option.label)}
                    />
                    <Checkmark className="checkmark" />
                    <LabelText>{option.label}</LabelText>
                  </PurpleCheckbox>
                </ListItem>
              ))}
            </ListItems>
          </DropDown>
        </>
      )}
    </DropDownWrapper>
  );
};

export default MultipleDropDown;
