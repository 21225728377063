import React from "react";
import Header from "../../components/Header/Header";
import { Container, ComponentWrapper } from "./style";

import NotFound from "../../components/Not Found";

const EasyAccount = () => {
  return (
    <>
      <Header title="Easy Account" />
      <ComponentWrapper>
        <Container>
          <NotFound coming />
        </Container>
      </ComponentWrapper>
    </>
  );
};

export default EasyAccount;
