import React from "react";
import { ReactComponent as FcmbLogo } from "../../assets/svg/fcmblogo.svg";
import { DetailsWrap, FieldWrapD, LeftD, RightD, Text } from "./style";
import { ReactComponent as SNLogo } from "../../assets/svg/serialNumber.svg";

const DReceiptView = ({ data, title }: any) => {
  return (
    <section style={{ width: "800px", padding: "20px 50px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FcmbLogo />
        <div
          style={{
            width: "45%",
            textAlign: "end",
          }}
        >
          <p style={{ fontWeight: "700", fontSize: "18px" }}>Quick Service </p>
          <p style={{ color: "#2D2235" }}>
            Dial *329# Top-Up your mobile phone : *329*AMOUNT # Top-Up other
            mobile phone : *329*AMOUNT*MOBILE NUMBER# Transfer funds :
            *329*AMOUNT*ACCOUNT NUMBER# Check Balance : *329*00#
          </p>
        </div>
      </div>
      <div className="">
        <p style={{ fontWeight: "700", fontSize: "29px", marginTop: "40px" }}>
          {title ? title : "Cash Deposit Receipt"}{" "}
        </p>
        <div>
          <FieldWrapD>
            <DetailsWrap>
              <LeftD>
                <Text>Beneficiary Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.beneficiaryName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Beneficiary Account Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.beneficiaryAccountNumber}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Depositor's Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.depositorName}</Text>{" "}
              </RightD>
            </DetailsWrap>

            <DetailsWrap>
              <LeftD>
                <Text>Amount</Text>{" "}
              </LeftD>
              <RightD>
                <Text>
                  {" "}
                  {new Intl.NumberFormat("ja-JP", {
                    style: "currency",
                    currency: data?.currency,
                  }).format(data?.amount)}
                </Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Transaction Date</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.transactionDate}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Slip Free ID</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.slipfreeId}</Text>{" "}
              </RightD>
            </DetailsWrap>

            <DetailsWrap>
              <LeftD>
                <Text>Narration</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.naration}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Transaction Id</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{data?.transactionId}</Text>{" "}
              </RightD>
            </DetailsWrap>
          </FieldWrapD>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "40px",
        }}
      >
        <div></div>
        <div>
          <SNLogo />
        </div>
      </div>
    </section>
  );
};

export default DReceiptView;
