import React from "react";

export const WithDrawalIcon = ({
  iconColor = "#FCBF24",
  iconBg = "#FFE198",
}) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24.5" r="24.5" fill={iconBg} fillOpacity="0.6" />
      <path
        d="M31.1709 18.6395C30.7409 16.4695 29.1309 15.5195 26.8909 15.5195H18.1109C15.4709 15.5195 13.7109 16.8395 13.7109 19.9195V25.0695C13.7109 27.2895 14.6209 28.5895 16.1209 29.1495C16.3409 29.2295 16.5809 29.2995 16.8309 29.3395C17.2309 29.4295 17.6609 29.4695 18.1109 29.4695H26.9009C29.5409 29.4695 31.3009 28.1495 31.3009 25.0695V19.9195C31.3009 19.4495 31.2609 19.0295 31.1709 18.6395ZM17.5309 23.9995C17.5309 24.4095 17.1909 24.7495 16.7809 24.7495C16.3709 24.7495 16.0309 24.4095 16.0309 23.9995V20.9995C16.0309 20.5895 16.3709 20.2495 16.7809 20.2495C17.1909 20.2495 17.5309 20.5895 17.5309 20.9995V23.9995ZM22.5009 25.1395C21.0409 25.1395 19.8609 23.9595 19.8609 22.4995C19.8609 21.0395 21.0409 19.8595 22.5009 19.8595C23.9609 19.8595 25.1409 21.0395 25.1409 22.4995C25.1409 23.9595 23.9609 25.1395 22.5009 25.1395ZM28.9609 23.9995C28.9609 24.4095 28.6209 24.7495 28.2109 24.7495C27.8009 24.7495 27.4609 24.4095 27.4609 23.9995V20.9995C27.4609 20.5895 27.8009 20.2495 28.2109 20.2495C28.6209 20.2495 28.9609 20.5895 28.9609 20.9995V23.9995Z"
        fill={iconColor}
      />
      <path
        d="M34.3017 22.9203V28.0703C34.3017 31.1503 32.5417 32.4803 29.8917 32.4803H21.1117C20.3617 32.4803 19.6917 32.3703 19.1117 32.1503C18.6417 31.9803 18.2317 31.7303 17.9017 31.4103C17.7217 31.2403 17.8617 30.9703 18.1117 30.9703H26.8917C30.5917 30.9703 32.7917 28.7703 32.7917 25.0803V19.9203C32.7917 19.6803 33.0617 19.5303 33.2317 19.7103C33.9117 20.4303 34.3017 21.4803 34.3017 22.9203Z"
        fill={iconColor}
      />
    </svg>
  );
};
