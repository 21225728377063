import React, { useCallback, useState, useEffect, useMemo } from "react";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { convertAmountToWords } from "../../utils/amountWriter";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as ExIcon } from "../../assets/svg/f.svg";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import {
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  ContainerDepo,
  StatusHolder,
  LeftContainerDepo,
  RightContainerDepo,
  ButtonWrap,
  Input,
  Radio,
  Instruction,
  InputDiv,
  LoaderContainer,
  LeftD,
  RightD,
  LeftDen,
  RightDen,
  Status,
  FieldWrapDen,
  DHeader,
  Text,
  FieldWrapD,
  DetailsWrap,
  DetailsWrapDen,
  DenominationContainer,
  TopContainer,
  DenominationWrap,
  Top,
  TopTitle,
  IconWrap,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import {
  denominationList,
  customerInfoData,
  withdrawalType,
  WithDdrawalError,
  currencyType,
  withdrawalSchema,
  ErrorHandler,
} from "../../utils/config";
import { CustomerInfoProp, DeProp, WithError } from "../../types/Types";

import { HeaderComponent } from "../../components/Withdrawal/style";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  useGetAccDetailsMutation,
  useGetReceiptMutation,
  usePostWithdrawalMutation,
  useValidateChequeMutation,
  useViewMandateMutation,
} from "../../services/transaction/transaction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DenominationInput from "../../components/PrimaryInput/denInput";
import toast from "react-hot-toast";
import DateComponent from "../../components/Date/Date";
import TextArea from "../../components/TextArea";
import TopCards from "../../components/Cards/TopCards";
import ReceiptView from "../../components/Receipt/Receipt";
import PDFGenerator from "../../components/PDFGenerator/PdfGenerator";
import { useSelector } from "react-redux";
import GeneralModal from "../../components/Modal/GeneralModal";
import { PostWithdrawal, GetReceipt } from "../../services/transaction/types";
import { Oval } from "react-loading-icons";
import SuccessModal from "../../components/Modal/SuccessModal";
import { calculateTotalPrice } from "../../utils";

const CashWithdrawal = () => {
  const branchCode = useSelector(
    (state: any) => state?.StaffDataReducer?.userDetails?.data?.branchCode
  );
  const user = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo?.user?.displayName
  );
  const currentDate = new Date();
  //states
  const [isOpenTransModal, setIsOpenTransModal] = useState(false);
  const [transStatus, setTransStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [accountOwner, setAccountOwner] = useState(false);
  const [imageData, setImageData] = useState("");
  const [openMandate, setOpenMandate] = useState(false);
  const [details, setDetails] = useState<PostWithdrawal>();
  const [accountNumber, setAccountNumber] = useState("");
  const [dateError, setDateError] = useState(false);
  const [amount, setAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isStatus, setStatus] = useState("");
  const [chequeNo, setChequeNo] = useState("");
  const [message, setMessage] = useState<string | undefined>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [selectedInstrumentDate, setSelectedInstrumentDate] = useState(
    currentDate.toJSON().slice(0, 10)
  );
  const [chequeError, setChequeErorr] = useState(false);
  const [selectedInstrumentType, setInstrumentType] = useState("");
  const [selecteCurrencyType, setSelecteCurrencyType] = useState("");
  const [withdrawal, setWithdrawal] = useState("");
  const [error, setError] = useState<WithError>(WithDdrawalError);
  const [customerInfo, setCustomerInfo] =
    useState<CustomerInfoProp>(customerInfoData);
  const [openFields, setOpenFields] = useState<boolean>(false);
  const [newDenomination, setNewDenomination] = useState<DeProp[]>();
  const [initialList, setInitialList] = useState(denominationList);

  //endpoints
  const [getAccount, mutationState] = useGetAccDetailsMutation();
  const [postTransaction, { isLoading }] = usePostWithdrawalMutation();
  const [validateCheque, validateMutation] = useValidateChequeMutation();

  //useform
  const { register, setValue } = useForm({
    resolver: yupResolver(withdrawalSchema),
  });

  //receipt
  const [transId, setTransId] = useState<string>("");
  const [transReceipt, setTransReceiptData] = useState<
    GetReceipt | undefined
  >();
  const [generatePDF, setGeneratePDF] = useState(false);
  const [transactionReceipt, setTransactionReceipt] = useState(false);
  const [getReceipt] = useGetReceiptMutation();
  const [viewMandate, mandateMutationState] = useViewMandateMutation();

  const updateDenomination = (index: any, quantity: number) => {
    const updatedDenomination = [...denominationList];
    updatedDenomination[index].quantity = quantity;
    updatedDenomination[index].amount =
      quantity * updatedDenomination[index].denomination;

    updatedDenomination.forEach((item: DeProp) => {
      if (item.amount !== 0) {
        const newList: DeProp[] = denominationList.filter(
          (denomination) => denomination.amount !== 0
        );
        setNewDenomination(newList);
      }
    });
  };

  const resetQuantities = () => {
    const resetList = denominationList.map((item: DeProp) => ({
      ...item,
      quantity: 0,
      amount: 0,
    }));
    setInitialList(resetList);
  };

  const onSelectAccountOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountOwner(event.target.value === "yes");
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerInfo((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  // get account details
  const handleCFetchAccount = async (e: any) => {
    try {
      const { value } = e.target;

      if (value.length === 10) {
        try {
          const response = await getAccount({
            accountNo: value,
          }).unwrap();
          setOpenFields(true);

          setAccountNumber(value);
          const firstName =
            response?.firstName !== null ? response?.firstName : "";
          const lastName =
            response?.lastName !== null ? response?.lastName : "";

          setStatus(response?.status as string);
          const beneficiaryName = `${firstName} ${lastName}`;
          setWithdrawal(beneficiaryName);
          setValue("beneficiaryName", beneficiaryName, {
            shouldValidate: true,
          });
          setValue("depositType", response?.customerType as string, {
            shouldValidate: true,
          });
          setCustomerInfo((prevState: any) => ({
            ...prevState,
            currencyType: response?.customerType,
          }));
          setValue("middleName", response?.middleName as string, {
            shouldValidate: true,
          });
          setValue("email", response?.email as string, {
            shouldValidate: true,
          });
          setValue("accountType", response?.accountType as string, {
            shouldValidate: true,
          });
          setValue(
            "dailyLimit",
            new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: response?.currency,
            }).format(response?.limitResponse?.dailyLimit as number),

            {
              shouldValidate: true,
            }
          );

          setValue(
            "weeklyLimit",
            new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: response?.currency,
            }).format(response?.limitResponse?.weeklyLimit as number),

            {
              shouldValidate: true,
            }
          );
          setValue(
            "availableBalance",

            new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: response?.currency,
            }).format(response?.availableBalance as number),

            {
              shouldValidate: true,
            }
          );
          setValue("schemaCode", response?.schemeCode as string, {
            shouldValidate: true,
          });
        } catch (error: any) {
          setOpenFields(false);
          ErrorHandler(error);
        }
      } else {
        setOpenFields(false);
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const payload = useMemo(
    () => ({
      isOwner: accountOwner,
      instrumentType: selectedInstrumentType,
      instrumentDate:
        selectedInstrumentType === "CHQ"
          ? selectedInstrumentDate
          : currentDate.toJSON().slice(0, 10),
      instrumentNumber: customerInfo?.instrumentNumber,
      currencyType: selecteCurrencyType,
      narration: customerInfo?.narration,
      phoneNumber: customerInfo?.phoneNumber,
      accountNumber: accountNumber,
      amount: amount,
      fee: 0,
      draweeName: accountOwner ? withdrawal : customerInfo?.depositorName,
      remark: customerInfo?.remark,
      chequeRefNo: chequeNo,
      checkValueDate: "2023-09-06",
      denominations: newDenomination,
    }),
    [
      accountOwner,
      selectedInstrumentType,
      chequeNo,
      withdrawal,
      accountNumber,
      selecteCurrencyType,
      selectedInstrumentDate,
      newDenomination,
      currentDate,
      customerInfo,
    ]
  );

  const closeReceipt = () => {
    setInstrumentType("");
    setSelecteCurrencyType("");
    resetQuantities();
    setAccountNumber("");
    setCustomerInfo({
      ...customerInfo,
      phoneNumber: "",
      depositorName: "",
      remark: "",
      narration: "",
      amount: 0,
      instrumentNumber: "",
    });
    setWithdrawal("");

    setValue("beneficiaryName", "", {
      shouldValidate: true,
    });
    setValue("depositType", "", {
      shouldValidate: true,
    });
    setCustomerInfo((prevState: any) => ({
      ...prevState,
      currencyType: "",
    }));
    setValue("middleName", "", {
      shouldValidate: true,
    });
    setValue("email", "", {
      shouldValidate: true,
    });
    setValue("accountType", "", {
      shouldValidate: true,
    });
    setValue("availableBalance", "", {
      shouldValidate: true,
    });
    setValue("schemaCode", "", {
      shouldValidate: true,
    });
    setValue("dailyLimit", "", {
      shouldValidate: true,
    });
    setValue("weeklyLimit", "", {
      shouldValidate: true,
    });
  };

  const handleChange = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    const selectedWatch = new Date(selectedInstrumentDate);

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    if (selectedWatch < sixMonthsAgo) {
      toast.error("The date is older than 6 months");
      setDateError(true);
    } else if (selectedWatch > new Date()) {
      toast.error("Selected date cannot be greater than today");
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [selectedInstrumentDate]);

  const onOpenTransDetail = useCallback(() => {
    const { depositorName, remark, phoneNumber, narration } = customerInfo;
    const updatedError: any = {};

    if (!selecteCurrencyType) {
      updatedError.selecteCurrencyType = "Select currency type";
    }
    // if (!narration) {
    //   updatedError.narration = "please provide narration";
    // }
    // if (!remark) {
    //   updatedError.remark = "please provide remark";
    // }
    if (!amount || amount === 0) {
      updatedError.amount = "Amount is required, SELECT DENOMINATION ALSO";
    }
    if (!selectedInstrumentType) {
      updatedError.instrumentType = "Select instrument type";
    }
    if (!withdrawal && !depositorName) {
      updatedError.depositorName = "Select instrument type";
    }
    if (!accountNumber) {
      updatedError.accountNumber = "Account number is required";
    }
    if (chequeError) {
      updatedError.cheque = "Valid cheque is required";
    }
    if (!phoneNumber || phoneNumber.length !== 11) {
      updatedError.phoneNumber = "Phone is required, 11 digits";
    }
    if (newDenomination?.length === 0) {
      updatedError.denomination = "Fill denomination";
      toast.error("Fill Denominations");
    }

    const getTotal = calculateTotalPrice(newDenomination as DeProp[]);
    if (getTotal !== amount) {
      updatedError.match = "Not match";
      toast.error("Ensure amount and total amount in denominations are equal");
    }
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    // if (selectedInstrumentDate < sixMonthsAgo) {
    //   updatedError.date = "The date is older than 6 months.";
    // }

    setError(updatedError);

    // if (Object.keys(updatedError).length === 0) {
    //   setIsOpenTransModal(true);
    // } else {
    //   setIsOpenTransModal(false);
    // }
    if (dateError) {
      updatedError.date = "The date is older than 6 months.";
    }
    if (Object.keys(updatedError).length === 0 && !dateError) {
      setDetails(payload);
      setIsOpenTransModal(true);
      setIsDisabled(true);
    }
  }, [
    customerInfo,
    selecteCurrencyType,
    selectedInstrumentType,
    withdrawal,
    accountNumber,
    chequeError,
    newDenomination,
    dateError,
    payload,
    amount,
  ]);

  const allowedTypes = ["CHQ", "LCHQ", "CMS"];

  const onSubmit = async () => {
    try {
      const response = await postTransaction(payload).unwrap();
      if (response.statusCode === 200) {
        setMessage(response?.message);
        setIsOpenTransModal(false);
        setTransactionStatus(true);
        if (response?.data?.tran_Id) {
          setTransId(response?.data?.tran_Id);
        }
        closeReceipt();
        setOpenFields(false);
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else if (error?.status === 500) {
        ErrorHandler(error);
      } else {
        ErrorHandler(error);
      }
    }
  };

  const handleClose = () => {
    setIsOpenTransModal(false);
  };

  const handleCloseReceipt = () => {
    setTransactionReceipt(false);
    setTransactionStatus(false);
    setGeneratePDF(false);
    window.location.reload();
  };
  const handleCloseMandate = () => {
    setOpenMandate(false);
  };
  const generateReceipt = async () => {
    const data = {
      transactionType: "Withdrawal",
      transactionId: transId,
    };
    try {
      const response = await getReceipt(data).unwrap();

      if (response.statusCode === 200) {
        setTransReceiptData(response);
        toast.success(response?.message);
        // setTransactionStatus(false);
        setTransactionReceipt(true);
        setGeneratePDF(true);
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else {
        ErrorHandler(error);
      }
    }
  };

  const handleViewMandate = async () => {
    const value = {
      accountNumber: accountNumber,
    };

    const response = await viewMandate(value).unwrap();
    if (response?.statusCode === 200) {
      toast.success(response.message);
      if (response?.data !== null) {
        const imageString: string | undefined = response?.data?.images;
        setImageData(imageString as string);
        setOpenMandate(true);
      } else {
        setImageData("");
        setOpenMandate(false);
      }

      // const decodedImage = atob(imageData as string);
    }
  };

  const handleValidateCheque = async (e: any) => {
    const { value } = e.target;

    if (value.length === 8) {
      try {
        const response = await validateCheque({
          accountNumber: accountNumber,
          chequeNumber: value,
        }).unwrap();
        setChequeNo(value);
        if (response?.statusCode === 200) {
          toast.success(response?.message);
          setChequeErorr(false);
        }
      } catch (error: any) {
        setChequeErorr(true);
        ErrorHandler(error);
      }
    }
  };

  useEffect(() => {
    const getTotal = calculateTotalPrice(newDenomination as DeProp[]);
    setTotalAmount(getTotal as number);
  }, [newDenomination]);

  return (
    <>
      <Header title="Withdrawal/Cash" />
      <ComponentWrapper>
        <TopCards />

        <Container>
          <HeaderComponent>
            <HeaderText>Withdrawal /Cash Withdrawals </HeaderText>
            <div style={{ marginTop: "24px", width: "538px" }}>
              <PrimaryInput
                name="accountNumber"
                type="text"
                rightIcon={<></>}
                placeholder="Enter Account Number"
                error={undefined}
                label="Account Number"
                check={""}
                padding={""}
                leftIcon={<SearchIcon />}
                onChange={handleCFetchAccount}
                value={undefined}
                register={register}
              />
            </div>
          </HeaderComponent>

          {mutationState.isLoading ? (
            <LoaderContainer>
              <Oval stroke="#5C2684" fill="white" width={24} height={24} />
            </LoaderContainer>
          ) : (
            <>
              {" "}
              {openFields && (
                <>
                  <StatusHolder>
                    <Status
                      color={isStatus === "ACTIVE" ? "#01B272" : "#C43C20"}
                      backgroundColor={
                        isStatus === "ACTIVE"
                          ? "rgba(1, 178, 114, 0.2)"
                          : "rgba(231, 175, 164, 0.3)"
                      }
                    >
                      {isStatus === "ACTIVE" ? "Active" : "Inactive"}
                    </Status>
                  </StatusHolder>

                  <FieldWrap>
                    <PrimaryInput
                      name="beneficiaryName"
                      type={"text"}
                      placeholder="Account Name"
                      rightIcon={<></>}
                      error={undefined}
                      label="Account Name"
                      check={""}
                      padding={""}
                      disabled={true}
                      leftIcon={<InputClose />}
                      onChange={() => {}}
                      value={undefined}
                      register={register}
                    />
                    <PrimaryInput
                      name="depositType"
                      type={"text"}
                      placeholder="customerType"
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}
                      label="Customer Type"
                      check={""}
                      register={register}
                      padding={""}
                      disabled={true}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                  </FieldWrap>
                  <FieldWrap>
                    <PrimaryInput
                      name="middleName"
                      type={"text"}
                      placeholder="Middle Name"
                      rightIcon={<></>}
                      // error={errors.dailyLimit?.message}
                      disabled={true}
                      label="Middle Name"
                      check={""}
                      padding={""}
                      register={register}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                    <PrimaryInput
                      name="email"
                      type="text"
                      placeholder={"Email"}
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}

                      label="Email"
                      disabled={true}
                      check={""}
                      register={register}
                      padding={""}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                  </FieldWrap>
                  <FieldWrap>
                    <PrimaryInput
                      name={"availableBalance"}
                      type="text"
                      placeholder={"Available Balance"}
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}

                      label="Available Balance"
                      check={""}
                      disabled={true}
                      register={register}
                      padding={""}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                    <PrimaryInput
                      name={"schemaCode"}
                      type="text"
                      placeholder="Schema Code"
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}
                      disabled={true}
                      label="Schema Code"
                      check={""}
                      register={register}
                      padding={""}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                  </FieldWrap>
                  <FieldWrap>
                    <PrimaryInput
                      name={"dailyLimit"}
                      type="text"
                      placeholder={"Daily Limit"}
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}

                      label="Daily Limit"
                      check={""}
                      disabled={true}
                      register={register}
                      padding={""}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                    <PrimaryInput
                      name={"weeklyLimit"}
                      type="text"
                      placeholder="Weekly Limit"
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}
                      disabled={true}
                      label="Weekly Limit"
                      check={""}
                      register={register}
                      padding={""}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />
                  </FieldWrap>
                  <FieldWrap>
                    <PrimaryInput
                      name={"accountType"}
                      type="accountType"
                      placeholder="Account Type"
                      rightIcon={<></>}
                      // error={errors.oneTimeLimit?.message}
                      disabled={true}
                      label="Account Type"
                      check={""}
                      register={register}
                      padding={""}
                      leftIcon={<InputClose />}
                      value={undefined}
                      onChange={() => {}}
                    />

                    <ButtonWrap>
                      <div>
                        <PrimaryButton
                          background={"#ffffff"}
                          color={"#5c2684"}
                          width={150}
                          title={"View Mandate"}
                          icon={""}
                          loaderColor="#5c2684"
                          loading={false}
                          isLoading={mandateMutationState.isLoading}
                          leftIconName={<></>}
                          rightIconName={<></>}
                          onClick={handleViewMandate}
                          border={"1px solid #5c2684"}
                        />
                      </div>
                    </ButtonWrap>
                  </FieldWrap>
                </>
              )}
            </>
          )}
        </Container>

        <ContainerDepo>
          <LeftContainerDepo>
            <HeaderText>Cash Withdrawal Information</HeaderText>
            <Instruction>
              Is the drawee the account owner
              <InputDiv>
                <Radio>
                  <Input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="yes"
                    checked={accountOwner}
                    onChange={onSelectAccountOwner}
                  />
                    <label>Yes</label>
                </Radio>
                <Radio>
                  <Input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="no"
                    checked={!accountOwner}
                    onChange={onSelectAccountOwner}
                  />
                    <label>No</label>
                </Radio>
              </InputDiv>
            </Instruction>

            <DropDownC
              value={selectedInstrumentType}
              placeholder="Instrument Type"
              options={withdrawalType}
              errorMessage={error?.instrumentType}
              label={"Instrument Type"}
              setSelectedItem={setInstrumentType}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />
            <DateComponent
              showModal={false}
              onClosed={function (): void {
                throw new Error("Function not implemented.");
              }}
              error={error?.date}
              disabled={!allowedTypes.includes(selectedInstrumentType)}
              value={selectedInstrumentDate}
              handleDateChange={onHandleChange}
              selectDate={setSelectedInstrumentDate}
              label={"Instrument Date"}
            />

            <PrimaryInput
              name="instrumentNumber"
              type="text"
              placeholder="Enter Instrument number"
              rightIcon={<></>}
              error={error?.instrumentNumber}
              label="Instrument Number"
              disabled={allowedTypes.includes(selectedInstrumentType)}
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              value={undefined}
              onChange={onHandleChange}
            />

            <PrimaryInput
              name="chequeNo"
              type="text"
              placeholder="Enter Cheque number"
              rightIcon={<></>}
              error={error?.cheque}
              label="Cheque/Ref No"
              disabled={!allowedTypes.includes(selectedInstrumentType)}
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              value={undefined}
              onChange={handleValidateCheque}
            />

            <DropDownC
              value={selecteCurrencyType}
              placeholder="Currency Type"
              options={currencyType}
              errorMessage={error?.selecteCurrencyType}
              label={"Currency Type"}
              setSelectedItem={setSelecteCurrencyType}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />

            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount *"
              check={""}
              padding={""}
              leftIcon={<></>}
              onChange={(e) => {
                const newValue = Math.max(0, parseInt(e.target.value) ?? 0);
                // const newValue = parseInt(e.target.value, 10) ?? 0;
                setAmount(newValue);
              }}
              value={amount}
            />

            <PrimaryInput
              name="depositorName"
              type="text"
              placeholder="Drawee's First/Last Name"
              rightIcon={<></>}
              error={error.depositorName}
              label="Drawee First/Last Name  *"
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={onHandleChange}
              value={accountOwner ? withdrawal : customerInfo?.depositorName}
            />
            <PrimaryInput
              name="phoneNumber"
              maxLength={11}
              type="number"
              inputMode="numeric"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error?.phoneNumber}
              label="Drawee's Phone Number"
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={onHandleChange}
              value={customerInfo?.phoneNumber}
              // register={register}
            />
            <TextArea
              label={"Remark"}
              name={"remark"}
              padding={0}
              placeholder={"Enter remark"}
              text={""}
              error={error?.remark}
              onChange={onHandleChange}
              value={customerInfo?.remark}
            />
            <TextArea
              label={"Narration"}
              name={"narration"}
              padding={0}
              placeholder={"Enter narration"}
              text={""}
              error={error?.narration}
              onChange={onHandleChange}
              value={customerInfo?.narration}
            />
            <ButtonWrap>
              <div>
                <PrimaryButton
                  background={""}
                  type="button"
                  color={""}
                  width={150}
                  title={"Post Transaction"}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={onOpenTransDetail}
                  border={"#5C2684"}
                />
              </div>
            </ButtonWrap>
          </LeftContainerDepo>
          <RightContainerDepo>
            <DenominationContainer>
              <TopContainer>
                <DHeader>Denomination</DHeader>
                <DHeader>Quantity</DHeader>
                {/* <DHeader>Amt</DHeader> */}
              </TopContainer>
              {initialList.map((denomination, index) => (
                <DenominationWrap key={denomination.denomination}>
                  <Text>{denomination.denomination}</Text>
                  <DenominationInput
                    name={""}
                    type={""}
                    placeholder={""}
                    rightIcon={<></>}
                    label={""}
                    value={denomination.quantity}
                    onChange={(e) =>
                      updateDenomination(index, parseInt(e.target.value))
                    }
                    check={""}
                    padding={""}
                    leftIcon={<></>}
                  />{" "}
                  {/* <Text>{1000*thousand}</Text> */}
                </DenominationWrap>
              ))}
            </DenominationContainer>

            <FieldWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Total Amount:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {selecteCurrencyType}{" "}
                    {Number.isNaN(totalAmount) ? 0 : totalAmount}.00
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Amount in words:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {convertAmountToWords(totalAmount ? totalAmount : 0)}{" "}
                    {selecteCurrencyType === "NGN"
                      ? "Naira"
                      : selecteCurrencyType === "GBP"
                      ? "Pound Sterling"
                      : "Dollar"}
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Transaction Date:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{currentDate.toJSON().slice(0, 10)}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Branch Code:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{branchCode}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Paid By:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{user}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Cash Received By:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {accountOwner ? withdrawal : customerInfo?.depositorName}
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
            </FieldWrapDen>
          </RightContainerDepo>
        </ContainerDepo>
      </ComponentWrapper>

      {isOpenTransModal && (
        <GeneralModal general>
          <Top>
            <TopTitle>Cash Withdrawal Transaction</TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleClose} />
            </IconWrap>
          </Top>
          <FieldWrapD>
            <DetailsWrap>
              <LeftD>
                <Text>Is Owner?</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.isOwner === true ? "True" : "False"}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Instrument Type</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.instrumentType}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Instrument Date</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.instrumentDate}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Instrument Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.instrumentNumber}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Currency Type</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.currencyType}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Narration</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.narration}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Phone Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text> {details?.phoneNumber}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Amount</Text>{" "}
              </LeftD>
              <RightD>
                <Text>
                  {" "}
                  {new Intl.NumberFormat("ja-JP", {
                    style: "currency",
                    currency: details?.currencyType,
                  }).format(details?.amount as any)}
                </Text>{" "}
              </RightD>
            </DetailsWrap>
            {/* <DetailsWrap>
              <LeftD>
                <Text>Fee</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.fee}</Text>{" "}
              </RightD>
            </DetailsWrap> */}
            <DetailsWrap>
              <LeftD>
                <Text>Drawee's Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.draweeName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Remark</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.remark}</Text>{" "}
              </RightD>
            </DetailsWrap>
          </FieldWrapD>
          <Radio>
            <Input
              type="radio"
              id="html"
              name="fav_language"
              value="acc"
              onChange={handleChange}
            />
             {" "}
            <label>
              I have read through all details displayed above and have ensured
              the information is accurate.
            </label>
          </Radio>
          <ButtonWrap>
            <div>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={100}
                title={"Post Transaction"}
                icon={""}
                isLoading={isLoading}
                loading={false}
                isDisabled={isDisabled}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onSubmit}
                leftIcon={false}
                border={""}
              />
            </div>
          </ButtonWrap>
        </GeneralModal>
      )}

      {transactionStatus && (
        <SuccessModal
          message={message}
          handleClose={handleClose}
          generateReceipt={generateReceipt}
          approval={transId !== ""}
        />
      )}

      {transactionReceipt && (
        <GeneralModal general>
          <Top>
            <TopTitle></TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleCloseReceipt} />
            </IconWrap>
          </Top>

          {generatePDF && (
            <PDFGenerator
              componentToRender={
                <ReceiptView
                  data={transReceipt?.data}
                  title="Withdrawal Receipt"
                />
              }
            />
          )}
        </GeneralModal>
      )}

      {openMandate && imageData !== "" && (
        <>
          <GeneralModal general>
            <Top>
              <TopTitle>Mandate </TopTitle>
              <IconWrap>
                <CloseIcon onClick={handleCloseMandate} />
              </IconWrap>
            </Top>

            <>
              {" "}
              <img src={`data:image/jpeg;base64,${imageData}`} alt="ch" />
            </>
          </GeneralModal>
        </>
      )}
    </>
  );
};

export default CashWithdrawal;
