import React, { useEffect, useMemo, useState } from "react";
import PrimaryInput from "../PrimaryInput";
import {
  Top,
  TableBody,
  TableBodyData,
  TableBodyRow,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderTitle,
  TableWrapper,
  TopLeftContainer,
  TopRightContainer,
  TopTitle,
  TopSelect,
  TablePaginatorWrapper,
} from "./style";
import Paginator from "../Paginator";
import { v4 as uuidv4 } from "uuid";
import { HeaderData } from "../../types/Types";

export interface TableCProps {
  header: HeaderData[];
  body: any[][] | undefined;
  arrOfObject: boolean;
  TopComponent?: boolean;
  itemsPerPage: number;
}

const PrimaryTable: React.FC<TableCProps> = ({
  header,
  body,
  TopComponent,
  itemsPerPage,
  arrOfObject,
}) => {
  const [currentItems, setCurrentItems] = useState<any[][] | undefined>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState(0);

  const dataBody: any[][] = useMemo(() => {
    return body ?? [];
  }, [body]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(dataBody?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(dataBody?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, dataBody]);

  const handlePageClick = (e: any) => {
    const newOffset = (e.selected * itemsPerPage) % dataBody?.length;
    setItemOffset(newOffset);
  };

  return (
    <TableContainer>
      {TopComponent && (
        <></>
        // <Top>
        //   <TopLeftContainer>
        //     <TopTitle>Transaction Table</TopTitle>
        //   </TopLeftContainer>
        //   <TopRightContainer>
        //     <PrimaryInput
        //       name={"search"}
        //       type={"text"}
        //       placeholder={"Search"}
        //       rightIcon={<></>}
        //       label={""}
        //       check={""}
        //       padding={""}
        //       leftIcon={<></>}
        //       password={false}
        //     />
        //     Show
        //     <TopSelect onChange={handleSelect} value={itemsPerPage}>
        //       {option?.map((option) => (
        //         <option value={option.value}>{option.value}</option>
        //       ))}
        //     </TopSelect>
        //     Entries
        //   </TopRightContainer>
        // </Top>
      )}
      <TableWrapper>
        <TableHeader>
          <TableHeaderRow>
            {header?.map((data: { title: string; id: number }) => (
              <TableHeaderTitle key={data.id}>{data.title}</TableHeaderTitle>
            ))}
          </TableHeaderRow>
        </TableHeader>

        {currentItems?.map((text) => {
          return (
            <TableBodyRow key={uuidv4()}>
              {Object.values(text).map((value, index) => (
                <TableBodyData key={uuidv4()}>{value}</TableBodyData>
              ))}
            </TableBodyRow>
          );
        })}
      </TableWrapper>
      {dataBody?.length > itemsPerPage && (
        <TablePaginatorWrapper>
          <Paginator handlePageClick={handlePageClick} pageCount={pageCount} />
        </TablePaginatorWrapper>
      )}
    </TableContainer>
  );
};

export default PrimaryTable;
