export function convertAmountToWords(n: number): string {
  if (n < 0) return "Invalid input";

  const singleDigit: string[] = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const doubleDigit: string[] = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const belowHundred: string[] = [
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (n === 0) return "Zero";

  function translate(n: number): string {
    let word = "";
    if (n < 10) {
      word = singleDigit[n] + " ";
    } else if (n < 20) {
      word = doubleDigit[n - 10] + " ";
    } else if (n < 100) {
      const rem = translate(n % 10);
      word =
        belowHundred[Math.trunc((n - (n % 10)) / 10) - 2] +
        (rem !== "" ? " " + rem : "");
    } else if (n < 1000) {
      word =
        singleDigit[Math.trunc(n / 100)] + " Hundred " + translate(n % 100);
    } else if (n < 1000000) {
      word =
        translate(Math.trunc(n / 1000)).trim() +
        " Thousand " +
        translate(n % 1000);
    } else if (n < 1000000000) {
      word =
        translate(Math.trunc(n / 1000000)).trim() +
        " Million " +
        translate(n % 1000000);
    } else {
      word =
        translate(Math.trunc(n / 1000000000)).trim() +
        " Billion " +
        translate(n % 1000000000);
    }
    return word;
  }

  const result = translate(n);
  return result.trim().replace(/ +/g, " ").replace(/\s+$/, "");
}
