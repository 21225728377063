import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import {
  ApprovalResponse,
  ApprovalParamater,
  ApproveLimitPayload,
} from "./type";
import { generateHeader } from '../../utils/headers';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const approvalApi = createApi({
  reducerPath: "approvalApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //all
    allApproval: builder.mutation<ApprovalResponse, ApprovalParamater>({
      query: ({ pageNumber, pageSize }) => ({
        url: `approval/all-approval-lists?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //deposit
    depositApproval: builder.mutation<ApprovalResponse, ApprovalParamater>({
      query: ({ pageNumber, pageSize }) => ({
        url: `approval/dposit-approval-lists?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //transfer
    transferApproval: builder.mutation<ApprovalResponse, ApprovalParamater>({
      query: ({ pageNumber, pageSize }) => ({
        url: `approval/transfer-approval-lists?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //withdrawal
    withdrawalApproval: builder.mutation<ApprovalResponse, ApprovalParamater>({
      query: ({ pageNumber, pageSize }) => ({
        url: `approval/withdrawal-approval-lists?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //deposit approval
    approveDepositLimit: builder.mutation<any, ApproveLimitPayload>({
      query: (body) => ({
        url: "approval/approve-deposit",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //withrawal approval
    approveWithdrawalLimit: builder.mutation<any, ApproveLimitPayload>({
      query: (body) => ({
        url: "approval/approve-withdrawal",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //transfer approval
    approveTransferLimit: builder.mutation<any, ApproveLimitPayload>({
      query: (body) => ({
        url: "approval/approve-transfer",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),
  }),
});

export const {
  useAllApprovalMutation,
  useDepositApprovalMutation,
  useTransferApprovalMutation,
  useWithdrawalApprovalMutation,
  useApproveDepositLimitMutation,
  useApproveTransferLimitMutation,
  useApproveWithdrawalLimitMutation,
} = approvalApi;
