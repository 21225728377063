import styled from "styled-components";

export const TableContainer = styled.div`
  background: white;
  border-radius: 24px;
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 30px;
  font-size: 13px;
  gap: 32px;
  width: 100%;
  table-layout: fixed !important;
  display: flex;
  flex-direction: column;
`;
export const ActiveBtn = styled.div`
  background: green;
  color: white;
  border-radius: 20px;
  width: 80px;
`;
export const ButtonsWrap = styled.div`
  //padding: 1vw;
`;
export const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const TableCardTop = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const TableCardTitle = styled.p`
  font-weight: 600;
  font-size: 23px;
  color: #171b03;
  text-align: center;
`;
export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  left: -100%;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
`;

export const DropDownLinks = styled.a`
  color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 6px;
  padding-right: 6px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  &:hover {
    background-color: #5c2684;
    color: white;
  }
`;
export const DropDown = styled.div`
  position: relative;
  display: inline-block;
  //width : 300px;
  &:hover ${DropDownContent} {
    display: block;
  }
`;
export const SeeAll = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #8ba00d;
  text-decoration: underline;
`;
export const TableWrapper = styled.table`
  width: 100%;
  color: #242627;
  border-spacing: 0;
  table-layout: fixed;
  background: #5c2684;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  /* tr {
    width: 100%;
  } */

  th {
    font-size: 14px;
    padding: 15px 8px;
    border-left: 1px solid #ffffff;
  }
  td {
    //font-size: clamp(12px, 1.4vw, 14px);
    font-size: 12px;
    line-height: 21px;
    text-align: left;
    padding: 10px 14px;
    margin: 0;
    border-left: 1px solid #ffffff;
  }
`;

export const TableHeader = styled.thead`
  width: 100%;
  text-align: left;
  font-size: 10px !important;
`;
export const BtnDiv = styled.div`
  width: 60px !important;
`;
export const PercentageBtn = styled.button`
  border-radius: 20px;
  background: rgba(1, 178, 114, 0.2);
  border: none;
  width: 75px;
  font-size: 12px;
  margin-left: -10px;
  padding: 0.5vw;
  font-weight: bold;
  color: #01b272;
`;

export const PercentageBtnRed = styled.button`
  border-radius: 20px;
  background: rgb(226, 26, 26, 0.2);
  border: none;
  width: 75px;

  font-size: 12px;
  margin-left: 1vw;
  padding: 0.5vw;
  color: red;
`;
export const TableHeaderRow = styled.tr`
  border-bottom: 1px solid #e9edf7;
  width: 100%;
  font-weight: 600 !important;

  /* background-color: #f3f6e7; */
`;
export const TableHeaderTitle = styled.th`
  font-weight: 500;
  text-align:center;
  font-size: 12px;
  //font-size: clamp(18px, 1.4vw, 20px);
  color: white;
`;

export const TableBody = styled.tbody`
  width: 100%;
  border: 2px solid #161616;
`;
export const TableBodyRow = styled.tr`
  :nth-of-type(even) {
    background: #fafafa;
  }
  :nth-of-type(odd) {
    background: #fff;
  }
`;

export const Flexer = styled.div`
  display: flex;
`;
export const TableBodyData = styled.td`
  font-weight: 400;
  font-size: 11px !important;
  text-align: center !important;
`;
export const Links = styled.div`
  text-decoration: underline;
  color: #5c2684;
  font-weight: bold;
  padding-left: 20px;
  cursor: pointer;
`;

export const TableButton = styled.button`
  font-weight: 400;
  color: #8ba00d;
  padding: 10px 18px;
  border-radius: 4px;
  background: #f3f6e7;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LeftIconContainer = styled.div`
  background-color: #8ba00d;
  padding: 2px;
  border-radius: 4px;
`;

export const TablePaginatorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 48px;
`;

export const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const CountText = styled.p`
  font-weight: 400;
  font-size: 13px;
  color: #828477;
`;
export const SelectWrapper = styled.select`
  border: none;
  outline: none;
`;
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;
export const TopLeftContainer = styled.div`
  display: flex;
`;
export const TopRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 29px;
  line-height: 120%;
  color: #2d2235;
`;
export const TopSelect = styled.select`
  width: 50px;
  border: none;
`;
