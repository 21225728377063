import { useState, useRef } from "react";
import {
  Wrapper,
  CalendarWrapper,
  TransparentBackdrop,
  Iconwrapper,
  Label,
  Error,
  Top,
  Input,
  InputWrapper,
} from "./DateStyle";

import { ReactComponent as CalendarIcon } from "../../assets/svg/calendarblack.svg";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import format from "date-fns/format";

interface DateType {
  showModal: boolean;
  onClosed: () => void;
  value: any;
  error?: string;
  handleDateChange: any;
  disabled?: boolean;
  selectDate: any;
  label: string;
}
const DateComponent: React.FC<DateType> = ({
  showModal,
  selectDate,
  label,
  value,
  error,
  disabled,
  handleDateChange,
}) => {
  const [selecteddate, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [active, setActive] = useState(false);
  const inputRef = useRef(null);

  const hideCalendar = () => {
    setShowCalendar(false);
  };

  const pickDay = (day: any) => {
    const selectedDate = format(day, "yyyy-MM-dd");
    setDate(selectedDate);
    selectDate(selectedDate);
    hideCalendar();
  };

  const handleBorder = () => {
    setActive(!active);
  };

  return (
    <Wrapper key="DateInput">
      <Top>
        <Label>{label}</Label>
      </Top>
      <InputWrapper
        border={active ? "1px solid ##C2684" : "1px solid #ececec"}
        ref={inputRef}
        onFocus={handleBorder}
        onClick={() => setShowCalendar((prev) => !prev)}
        onBlur={() => setActive(false)}
      >
        <Input
          type="text"
          placeholder="YYYY/MM/DD"
          readOnly
          value={value === "" ? "" : value}
          disabled={disabled}
        />
        <Iconwrapper>
          <label htmlFor="date">
            <CalendarIcon />
          </label>
        </Iconwrapper>
      </InputWrapper>
      {showCalendar && !disabled ? (
        <>
          <CalendarWrapper>
            <Calendar onClickDay={pickDay} className={"calendar"} />
          </CalendarWrapper>
          <TransparentBackdrop onClick={hideCalendar} />
        </>
      ) : null}
      <Error>{error}</Error>
    </Wrapper>
  );
};

export default DateComponent;
