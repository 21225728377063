import {
  Wrapper,
  Title,
  CardContainer,
  TopTitle,
  MTop,
  IconWrap,
  FieldWrapD,
  LeftD,
  RightD,
  DetailsWrap,
  Text,
  FieldWrap,
} from "./CardStyles";
import TransactionCard from "../Cards/TransactionCard";
import { cardComponentType } from "../../types/Types";
import { formatCurrency } from "../../utils";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { TillBalance } from "../../assets/svgComponent/TillBalanceIcon";
import { InterTransfer } from "../../assets/svgComponent/InterTransfer";
import { WithDrawalIcon } from "../../assets/svgComponent/WithDrawalIcon";
import { WalletTransferIcn } from "../../assets/svgComponent/WalletTranserIcn";
import { IntraTransFerIcon } from "../../assets/svgComponent/IntraTransferIcon";
import GeneralModal from "../Modal/GeneralModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery } from "../../services/admin/adminService";

const Activities = ({ dashboardAnalysis }: any) => {
  const [openModal, setOpenModal] = useState(false);
  const staffInfo = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );
  const getAllUsers = useGetAllUsersQuery({ pageNumber: 1 });
  const userData = getAllUsers?.data?.data?.data;

  const adminRole = staffInfo?.user?.role === "Admin";

  const transactionData: cardComponentType[] = [
    {
      title: "Till Balance",
      amount: formatCurrency(dashboardAnalysis?.tillBalance) ?? "0",
      img: <TillBalance />,
      count: dashboardAnalysis?.tillCount ?? 0,
    },
    {
      title: "Deposits",
      amount: formatCurrency(dashboardAnalysis?.totalDeposits) ?? "0",
      img: <InterTransfer />,
      count: dashboardAnalysis?.depositCount ?? 0,
    },
    {
      title: "Withdrawal",
      amount: formatCurrency(dashboardAnalysis?.totalWithdrawals) ?? "0",
      img: <WithDrawalIcon />,
      count: dashboardAnalysis?.depositCount ?? 0,
    },
    {
      title: "Inter Transfer",
      amount: formatCurrency(dashboardAnalysis?.totalInterTransfers) ?? "0",
      img: <InterTransfer />,
      count: dashboardAnalysis?.accountWalletTransferCount ?? 0,
    },
    {
      title: "Intra Transfer",
      amount: formatCurrency(dashboardAnalysis?.totalIntraTransfers) ?? "0",
      img: <IntraTransFerIcon />,
      count: dashboardAnalysis?.intraTransferCount ?? 0,
    },
    {
      title: "Wallet- Wallet Transfer",
      amount:
        formatCurrency(dashboardAnalysis?.totalWalletWalletTransfers) ?? "0",
      img: <WalletTransferIcn />,
      count: dashboardAnalysis?.walletWalletTransferCount ?? 0,
    },
    {
      title: "Wallet- Account Transfer ",
      amount:
        formatCurrency(dashboardAnalysis?.totalAccountWalletTransfers) ?? "0",
      img: <WalletTransferIcn />,
      count: dashboardAnalysis?.walletAccountTransferCount ?? 0,
    },
    {
      title: "Account- Wallet Transfer",
      amount:
        formatCurrency(dashboardAnalysis?.totalAccountWalletTransfers) ?? "0",
      img: <WalletTransferIcn />,
      count: 0,
    },
  ];
  const adminData: cardComponentType[] = [
    {
      title: "Users",
      amount: "Total Users",
      img: <TillBalance />,
      count: userData?.length,
    },
  ];

  const handleClick = (type: string) => {
    if (dashboardAnalysis?.tellerTills && type === "Till Balance") {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Wrapper>
      <Title>Activities</Title>
      {adminRole ? (
        <>
          <CardContainer>
            {adminData?.map((data) => (
              <TransactionCard key={data?.title} data={data} width={"24%"} />
            ))}
          </CardContainer>
        </>
      ) : (
        <>
          <CardContainer>
            {transactionData?.map((data) => (
              <TransactionCard
                key={data?.title}
                data={data}
                width={"24%"}
                handleClick={() => handleClick(data.title as string)}
              />
            ))}
          </CardContainer>

          {openModal && (
            <GeneralModal general>
              <MTop>
                <TopTitle>Till Balance</TopTitle>
                <IconWrap>
                  <CloseIcon onClick={handleClose} />
                </IconWrap>
              </MTop>

              <FieldWrapD>
                <FieldWrap>
                  {dashboardAnalysis?.tellerTills?.map(
                    (data: any, index: any) => (
                      <>
                        <DetailsWrap>
                          <LeftD>
                            <Text>{data?.currency}</Text>{" "}
                          </LeftD>
                          <RightD>
                            <Text>
                              {" "}
                              {new Intl.NumberFormat("ja-JP", {
                                style: "currency",
                                currency: `${data?.currency}`,
                              }).format(data?.tillBalance)}
                            </Text>{" "}
                          </RightD>
                        </DetailsWrap>
                      </>
                    )
                  )}
                </FieldWrap>
              </FieldWrapD>
            </GeneralModal>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Activities;
