import { useMemo, useState } from "react";
import {
  adminData,
  tellerData,
  tellerNewData,
  hopData,
  ftoData,
  opsSystemControlData,
} from "./data";
import SubMenu from "./subMenu";
import { ReactComponent as FCMBIcon } from "../../assets/svg/FC.svg";
import {
  MainContainer,
  ListWrapper,
  LogoWrapper,
  SidebarNav,
  SidebarWrap,
  SidebarParentWrapper,
  LogoutWrapper,
  IconButtons,
  ButtonTexts,
} from "./style";
import {
  HiOutlineLogout,
  HiOutlineChevronRight,
  HiOutlineChevronLeft,
} from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { IconContext } from "../../../node_modules/react-icons/lib/esm/iconContext";
import { SidebarProps } from "../../types/Types";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "../../services/dashboardAnalysis/dashboardService";
import { handleLogout } from "../../utils";

const Sidebar = () => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [expanded, setExpaned] = useState(() => window.innerWidth > 1050);

  const memoizedIconContextValue = useMemo(
    () => ({ className: "sidebar-icons" }),
    []
  );
  const staffInfo = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );

  const roleList = [
    "HOP A",
    "HOP B",
    "HOP C",
    "HOP D",
    "CSM A",
    "CSM B",
    "CSM C",
    "CSM D",
  ];
  const generalRole = roleList?.includes(staffInfo?.user?.role);

  return (
    <MainContainer width={expanded ? "280px" : "100px"}>
      <IconContext.Provider value={memoizedIconContextValue}>
        <LogoWrapper justify={expanded ? " space-between" : "center"}>
          {expanded && <FCMBIcon />}

          <ListWrapper onClick={() => setExpaned(!expanded)}>
            {expanded ? (
              <HiOutlineChevronLeft color="white" size={20} />
            ) : (
              <HiOutlineChevronRight color="white" size={20} />
            )}
          </ListWrapper>
        </LogoWrapper>

        <SidebarNav>
          <SidebarWrap>
            {/* admin */}
            {staffInfo?.user?.role === "Admin" && (
              <>
                {adminData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* OpsSystemControl */}
            {staffInfo?.user?.role === "OpsSystemControl" && (
              <>
                {opsSystemControlData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* FTO */}
            {staffInfo?.user?.role === "FTO" && (
              <>
                {ftoData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* head teller */}
            {staffInfo?.user?.role === "Teller" && (
              <>
                {tellerData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* teller new */}
            {staffInfo?.user?.role === "TellerNew" && (
              <>
                {tellerNewData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            {/* HOP and CSM */}
            {generalRole && (
              <>
                {hopData.map((item: SidebarProps) => {
                  return (
                    <SubMenu item={item} key={item.title} expanded={expanded} />
                  );
                })}
              </>
            )}

            <SidebarParentWrapper>
              <LogoutWrapper onClick={() => handleLogout(logout, navigate)}>
                <IconButtons>
                  <HiOutlineLogout color={"#464347"} size={20} />
                  {expanded && (
                    <ButtonTexts color={"#464347"}>Logout</ButtonTexts>
                  )}
                </IconButtons>
              </LogoutWrapper>
            </SidebarParentWrapper>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </MainContainer>
  );
};

export default Sidebar;
