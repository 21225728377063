import { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import GeneralModal from "../../components/Modal/GeneralModal";
import { HeaderComponent } from "../BioWithdrawal/style";
import {
  HeaderText,
  FieldWrap,
  ComponentWrapper,
  Container,
  ButtonWrap,
  FieldWrapD,
  ButtonWrapLeft,
  Top,
  TopTitle,
  IconWrap,
  Text,
  LoaderDiv,
  DetailsWrap,
  LeftD,
  RightD,
  SearchWrap,
} from "./style";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { ReactComponent as SuccessIcon } from "../../assets/svg/transactionSuccess.svg";
import { ReactComponent as FailedIcon } from "../../assets/svg/transFailed.svg";
import { useSelector } from "react-redux";
import { useGetAnalyticsQuery } from "../../services/dashboardAnalysis/dashboardService";
import {
  IntraBankDeposit,
  useGetAccDetailsMutation,
  useGetBanksQuery,
  useGetInterBankAccountDetailsQuery,
  usePostDepositMutation,
  usePostInterBankMutation,
  useViewMandateMutation,
  usePostIntraBankMutation,
} from "../../services/transaction/transaction";
import {
  currencyType,
  interSchema,
  withdrawalType,
  depositType,
  instrumentType,
  ErrorHandler,
} from "../../utils/config";
import Header from "../../components/Header/Header";
import TransactionCard from "../../components/Cards/TransactionCard";
import { cardComponentType } from "../../types/Types";
import { formatCurrency } from "../../utils";
import DateComponent from "../../components/Date/Date";
import PrimaryButton from "../../components/Buttons/Button";
import { convertAmountToWords } from "../../utils/amountWriter";
import Modal from "../../components/Modal/CustomModal";
// import ReceiptView from "../CashWithdrawals/Receipt";
import { KeyValueMapping } from "../../pages/CashWithdrawals/ViewTransDetails";
import PDFGenerator from "../../components/PDFGenerator/PdfGenerator";
import TopCards from "../../components/Cards/TopCards";
import PuffLoader from "../../components/Loader";

const FcmbToOther = () => {
  const [getAccount, mutationState] = useGetAccDetailsMutation();
  const accFetchLoading = mutationState?.isLoading;
  const [instrument, setInstrument] = useState("");
  const currentDate = new Date();
  const branchcode = useSelector(
    (state: any) => state?.StaffDataReducer?.userDetails?.data?.branchCode
  );
  const [error, setError] = useState<{
    amount: string;
    narration?: string;
    currency: string;
    instrument: string;
    beneAccNum: string;
    selectedDepositType: string;
    accountNumber: string;
    // fee: string;
    chequeNo: string;
    selectDate: string;
    phoneNumber: string | undefined;
  }>({
    amount: "",
    // fee: "",
    chequeNo: "",
    narration: "",
    selectedDepositType: "",
    currency: "",
    phoneNumber: "",
    selectDate: "",
    beneAccNum: "",
    instrument: "",
    accountNumber: "",
  });
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(interSchema),
  });
  const formatAmount = (currency: string, amount: number) => {
    if (currency === "NGN") {
      return `${convertAmountToWords(amount)} Naira`;
    }
    if (currency === "USD") {
      return `${convertAmountToWords(amount)} Dollars`;
    }
    if (currency === "GBP") {
      return `${convertAmountToWords(amount)} Pounds`;
    }
    if (currency === "EUR") {
      return `${convertAmountToWords(amount)} Euros`;
    }
  };
  const staffInfo = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo
  );
  const [result, setResult] = useState<{
    statusCode: number;
    message: string;
    data: any;
  }>({
    statusCode: 0,
    message: "",
    data: null,
  });
  // const [fee, setFee] = useState("");
  const getBanks = useGetBanksQuery();
  const listOfBanks = getBanks?.data?.data;
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [isOpenTransModal, setIsOpenTransModal] = useState(false);
  const [beneName, setBeneName] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [sortcode, setSortCode] = useState("");
  const [imageData, setImageData] = useState("");
  const [openMandate, setOpenMandate] = useState(false);
  const bankUser = staffInfo?.user?.displayName ?? ";";
  const [accountNumber, setAccountNumber] = useState("");
  const [narration, setNarration] = useState("");
  const [chequeNo, setChequeNo] = useState(0);
  const [transactionReceipt, setTransactionReceipt] = useState(false);
  const [selectDate, setSelectedDate] = useState(
    currentDate.toJSON().slice(0, 10).toString()
  );
  const [accountName, setAccountName] = useState("");
  const [amount, setAmount] = useState(0);
  const [phoneNumber, setPhone] = useState("");
  const [selectedInstrumentType, setInstrumentType] = useState("");
  const [selectedDepositType, setDepositType] = useState("");
  const [beneAccNum, setBeneAccNum] = useState("");
  const [viewMandate, mandateMutationState] = useViewMandateMutation();
  const getInterBank = useGetInterBankAccountDetailsQuery(
    { accountNumber: beneAccNum, bankCode: sortcode },
    {
      skip: beneAccNum.length !== 10 || sortcode.length !== 3,
    }
  );
  const interbankLoading = getInterBank?.isLoading;
  const [postInterBank, { isLoading }] = usePostInterBankMutation();
  const [currency, setCurrency] = useState("");
  const [generatePDF, setGeneratePDF] = useState(false);
  const [openFields, setOpenFields] = useState(false);
  const [openBeneFields, setOpenBeneFields] = useState(false);

  useEffect(() => {
    const handleCFetchAccount = async () => {
      try {
        if (accountNumber.length === 10) {
          const response = await getAccount({
            accountNo: accountNumber,
          }).unwrap();

          setOpenFields(true);
          const beneficiaryName = `${response?.firstName} ${response?.middleName} ${response?.lastName}`;
          setValue("accountName", beneficiaryName, {
            shouldValidate: true,
          });
          setAccountName(beneficiaryName);
          setValue("depositType", response?.customerType as string, {
            shouldValidate: true,
          });
          setValue("bvn", response?.bvn as string, {
            shouldValidate: true,
          });
          setValue(
            "customerAccountDescription",
            response?.accountType as string,
            {
              shouldValidate: true,
            }
          );
          setValue("availableBalance", response?.availableBalance as any, {
            shouldValidate: true,
          });
          setValue("schemaCode", response?.schemeCode as string, {
            shouldValidate: true,
          });
          setValue(
            "dailyLimit",
            new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: "NGN",
            }).format(response?.limitResponse?.dailyLimit as number),

            {
              shouldValidate: true,
            }
          );

          setValue(
            "weeklyLimit",
            new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: "NGN",
            }).format(response?.limitResponse?.weeklyLimit as number),

            {
              shouldValidate: true,
            }
          );
        } else {
        }
      } catch (error: any) {
        ErrorHandler(error);
      }
    };
    handleCFetchAccount();
  }, [accountNumber, getAccount, setValue]);

  const filteredData = useMemo(() => {
    if (!selectedBank) {
      return null;
    }
    const normalizedSearchTerm = selectedBank?.toUpperCase();
    return listOfBanks?.filter((item: any) =>
      item.institutionName.toUpperCase().includes(normalizedSearchTerm)
    );
  }, [listOfBanks, selectedBank]);

  useEffect(() => {
    const handleBFetchAccount = async () => {
      try {
        if (beneAccNum.length === 10 && sortcode && selectedBank) {
          const response = await getInterBank;

          setOpenBeneFields(true);
          const beneficiaryName = response?.data?.data?.accountName;
          setValue("beneficiaryAccountName", beneficiaryName, {
            shouldValidate: true,
          });
          setBeneName(beneficiaryName);
        } else {
        }
      } catch (error: any) {
        ErrorHandler(error);
      }
    };
    handleBFetchAccount();
  }, [beneAccNum, getInterBank, selectedBank, setValue, sortcode]);
  const onOpenTransDetail = useCallback(() => {
    const updatedError: any = {};
    if (!narration) {
      updatedError.narration = "Field can not be blank";
    }
    // if (!fee) {
    //   updatedError.fee = "Please input a fee";
    // }
    if (selectedDepositType === "Cheque Deposit" && !chequeNo) {
      updatedError.chequeNo = "Cheque ref no required";
    }
    if (!selectedDepositType) {
      updatedError.selectedDepositType = "Please select a type";
    }
    if (!instrument) {
      updatedError.instrument = "Instrument is Required";
    }
    if (!phoneNumber || phoneNumber.length < 11) {
      updatedError.phoneNumber = "Phone is required, 11 digits";
    }
    if (!beneAccNum) {
      updatedError.beneAccNum = "Required";
    }
    if (!accountNumber) {
      updatedError.accountNumber = "Required";
    }
    if (!currency) {
      updatedError.currency = "Select currency";
    }
    if (!amount || amount === 0) {
      updatedError.amount = "Amount is required";
    }
    if (!selectDate) {
      updatedError.selectDate = "Date is required";
    }

    setError(updatedError);
    setIsOpenTransModal(Object.keys(updatedError).length === 2);
  }, [
    accountNumber,
    amount,
    beneAccNum,
    chequeNo,
    currency,
    // fee,
    narration,
    phoneNumber,
    selectDate,
    instrument,
    selectedDepositType,
  ]);
  const onSubmit = async () => {
    const payload = {
      isOwner: true,
      amount: amount,
      // fee: fee,
      currencyType: currency,
      instrumentType: selectedInstrumentType,
      instrumentDate: selectDate,
      creditAccountName: beneName,
      narration: narration,
      phoneNumber: phoneNumber,
      debitAccountNumber: accountNumber,
      creditAccountNumber: beneAccNum,
      destinationBankCode: sortcode,
      debitAccountKYCLevel: "string",
      nameEnquiryReference: "",
      remark: narration,
      chequeRefNo: !chequeNo ? "string" : chequeNo,
    };
    onOpenTransDetail();
    try {
      const response = await postInterBank(payload).unwrap();
      setResult(response);
      setTransactionStatus(true);
      if (response) {
        setIsOpenTransModal(false);
      }
    } catch (err: any) {
      setTransactionStatus(true);
      setResult(err);
      if (err) {
        setIsOpenTransModal(false);
      }
    }
  };
  const closeReceipt = () => {
    setCurrency("");
    setNarration("");
    setPhone("");
    setChequeNo(0);
    setSelectedBank("");
    setBeneName("");
    setInstrument("");
    setDepositType("");
    setAccountNumber("");
    setAmount(0);
    setBeneAccNum("");
    setSortCode("");
    setTransactionStatus(false);
    setTransactionReceipt(false);
    // setFee("");
  };
  const transReceipt = {
    Sender: accountName,
    "Sender Account Number": accountNumber,
    Beneficiary: beneName,
    "Beneficiary Bank": selectedBank,
    "Beneficiary Account Number": beneAccNum,
    Amount: amount,
    Currency: currency,
    Phone: phoneNumber,
    "Beneficiary Bank Code": "214",
    Narration: narration,
    Date: new Date(Date.now()).toLocaleDateString(),
  };

  const allowedTypes = ["CHQ", "LCHQ", "CMS"];

  const handleViewMandate = async () => {
    const value = {
      accountNumber: accountNumber,
    };

    const response = await viewMandate(value).unwrap();
    if (response?.statusCode === 200) {
      toast.success(response.message);
      if (response?.data !== null) {
        const imageString: string | undefined = response?.data?.images;
        setImageData(imageString as string);
        setOpenMandate(true);
      } else {
        setImageData("");
        setOpenMandate(false);
      }

      // const decodedImage = atob(imageData as string);
    }
  };

  const handleCloseMandate = () => {
    setOpenMandate(false);
  };

  return (
    <>
      <Header title="FCMB-to-Others" />
      <ComponentWrapper>
        <TopCards />
        <Container>
          <HeaderComponent>
            <HeaderText>Customer Information</HeaderText>
            <div style={{ marginTop: "24px", width: "338px" }}>
              <PrimaryInput
                name="accountNumber"
                type="text"
                rightIcon={<></>}
                placeholder="Enter Account Number"
                label="Account Number"
                check={""}
                error={error?.accountNumber}
                padding={""}
                leftIcon={<SearchIcon />}
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
                register={register}
              />
            </div>
          </HeaderComponent>
          {accFetchLoading ? (
            <PuffLoader />
          ) : (
            openFields &&
            accountNumber.length === 10 &&
            mutationState?.data !== undefined && (
              <>
                <FieldWrap>
                  <PrimaryInput
                    name="accountName"
                    type={"text"}
                    placeholder="Account Name"
                    rightIcon={<></>}
                    error={undefined}
                    label="Account Name"
                    check={""}
                    padding={""}
                    disabled={true}
                    leftIcon={<InputClose />}
                    onChange={() => {}}
                    value={undefined}
                    register={register}
                  />
                  <PrimaryInput
                    name="depositType"
                    type={"text"}
                    placeholder="customerType"
                    rightIcon={<></>}
                    label="Customer Type"
                    check={""}
                    register={register}
                    padding={""}
                    disabled={true}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                </FieldWrap>
                <FieldWrap>
                  <PrimaryInput
                    name="customerAccountDescription"
                    type={"text"}
                    placeholder="Customer Account Description"
                    rightIcon={<></>}
                    disabled={true}
                    label="Customer Account Description"
                    check={""}
                    padding={""}
                    register={register}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                  <PrimaryInput
                    name="bvn"
                    type="text"
                    placeholder={"Customer Bvn"}
                    rightIcon={<></>}
                    label="Customer Bvn"
                    disabled={true}
                    check={""}
                    register={register}
                    padding={""}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                </FieldWrap>

                <FieldWrap>
                  <PrimaryInput
                    name={"availableBalance"}
                    type="text"
                    placeholder={"Available Balance"}
                    rightIcon={<></>}
                    label="Available Balance"
                    check={""}
                    disabled={true}
                    register={register}
                    padding={""}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                  <PrimaryInput
                    name={"schemaCode"}
                    type="text"
                    placeholder="Schema Code"
                    rightIcon={<></>}
                    disabled={true}
                    label="Schema Code"
                    check={""}
                    register={register}
                    padding={""}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                </FieldWrap>
                <FieldWrap>
                  <PrimaryInput
                    name={"dailyLimit"}
                    type="text"
                    placeholder={"Daily Limit"}
                    rightIcon={<></>}
                    // error={errors.oneTimeLimit?.message}

                    label="Daily Limit"
                    check={""}
                    disabled={true}
                    register={register}
                    padding={""}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                  <PrimaryInput
                    name={"weeklyLimit"}
                    type="text"
                    placeholder="Weekly Limit"
                    rightIcon={<></>}
                    // error={errors.oneTimeLimit?.message}
                    disabled={true}
                    label="Weekly Limit"
                    check={""}
                    register={register}
                    padding={""}
                    leftIcon={<InputClose />}
                    value={undefined}
                    onChange={() => {}}
                  />
                </FieldWrap>

                <ButtonWrapLeft>
                  <PrimaryButton
                    background={""}
                    color={""}
                    width={"150px"}
                    onClick={handleViewMandate}
                    title={"View Mandate"}
                    loaderColor="white"
                    loading={false}
                    isLoading={mandateMutationState.isLoading}
                    leftIconName={<></>}
                    rightIconName={<></>}
                    border={""}
                  />
                </ButtonWrapLeft>
              </>
            )
          )}
        </Container>

        <Container>
          <HeaderComponent>
            <HeaderText>Beneficiary Information</HeaderText>
          </HeaderComponent>

          <FieldWrap>
            <PrimaryInput
              name="beneficiaryAccountNumber"
              type="text"
              rightIcon={<></>}
              placeholder="Enter Account Number"
              error={error?.beneAccNum}
              label="Account Number"
              check={""}
              padding={""}
              leftIcon={<SearchIcon />}
              onChange={(e) => setBeneAccNum(e.target.value)}
              value={beneAccNum}
              register={register}
            />

            <div>
              <PrimaryInput
                name={"Beneficiary Bank"}
                type={"text"}
                placeholder={"Enter Bank Here"}
                rightIcon={<></>}
                error={errors.beneficiaryAccount?.message}
                disabled={undefined}
                label={"Beneficiary Bank"}
                check={""}
                padding={""}
                leftIcon={<SearchIcon />}
                value={selectedBank}
                onChange={(e: any) => {
                  setSelectedBank(e.target.value);
                  setIsSearchOpen(true);
                }}
                password={false}
              />

              {isSearchOpen && filteredData ? (
                <SearchWrap>
                  {filteredData.map(
                    (item: {
                      id: number;
                      institutionCode: string;
                      institutionName: string;
                      logo: string;
                    }) => (
                      <div>
                        <img src={item.logo} alt="logo" />
                        <p
                          key={item.id}
                          onClick={() => {
                            setSelectedBank(item.institutionName);
                            setIsSearchOpen(false);
                            setSortCode(item.institutionCode);
                          }}
                        >
                          {item.institutionName}
                        </p>
                      </div>
                    )
                  )}
                </SearchWrap>
              ) : null}
            </div>
            {interbankLoading ? (
              <LoaderDiv>
                <PuffLoader />
              </LoaderDiv>
            ) : (
              <PrimaryInput
                name="beneficiaryAccountName"
                type={"text"}
                placeholder="Account Name"
                rightIcon={<></>}
                error={undefined}
                label="Account Name"
                check={""}
                padding={""}
                disabled={true}
                leftIcon={<InputClose />}
                onChange={() => {}}
                value={beneName}
                register={register}
              />
            )}
            <PrimaryInput
              name="BankCode"
              type={"text"}
              placeholder="Bank Code"
              rightIcon={<></>}
              label="Bank Code"
              check={""}
              // register={register}
              padding={""}
              disabled={false}
              leftIcon={<InputClose />}
              value={sortcode}
              onChange={(e) => setSortCode(e.target.value)}
            />
          </FieldWrap>
        </Container>

        <Container>
          <HeaderComponent>
            <HeaderText>Funds Transfer</HeaderText>
          </HeaderComponent>
          <FieldWrap>
            <DropDownC
              value={currency}
              placeholder="Select Currency"
              options={currencyType}
              errorMessage={error.currency}
              label={"Currency"}
              setSelectedItem={(value: any) => setCurrency(value)}
              setOptionValue={() => {}}
              setSelectedItems={() => {}}
            />

            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount *"
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={(e) => {
                const newValue = Math.max(0, parseInt(e.target.value) || 0);
                setAmount(newValue);
              }}
              value={amount}
            />
          </FieldWrap>
          <FieldWrap>
            <PrimaryInput
              name="phoneNumber"
              maxLength={11}
              type="number"
              inputMode="numeric"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error?.phoneNumber}
              label="Depositor’s Phone Number"
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={(e) => setPhone(e.target.value)}
              value={phoneNumber}
            />
            <PrimaryInput
              name={"Narration"}
              type={"string"}
              error={error?.narration}
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
              label="Narration/Remarks"
              placeholder={"Enter description here"}
            />
          </FieldWrap>
          <FieldWrap>
            <DropDownC
              value={selectedInstrumentType}
              placeholder="Instrument Type"
              options={withdrawalType}
              errorMessage={undefined}
              label={"Instrument Type"}
              setSelectedItem={setInstrumentType}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />
            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectDate}
              handleDateChange={(e: any) => setSelectedDate(e.target.value)}
              selectDate={setSelectedDate}
              label={"Instrument Date"}
              error={error?.selectDate}
              disabled={!allowedTypes.includes(selectedInstrumentType)}
            />
          </FieldWrap>

          <FieldWrap>
            {/* <PrimaryInput
              name="Fee"
              type="number"
              inputMode="numeric"
              placeholder="Enter Fee amount"
              rightIcon={<></>}
              error={error?.fee}
              label="Fee"
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={(e) => setFee(e.target.value)}
              value={fee}
            /> */}
          </FieldWrap>

          <FieldWrap>
            <PrimaryInput
              name="chequeNo"
              type="text"
              placeholder="Enter Cheque number"
              rightIcon={<></>}
              error={undefined}
              label="Cheque/Ref No"
              disabled={!allowedTypes.includes(selectedInstrumentType)}
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              value={chequeNo}
              onChange={(e) => setChequeNo(e.target.value)}
            />
          </FieldWrap>
          <FieldWrap>
            <ButtonWrap>
              <PrimaryButton
                background={""}
                type="button"
                color={""}
                width={150}
                title={"Post Transaction"}
                isLoading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onOpenTransDetail}
                border={"#5C2684"}
              />
            </ButtonWrap>

            <FieldWrapD>
              <DetailsWrap>
                <LeftD>
                  <Text>Total Amount:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{amount}.00</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Amount in words:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{formatAmount(currency, amount)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Transaction Date:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{currentDate.toJSON().slice(0, 10)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Branch Code:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{branchcode}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Paid By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{bankUser}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Cash Received By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{bankUser}</Text>{" "}
                </RightD>
              </DetailsWrap>
            </FieldWrapD>
          </FieldWrap>
        </Container>
      </ComponentWrapper>

      <Modal
        open={transactionStatus}
        closeIcon
        onClose={closeReceipt}
        size="tiny"
        style={{
          width: "483px",
          heigth: "517.5px",
        }}
        title=""
        className=""
        contentPadding="2.5rem"
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {result?.statusCode === 200 || result?.statusCode === 201 ? (
              <SuccessIcon />
            ) : (
              <FailedIcon />
            )}
          </div>

          <p
            style={{
              fontWeight: "700",
              fontSize: "13px",
              margin: "10px 30px",
            }}
          >
            {result?.data ? result?.data?.Message : result?.message}
          </p>
          {/* {(result?.statusCode === 200 || result?.statusCode === 201) && (
            <PrimaryButton
              background={""}
              color={""}
              width={150}
              title="Generate Receipt"
              loading={false}
              type="button"
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={() => {
                setGeneratePDF(true)
                setTransactionReceipt((prevState) => !prevState);
                setTransactionStatus((prevState) => !prevState);
              }}
              border={"#5C2684"}
            />
          )} */}
        </div>
      </Modal>

      <Modal
        open={isOpenTransModal}
        closeIcon
        onClose={() => {
          setIsOpenTransModal(false);
        }}
        size="small"
        title=""
        className=""
        contentPadding="3.5rem"
      >
        <KeyValueMapping
          onSubmit={onSubmit}
          onClose={() => {
            setIsOpenTransModal(false);
          }}
          title={"Interbank Funds Transfer"}
          postWithdrawalLoading={isLoading}
          data={transReceipt}
        />
      </Modal>

      {/* <Modal
        open={transactionReceipt}
        closeIcon
        onClose={closeReceipt}
        size="tiny"
        style={{
          width: "944px",
          heigth: "auto",
        }}
        title=""
        className=""
        contentPadding="2.5rem"
      >
         {
          generatePDF && (
        // <PDFGenerator
        //   componentToRender={ <ReceiptView data={transReceipt}
        //   onTriggerDownload = {() => setGeneratePDF(true)}
        //   title="Cash Transfer/Interbank"  />}
        // />
          )
         }
      </Modal> */}
      {openMandate && imageData !== "" && (
        <>
          <GeneralModal general>
            <Top>
              <TopTitle>Mandate </TopTitle>
              <IconWrap>
                <CloseIcon onClick={handleCloseMandate} />
              </IconWrap>
            </Top>

            <>
              {" "}
              <img src={`data:image/jpeg;base64,${imageData}`} alt="ch" />
            </>
          </GeneralModal>
        </>
      )}
    </>
  );
};
export default FcmbToOther;
