import React, { useEffect, useState } from "react";
import {
  BellContainer,
  Container,
  ImageC,
  ImageContainer,
  LeftContainer,
  LeftHeader,
  RightContainer,
  UserContainer,
  Username,
  CurrentContainer,
  CurrentText,
} from "./style";
import { ReactComponent as NotificationIcon } from "../../assets/svg/notification.svg";
import { ReactComponent as CalenderIcon } from "../../assets/svg/calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/svg/clock.svg";
import { format, getDate, getYear } from "date-fns";

const Header = ({ title = "Dashboard" }) => {
  const localInfo = JSON.parse(localStorage.getItem("staffInfo") ?? "");
  let username = localInfo?.user?.displayName;
  //const currentUser: = "";
  const displayName = username;
  const currentDate = new Date();
  const formattedTime = format(currentDate, "h:mm a") ?? "";

  const currentWeekday: string = format(currentDate, "EEEE");
  const currentDateNumber: number = getDate(currentDate);
  const currentMonth: string = format(currentDate, "MMMM");
  const currentYear: number = getYear(currentDate);

  const currentDateFormat = `${currentWeekday} ${currentDateNumber} ${currentMonth} ${currentYear} `;

  const date = new Date();
  const [dateState, setDateState] = useState(date);
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 10000);
  }, []);

  const getTag = () => {
    let hours = date.getHours();
    let tag;

    if (hours < 12) {
      tag = "AM";
    } else if (hours >= 12 && hours <= 24) {
      tag = "PM";
    }
    return tag;
  };

  return (
    <Container>
      <LeftContainer>
        <LeftHeader>{title}</LeftHeader>
      </LeftContainer>
      <RightContainer>
        <CurrentContainer>
          <CalenderIcon />
          <CurrentText>{currentDateFormat}</CurrentText>
        </CurrentContainer>
        <CurrentContainer>
          <ClockIcon />
          <CurrentText>
            {" "}
            {dateState.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            })}{" "}
            {getTag()}
          </CurrentText>
        </CurrentContainer>
        <BellContainer>
          <NotificationIcon />
        </BellContainer>

        <UserContainer>
          <ImageContainer>
            {localInfo?.user?.displayName.slice(0, 1)}
          </ImageContainer>
          <Username>{displayName}</Username>
        </UserContainer>
      </RightContainer>
    </Container>
  );
};

export default Header;
