import styled from "styled-components";
interface statusProp {
  color?: string;
  backgroundColor?: string;
}
export const Container = styled.div`
  background-color: white;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 40px;
  justify-content: flex-end;
`;
export const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  padding-left: 15px;
`;
export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
`;
export const ContentLayout = styled.div`
  position: relative;
  padding: 40px 35px;
`;
export const DateWrap = styled.div`
  width: 100%;
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
export const TopContainer = styled.div`
  display: flex;
  flex-flow: column;
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding-inline: 24px;
`;
export const TableContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding-inline: 24px;
`;
export const NameContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;
export const Name = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #13071c;
  background: #fafafa;
  border-radius: 8px;
  padding: 14px 21px;
`;
export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #13071c;
`;
export const Status = styled.div<statusProp>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;
