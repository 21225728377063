import React from "react";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import {
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  ContainerDepo,
  LeftContainerDepo,
  RightContainerDepo,
  ButtonWrap,
  Input,
  Radio,
  Instruction,
  InputDiv,
  CardContainer,
  LeftD,
  RightD,
  Text,
  FieldWrapD,
  DetailsWrap,
  HeaderComponent,
  CheckedLabel,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import TransactionCard from "../../components/Cards/TransactionCard";
import { tableDatas, tableHeader } from "../../utils/config";
import PrimaryTable from "../../components/Table";
import { cardComponentType, widthDrawalProps } from "../../types/Types";
import { CustomDatePicker } from "../CustomDatePicker";
import { FormProvider, useForm } from "react-hook-form";
import { Icon } from "semantic-ui-react";
import { formatCurrency, formatDate } from "../../utils";
import { TillBalance } from "../../assets/svgComponent/TillBalanceIcon";
import { InterTransfer } from "../../assets/svgComponent/InterTransfer";
import { WithDrawalIcon } from "../../assets/svgComponent/WithDrawalIcon";
import { IntraTransFerIcon } from "../../assets/svgComponent/IntraTransferIcon";

const WidthDrawalComponent = ({
  accountNumberName,
  accountNumberType,
  accountNumberPlaceholder,
  accountNubmerLabel,
  accountName,
  accountNamePlaceholder,
  accountNameLabel,
  customerTypeName,
  customerTypePlaceholder,
  customerTypeLabel,
  accountTypeName,
  instrumentDateLabel,
  amountName,
  amountNameLabel,
  phoneNumberLabel,
  phoneNumberPlaceholder,
  customerAccountDescriptionName,
  customerAccountDescriptionLabel,
  customerAccountDescriptionPlaceholder,
  customerBvnName,
  customerBvnNamePlaceholder,
  customerBvnLabel,
  availableBalanceName,
  availableBalancePlaceholder,
  availableBalanceLabel,
  schemaCodeName,
  schemaCodePlaceholder,
  schemaCodeLabel,
  amountNamePlaceholder,
  draweePlaceholderLabel,
  draweePlaceholder,
  phoneNumberName,
  onChange,
  componentTitle,
  accountTypeLabel,
  accountTypePlaceholder,
  chequeNo,
  enterChequeNo,
  chequeNumberLabel,
  accountNumberNameValue,
  accountNameValue,
  customerTypeNameValue,
  customerAccountDescriptionNameValue,
  customerBvnNameValue,
  accountTypeNameValue,
  availableBalanceNameValue,
  schemaCodeNameValue,
  setInstrumentType,
  onOpenTransDetail,
  draweeName,
  amountValue,
  draweeNameValue,
  phoneNumberValue,
  isAccountLoading,
  setInstrumentDate,
  onSelectAccountOwner,
  accountOwner,
  phoneNumberError,
  depositorNameError,
  amountError,
  instrumentTypeValue,
}: widthDrawalProps) => {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    formState: { isDirty },
    watch,
  } = methods;

  const handleChange = () => {
    formatDate(watch("instrumentDate"));
  };

  // const { data: dashboardAnalysis } = useGetDashboardAnalysisQuery();
  const header = ["Denomination", "Quantity", "Amount"];
  const dataBody = tableDatas?.map((item: any) => [
    item.sn,
    item.firstname,
    item.lastname,
  ]);
  const actualPlaceholder: string = enterChequeNo || "";
  const chequeNumberName: string = chequeNo || "";
  const actualChequeNumberLabel: string = chequeNumberLabel || "";

  const instrumentType = [
    {
      label: "CHEQUE",
      value: "CHQ",
    },
    {
      label: "CMS DRAFT",
      value: "CMS",
    },
    {
      label: "DEMAND DRAFT",
      value: "DDS",
    },
    {
      label: "APPROVED ELECTRONIC MAIL",
      value: "EMAIL",
    },
    {
      label: "LOOSE CHEQUE",
      value: "LCHQ",
    },
    {
      label: "OTHERS",
      value: "OTHRS",
    },
    {
      label: "SAVINGS AC WITHDRAWAL SLIP",
      value: "WSLIP",
    },
  ];

  const depositData: cardComponentType[] = [
    {
      title: "Till Balance",
      amount: formatCurrency(0) ?? "0",
      img: <TillBalance />,
      count: 0,
    },
    {
      title: "Deposits",
      amount: formatCurrency(0) ?? "0",
      img: <InterTransfer />,
      count: 0,
    },
    {
      title: "Withdrawal",
      amount: formatCurrency(0) ?? "0",
      img: <WithDrawalIcon />,
      count: 0,
    },
    {
      title: "Inter Transfers",
      amount: formatCurrency(0) ?? "0",
      img: <InterTransfer />,
      count: 0,
    },
    {
      title: "Intra Transfer",
      amount: formatCurrency(0) ?? "0",
      img: <IntraTransFerIcon />,
      count: 0,
    },
  ];

  return (
    <>
      <Header title={componentTitle} />
      <ComponentWrapper>
        <CardContainer>
          {depositData.map((data) => (
            <TransactionCard data={data} width={"18%"} />
          ))}
        </CardContainer>

        <Container>
          <HeaderComponent>
            <HeaderText>Customer Information</HeaderText>
            <div style={{ marginTop: "24px", width: "538px" }}>
              <PrimaryInput
                name={accountNumberName}
                type={accountNumberType}
                placeholder={accountNumberPlaceholder}
                rightIcon={isAccountLoading && <Icon name="spinner" loading />}
                error={undefined}
                label={accountNubmerLabel}
                check={""}
                padding={""}
                leftIcon={<SearchIcon />}
                onChange={onChange}
                value={accountNumberNameValue}
                // register={register}
              />
            </div>
          </HeaderComponent>

          <FieldWrap>
            <PrimaryInput
              name={accountName}
              type={"text"}
              placeholder={accountNamePlaceholder}
              rightIcon={<></>}
              error={undefined}
              label={accountNameLabel}
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={onChange}
              value={accountNameValue}
              // register={register}
              readOnly
            />
            <PrimaryInput
              name={customerTypeName}
              type={"text"}
              placeholder={customerTypePlaceholder}
              rightIcon={<></>}
              // error={errors.oneTimeLimit?.message}
              disabled={undefined}
              label={customerTypeLabel}
              check={""}
              // register={register}
              padding={""}
              leftIcon={<InputClose />}
              value={customerTypeNameValue}
              onChange={onChange}
              readOnly
            />
          </FieldWrap>
          <FieldWrap>
            <PrimaryInput
              name={customerAccountDescriptionName}
              type={"text"}
              placeholder={customerAccountDescriptionPlaceholder}
              rightIcon={<></>}
              // error={errors.dailyLimit?.message}
              disabled={undefined}
              label={customerAccountDescriptionLabel}
              check={""}
              padding={""}
              // register={register}
              leftIcon={<InputClose />}
              value={customerAccountDescriptionNameValue}
              onChange={onChange}
              readOnly
            />
            <PrimaryInput
              name={customerBvnName}
              type="text"
              placeholder={customerBvnNamePlaceholder}
              rightIcon={<></>}
              // error={errors.oneTimeLimit?.message}
              disabled={undefined}
              label={customerBvnLabel}
              check={""}
              // register={register}
              padding={""}
              leftIcon={<InputClose />}
              value={customerBvnNameValue}
              onChange={onChange}
              readOnly
            />
          </FieldWrap>

          <FieldWrap>
            <PrimaryInput
              name={availableBalanceName}
              type="text"
              placeholder={availableBalancePlaceholder}
              rightIcon={<></>}
              // error={errors.oneTimeLimit?.message}
              disabled={undefined}
              label={availableBalanceLabel}
              check={""}
              // register={register}
              padding={""}
              leftIcon={<InputClose />}
              value={availableBalanceNameValue}
              onChange={onChange}
              readOnly
            />
            <PrimaryInput
              name={schemaCodeName}
              type="text"
              placeholder={schemaCodePlaceholder}
              rightIcon={<></>}
              // error={errors.oneTimeLimit?.message}
              disabled={undefined}
              label={schemaCodeLabel}
              check={""}
              // register={register}
              padding={""}
              leftIcon={<InputClose />}
              value={schemaCodeNameValue}
              onChange={onChange}
              readOnly
            />
          </FieldWrap>

          <FieldWrap>
            <PrimaryInput
              name={accountTypeName}
              type="text"
              placeholder={accountTypePlaceholder}
              rightIcon={<></>}
              // error={errors.oneTimeLimit?.message}
              disabled={undefined}
              label={accountTypeLabel}
              check={""}
              // register={register}
              padding={""}
              leftIcon={<InputClose />}
              value={accountTypeNameValue}
              onChange={onChange}
              readOnly
            />
            {/* <ButtonWrap>
              <PrimaryButton
                background={"#ffffff"}
                color={"#5c2684"}
                width={150}
                title={"View Mandate"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={(event) => console.log(event.target.value)}
                border={"1px solid #5c2684"}
              />
            </ButtonWrap> */}
          </FieldWrap>
        </Container>

        <ContainerDepo>
          <LeftContainerDepo>
            <HeaderText>Cash Withdrawal Information</HeaderText>
            <div>
              <CheckedLabel>
                <input type="checkbox" checked={false} />
                Bypass 100k limit
              </CheckedLabel>
            </div>
            <Instruction>
              Is the drawee the account owner
              <InputDiv>
                <Radio>
                  <Input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="yes"
                    checked={accountOwner}
                    onChange={onSelectAccountOwner}
                  />
                    <label>Yes</label>
                </Radio>
                <Radio>
                  <Input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="no"
                    checked={!accountOwner}
                    onChange={onSelectAccountOwner}
                  />
                    <label>No</label>
                </Radio>
              </InputDiv>
            </Instruction>

            {componentTitle !== "Bio Metrics" && (
              <>
                <FormProvider {...methods}>
                  <CustomDatePicker
                    placeholderText="DD/MM/YYYY"
                    name="instrumentDate"
                    onChange={setInstrumentDate(
                      formatDate(watch("instrumentDate"))
                    )}
                    label={instrumentDateLabel}
                    popperPlacement="top-start"
                    extraClasses="personal__details__dob"
                  />
                </FormProvider>

                <DropDownC
                  value={undefined}
                  placeholder={"Instrument Type"}
                  options={instrumentType}
                  errorMessage={""}
                  label={"Instrument Type"}
                  //  name="instrumentType"
                  setSelectedItem={(value: any) => setInstrumentType(value)}
                />

                {instrumentTypeValue === "CHQ" && (
                  <PrimaryInput
                    name={chequeNumberName}
                    type="text"
                    placeholder={actualPlaceholder}
                    rightIcon={<></>}
                    error={undefined}
                    label={actualChequeNumberLabel}
                    check={""}
                    padding={""}
                    leftIcon={<InputClose />}
                    onChange={onChange}
                  />
                )}
              </>
            )}

            <PrimaryInput
              name={amountName}
              type="text"
              placeholder={amountNamePlaceholder}
              rightIcon={<></>}
              error={amountError}
              label={amountNameLabel}
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={onChange}
              value={amountValue}
            />
            <PrimaryInput
              name={draweeName || ""}
              type="text"
              placeholder={draweePlaceholder}
              rightIcon={<></>}
              error={depositorNameError}
              label={draweePlaceholderLabel}
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={onChange}
              value={draweeNameValue}
            />
            <PrimaryInput
              name={phoneNumberName}
              maxLength={11}
              type={"text"}
              placeholder={phoneNumberPlaceholder}
              rightIcon={<></>}
              error={phoneNumberError}
              label={phoneNumberLabel}
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={onChange}
              value={phoneNumberValue}
              // register={register}
            />
            <ButtonWrap>
              <PrimaryButton
                background={""}
                color={""}
                width={150}
                title={"Post Transaction"}
                loading={false}
                type="button"
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onOpenTransDetail}
                border={"#5C2684"}
              />
            </ButtonWrap>
          </LeftContainerDepo>
          <RightContainerDepo>
            <PrimaryTable
              header={tableHeader}
              body={dataBody}
              arrOfObject={true}
              itemsPerPage={0}
              TopComponent={false}
            />

            <FieldWrapD>
              <DetailsWrap>
                <LeftD>
                  <Text>Total Amount:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>2000</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Amount in words:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>Two Thousand Naira Only</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Transaction Date:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>2023-01-05</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Branch Code:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>116</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Paid By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>Bamidele Akinyemi</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Cash Receieved By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>Bamidele Akinyemi</Text>{" "}
                </RightD>
              </DetailsWrap>
            </FieldWrapD>
          </RightContainerDepo>
        </ContainerDepo>
      </ComponentWrapper>
    </>
  );
};

export default WidthDrawalComponent;
