import React from "react";
import PrimaryButton from "../../components/Buttons/Button";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  ButtonWrap,
  Item,
  TransactionContainer,
  Top,
  TopTitle,
  IconWrap,
  WidhtDrawalHeading,
} from "./style";
import { useState } from "react";

export const KeyValueMapping = ({
  data,
  onSubmit,
  handleClose,
  postWithdrawalLoading,
}: any) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = () => {
    setIsChecked((prevState) => !prevState);
  };

  return (
    <section>
      <Top>
        <TopTitle>Cash Deposit Transaction</TopTitle>
        <IconWrap>
          <CloseIcon onClick={handleClose} />
        </IconWrap>
      </Top>

      <TransactionContainer>
        {Object.entries(data).map(([key, value]: any) => (
          <Item key={key}>
            <span>{key}</span>
            <span>{value}</span>
          </Item>
        ))}
      </TransactionContainer>

      <div
        style={{
          display: "flex",
          gap: "30px",
          margin: "15px 0",
        }}
      >
        <input
          type="radio"
          checked={isChecked}
          style={{ color: "#5C2684" }}
          onChange={handleInputChange}
        />
        <p>
          I have read through all details displayed above and have ensured the
          information is accurate.
        </p>
      </div>
      <ButtonWrap>
        <>
          <PrimaryButton
            isLoading={postWithdrawalLoading}
            background={""}
            color={""}
            width={"250px"}
            isDisabled={!isChecked}
            title="Post Transaction"
            loading={postWithdrawalLoading}
            type="button"
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={onSubmit}
            border={"#5C2684"}
          />
        </>
      </ButtonWrap>
    </section>
  );
};
