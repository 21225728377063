import React from "react";
import {
  Container,
  Greet,
  LeftContainer,
  MediumText,
  Paragraph,
  RightContainer,
} from "./style";
import { ReactComponent as Over1 } from "../../assets/svg/over1.svg";
import { ReactComponent as Over2 } from "../../assets/svg/over2.svg";

interface IOverViewProps {
  displayName: string;
  role: string;
  staffId: string;
}
const Overview = ({ displayName, role, staffId }: IOverViewProps) => {


  return (
    <Container>
      <LeftContainer>
        <Greet>{`Welcome, ${displayName}`}</Greet>
        <MediumText>{`${staffId}, ${role}`}</MediumText>
        <Paragraph>Have a productive today!</Paragraph>
      </LeftContainer>
      <RightContainer>
        <Over1 />
        <Over2 />
      </RightContainer>
    </Container>
  );
};

export default Overview;
