import React from "react";
import WidthDrawalComponent from "../../components/Withdrawal/WithdrawalComponent";

const BioWithdrawal = () => {
  return (
    <WidthDrawalComponent
      componentTitle="Bio Metrics"
      accountNumberName="accountNumber"
      accountNumberType="text"
      accountNumberPlaceholder="Account Number"
      accountNubmerLabel="Account Number"
      accountName="accountName"
      accountNamePlaceholder="Anya Nzubechi Godswill"
      accountNameLabel="Account Name"
      customerTypeName="customerType"
      customerTypePlaceholder="Individual or Joint"
      customerTypeLabel="Customer Type"
      accountTypeName="accountType"
      accountTypeLabel="Account Type"
      accountTypePlaceholder="Classic Current (Salary)"
      customerAccountDescriptionName="customerAccountDescription"
      customerAccountDescriptionLabel="Customer Account Description"
      customerAccountDescriptionPlaceholder="Classic Current (Salary) /NGN/Active"
      customerBvnName="customerBvn"
      customerBvnNamePlaceholder="2233448958950"
      customerBvnLabel="Customer BVN"
      availableBalanceName="availableBalance"
      availableBalancePlaceholder="9,345.00"
      availableBalanceLabel="Available Balance"
      schemaCodeName="schemaCode"
      schemaCodePlaceholder="OF240"
      schemaCodeLabel="Schema Code"
      amountName="amount"
      amountNamePlaceholder="Enter Amount"
      amountNameLabel="Amount *"
      draweePlaceholderLabel="Drawee’s First/Last Name  *"
      draweePlaceholder="Enter first/last name"
      phoneNumberName="phoneNumber"
      phoneNumberPlaceholder="+234716543210"
      phoneNumberLabel="Phone number *"
      onChange={(event) => console.log(event.target.value)}
      instrumentDateLabel="Instrument Date"
    />
  );
};

export default BioWithdrawal;
