import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import DropDownC from "../../../components/PrimaryInput/dropDown";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import {
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  ButtonWrap1,
  TableDiv,
  ReceiptDiv,
  Status,
  FieldWrapD,
  TopTitle,
  MTop,
  IconWrap,
  LeftD,
  RightD,
  DetailsWrap,
  Text,
} from "./style";
import PrimaryButton from "../../../components/Buttons/Button";
import {
  ReportTableHeader,
  CollectionReportTableHeader,
  collectionName,
  transactionTypes,
  buaCollectionReportTableHeader,
  ErrorHandler,
} from "../../../utils/config";
import PrimaryTable from "../../../components/Table";
import DateComponent from "../../../components/Date/Date";
import { useGetReceiptMutation } from "../../../services/transaction/transaction";
import {
  useGetReportsMutation,
  useGetTransferReportsMutation,
  useGetWithdrawalReportsMutation,
} from "../../../services/reports/reportService";
import { toast } from "react-hot-toast";
import { useCollectionReportMutation } from "../../../services/collection/collection";
import GeneralModal from "../../../components/Modal/GeneralModal";
import ReceiptView from "../../../components/Receipt/Receipt";
import PDFGenerator from "../../../components/PDFGenerator/PdfGenerator";
import { GetReceipt } from "../../../services/transaction/types";
import { HiPrinter } from "react-icons/hi";

const DepositReports = () => {
  const [openTable, setOpenTable] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [reportType, setReportType] = useState("");
  const [selectedCollectionName, setCollectionName] = useState("");
  const [transReceipt, setTransReceiptData] = useState<
    GetReceipt | undefined
  >();

  const [getReceipt] = useGetReceiptMutation();
  const [postReports, { isLoading }] = useGetReportsMutation();
  const [transactionReceipt, setTransactionReceipt] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [generatePDF, setGeneratePDF] = useState(false);
  const [getWithdrawalReports, withdrawalMutationState] =
    useGetWithdrawalReportsMutation();
  const withdrawalLoading = withdrawalMutationState.isLoading;
  const [getTransferReports, transferMutation] =
    useGetTransferReportsMutation();
  const [collectionReport, collectionMutation] = useCollectionReportMutation();
  const collectionLoading = collectionMutation.isLoading;
  const transferLoading = transferMutation.isLoading;
  const [arrayData, setArrayData] = useState<any>([]);

  const onSubmit = async () => {
    let payload = {
      transactionType: reportType,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    let collectionPayload = {
      TransactionType: reportType,
      StartDate: selectedStartDate,
      EndDate: selectedEndDate,
      collectionName: selectedCollectionName,
    };

    let responseData = null;

    try {
      switch (payload.transactionType) {
        case "Cash":
          const apiResponse = await postReports(payload).unwrap();
          responseData = apiResponse?.data;
          let responseMessage = apiResponse?.message;
          toast.success(responseMessage);
          break;

        case "Collection":
          const apiCollectionResponse = await collectionReport(
            collectionPayload
          ).unwrap();

          responseData = apiCollectionResponse?.data;
          let collectionResponseMessage = apiCollectionResponse?.message;
          toast.success(collectionResponseMessage);
          break;

        case "Cash Withdrawal":
          const apiWithdrawalResponse = await getWithdrawalReports(
            payload
          ).unwrap();
          responseData = apiWithdrawalResponse?.data;
          let withdrawalResponseMessage = apiWithdrawalResponse?.message;
          toast.success(withdrawalResponseMessage);
          break;
        case "InterBank":
        case "IntraBank":
          const apiTransferResponse = await getTransferReports(
            payload
          ).unwrap();

          responseData = apiTransferResponse?.data;
          let transferResponseMessage = apiTransferResponse?.message;
          toast.success(transferResponseMessage);
          break;
        default:
          break;
      }

      if (responseData) {
        setArrayData(responseData);
        setOpenTable(true);
      }
    } catch (err: any) {
      ErrorHandler(err);
      setArrayData([]);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setTransactionReceipt(false);
  };
  const handleView = (slipfreeId: string) => {
    arrayData?.filter((data: any) => {
      if (data?.slipfreeId === slipfreeId) {
        setModalData(data);
        setOpenModal(true);
      }
    });
  };

  const generateReceipt = async (transId: string) => {
    const data = {
      transactionType:
        reportType === "Cash Withdrawal" ? "Withdrawal" : reportType,
      transactionId: transId,
    };

    try {
      const response = await getReceipt(data).unwrap();

      if (response.statusCode === 200) {
        setTransReceiptData(response);
        toast.success(response?.message);
        // setTransactionStatus(false);
        setTransactionReceipt(true);
        setGeneratePDF(true);
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else {
        ErrorHandler(error);
      }
    }
  };

  const dataBody = arrayData?.map((item: any, index: any) => [
    index + 1,
    <ReceiptDiv>{item?.recepitNo}</ReceiptDiv>,
    item?.sourceAccount,
    item?.destinationAccount,
    <>
      {new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: item?.currency !== "" ? item?.currency : "NGN",
      }).format(item?.amount)}
    </>,
    item?.currency.toUpperCase(),
    item?.datecreated
      ? item?.datecreated?.slice(0, 10)
      : item?.dateCreated?.slice(0, 10),
    <Status
      key={item?.id}
      color={item?.completed === true ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.completed === true
          ? "rgba(7, 8, 8, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.completed === true ? "TRUE" : "FALSE"}
    </Status>,
    <div>
      <HiPrinter
        style={{ cursor: "pointer" }}
        onClick={() => generateReceipt(item?.transId)}
        color="#5c2684"
        size={24}
      />
    </div>,
  ]);

  const collectionDataBody = arrayData?.map((item: any, index: any) => [
    index + 1,
    <ReceiptDiv>{item?.TransactionId}</ReceiptDiv>,
    item?.DebitAccountNumber,
    item?.CustomerNumber,
    <>
      {new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: item?.currency !== "" ? item?.currency : "NGN",
      }).format(item?.amount)}
    </>,
    item?.currency.toUpperCase(),
    item?.datecreated?.slice(0, 10),
    <Status
      key={item?.TransactionId}
      color={item?.ApprovalStatus === "APPROVED" ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.ApprovalStatus === "APPROVED"
          ? "rgba(7, 8, 8, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.ApprovalStatus}
    </Status>,
  ]);

  const buaCollectionDataBody = arrayData?.map((item: any, index: any) => [
    index + 1,
    <ReceiptDiv>{item?.orderId}</ReceiptDiv>,
    item?.creditAccount,
    item?.tellerNumber,
    <>
      {new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: item?.currency !== "" ? item?.currency : "NGN",
      }).format(item?.amount)}
    </>,
    item?.dateCreated?.slice(0, 10),
    item?.dateApproved !== null ? item?.dateApproved?.slice(0, 10) : "NIL",
    <Status
      key={item?.transactionId}
      color={item?.status === "NEW" ? "#01B272" : "#C43C20"}
      backgroundColor={
        item?.status === "NEW"
          ? "rgba(7, 8, 8, 0.2)"
          : "rgba(231, 175, 164, 0.3)"
      }
    >
      {item?.status === "" ? "NOT APPROVED" : item?.status}
    </Status>,
    <PrimaryButton
      background={"#ffffff"}
      color={"#5c2684"}
      width={150}
      title={"View"}
      icon={""}
      loading={false}
      leftIconName={<></>}
      rightIconName={<></>}
      onClick={() => handleView(item?.slipfreeId)}
      leftIcon={false}
      border={"1px solid #5c2684"}
    />,
  ]);

  const onClearFields = () => {
    setTransactionType("");
    setSelectedEndDate("");
    setReportType("");
    setSelectedStartDate("");
  };

  return (
    <>
      <Header title="Reports" />
      <ComponentWrapper>
        <Container>
          <HeaderText>Transaction History</HeaderText>
          <FieldWrap>
            <DropDownC
              value={transactionType}
              placeholder={"Select Type"}
              options={transactionTypes}
              errorMessage={""}
              label={"Transaction Type"}
              setSelectedItem={() => {}}
              setOptionValue={setReportType}
              setSelectedItems={setTransactionType}
            />
            {reportType === "Collection" && (
              <>
                <DropDownC
                  value={selectedCollectionName}
                  placeholder={"Collection Name"}
                  options={collectionName}
                  errorMessage={""}
                  label={"Collection Name"}
                  setSelectedItem={() => {}}
                  setOptionValue={setCollectionName}
                  setSelectedItems={setCollectionName}
                />
              </>
            )}
          </FieldWrap>
          <FieldWrap>
            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectedStartDate}
              handleDateChange={(e: any) => {
                setSelectedStartDate(e.target.value);
              }}
              selectDate={setSelectedStartDate}
              label={"Start Date"}
            />

            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectedEndDate}
              handleDateChange={() => {}}
              selectDate={setSelectedEndDate}
              label={"End Date"}
            />
          </FieldWrap>

          <ButtonWrap1>
            <PrimaryButton
              background={"#fff"}
              color={"#5c2684"}
              width={"150px"}
              title={"Clear Fields"}
              icon={""}
              isLoading={false}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={onClearFields}
              leftIcon={false}
              border={"1px solid #5c2684"}
            />
            <PrimaryButton
              background={"#5c2684"}
              color={"#fff"}
              width={"150px"}
              title={"Search"}
              icon={""}
              isLoading={
                isLoading ||
                transferLoading ||
                withdrawalLoading ||
                collectionLoading
              }
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={onSubmit}
              leftIcon={false}
              border={"1px solid #5c2684"}
            />
          </ButtonWrap1>
        </Container>

        {openTable && (
          <TableDiv>
            <HeaderText>Transactions</HeaderText>
            <PrimaryTable
              header={
                reportType === "Collection" &&
                selectedCollectionName === "DANGOTE"
                  ? CollectionReportTableHeader
                  : reportType === "Collection" &&
                    selectedCollectionName === "BUA"
                  ? buaCollectionReportTableHeader
                  : ReportTableHeader
              }
              body={
                reportType === "Collection" &&
                selectedCollectionName === "DANGOTE"
                  ? collectionDataBody
                  : reportType === "Collection" &&
                    selectedCollectionName === "BUA"
                  ? buaCollectionDataBody
                  : dataBody
              }
              arrOfObject={true}
              TopComponent={false}
              itemsPerPage={10}
            />
          </TableDiv>
        )}
      </ComponentWrapper>

      {openModal && (
        <>
          <GeneralModal general>
            <MTop>
              <TopTitle>Transaction Details</TopTitle>
              <IconWrap>
                <CloseIcon onClick={handleClose} />
              </IconWrap>
            </MTop>

            <FieldWrapD>
              <DetailsWrap>
                <LeftD>
                  <Text>Slipfree Id</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.slipfreeId}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Depositor's Name</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.depositorName}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Depositor's Phone Number</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.depositorPhoneNo}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Category</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.category}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Product Type</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.productType}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Customer Reference</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.customerReference}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Created By</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{modalData?.createdBy}</Text>{" "}
                </RightD>
              </DetailsWrap>
            </FieldWrapD>
          </GeneralModal>
        </>
      )}

      {transactionReceipt && (
        <GeneralModal general>
          <MTop>
            <TopTitle></TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleClose} />
            </IconWrap>
          </MTop>

          {generatePDF && (
            <PDFGenerator
              componentToRender={
                <ReceiptView
                  data={transReceipt?.data}
                  title="Withdrawal Receipt"
                />
              }
            />
          )}
        </GeneralModal>
      )}
    </>
  );
};

export default DepositReports;
