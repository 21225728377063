import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: #fbf9f9;
  border-radius: 16px;
  gap: 95px;
  width: 100%;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
  padding: 40px 30px;
  gap: 14px;
  flex-direction: column;
`;
export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 100;
  right: 0; */
`;
export const Greet = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: #2d2235;
`;

export const MediumText = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  color: #2d2235;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  width: 60%;
  line-height: 120%;
  color: #2d2235;
`;
