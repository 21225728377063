import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { ReactComponent as CalendarIcon } from "../../assets/svg/calendarblack.svg";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth, getYear } from "date-fns";
import { generateYearsBetween } from "../../utils";
import { Label } from "../PrimaryInput/style";

const DateWrapper = ({
  className,
  children,
}: {
  className: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="datePicker__customWrapper">
      <CalendarContainer className={className}>
        <div style={{ fontSize: "20px", marginTop: "12px" }}>Calendar</div>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    </div>
  );
};

export const CustomDatePicker = ({
  label,
  name,
  extraClasses,
  required,
  defaultValue,
  classes,
  dobError,
  ...props
}: any) => {
  const { control, formState } = useFormContext();
  const currentDate = new Date();
  const defaultDateAllowed = currentDate.setFullYear(currentDate.getFullYear());
  const years = generateYearsBetween(1900).reverse();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className={`custom__form ${extraClasses || "_margin"}`}>
      {label && <Label>{label}</Label>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div
            style={{
              position: "relative",
            }}
          >
            <DatePicker
              {...props}
              showPopperArrow
              maxDate={defaultDateAllowed}
              onChange={onChange}
              selected={value || defaultValue}
              dateFormat="dd/MM/yyyy"
              wrapperClassName="datePicker"
              className={`${classes} ${
                formState.errors[name] && "errorInput_border"
              }`}
              calendarContainer={DateWrapper}
              renderCustomHeader={({ date, changeYear, changeMonth }) => (
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "10px",
                    display: "flex",
                  }}
                >
                  <select
                    style={{ padding: "8px", marginRight: "10px" }}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <select
                    value={getYear(date)}
                    style={{ padding: "8px" }}
                    onChange={({ target: { value } }: any) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            />
            <CalendarIcon
              style={{ position: "absolute", right: "4%", top: "35%" }}
            />
          </div>
        )}
      />

      {/* {formState.errors[name] && (
        <p
          style={{ fontSize: "0.75rem" }}
          className={`dob__error__message ${dobError}`}
        >
          {formState?.errors[name].message}
        </p>
      )} */}
    </div>
  );
};
