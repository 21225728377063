import React, { useState, useEffect } from "react";
import "./App.css";
import AppRouter from "./routes/appRouter";
import { saveStaffInfo, saveUserDetails } from "./services/auth/authSlice";
import { useIdleTimer } from "react-idle-timer";
import {
  useGetUserDetailsMutation,
  useLogoutMutation,
} from "./services/dashboardAnalysis/dashboardService";
import { store } from "./store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "./utils";
import GeneralModal from "./components/Modal/GeneralModal";
import InactiveContent from "./components/InactiveContent";

function App() {
  const staffInfoJSON = localStorage.getItem("staffInfo");
  const staffInfo = staffInfoJSON ? JSON.parse(staffInfoJSON) : {};
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const [logout] = useLogoutMutation();
  const [getUserDetails] = useGetUserDetailsMutation();
  const currentTime = Date.now();
  const savedTokenTime = useSelector(
    (state: any) => state?.StaffDataReducer?.tokenExpiresIn
  );
  const username = staffInfo?.user?.staffName;

  //if staff info, save it to store
  if (staffInfo) {
    store.dispatch(saveStaffInfo(staffInfo));
  }

  // get user details which signed in
  const fetchDetails = async () => {
    const response = await getUserDetails({ username: username }).unwrap();
    if (response?.statusCode === 200) {
      store.dispatch(saveUserDetails(response));
    }
  };
  useEffect(() => {
    if (staffInfo?.user) {
      fetchDetails();
    }
  }, []);

  useEffect(() => {
    if (staffInfo?.user) {
      const currentHour = new Date().getHours();
      // if (currentHour === 17) {
      //   handleLogout(logout, navigate);
      // }
      if (currentTime > savedTokenTime) {
        //handle logout
        handleLogout(logout, navigate);
      }
    }
  }, [currentTime, logout, navigate, savedTokenTime, staffInfo]);

  //function to cancel modal
  const handleCancel = () => {
    setShowModal(false);
  };

  const onIdle = () => {
    if (staffInfo?.user) {
      setShowModal(true);
    }
  };
  const sessionTime = process.env.REACT_APP_SESSION_TIME;

  useIdleTimer({
    onIdle,
    timeout: parseFloat(sessionTime as string) * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    if (showModal) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setCounter(60);
    }

    if (counter === 0) {
      setShowModal(false);
      handleLogout(logout, navigate);
    }
  }, [showModal, counter, navigate]);

  return (
    <>
      {showModal && (
        <GeneralModal general>
          <InactiveContent handleCancel={handleCancel} counter={counter} />
        </GeneralModal>
      )}
      <AppRouter />
    </>
  );
}

export default App;
