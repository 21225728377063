import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import {
  ComponentWrapper,
  LoaderContainer,
  DataDiv,
  TopTitle,
  TableBtn,
  FieldWrap,
  Container,
} from "./style";
import PrimaryButton from "../../../components/Buttons/Button";
import PrimaryTable from "../../../components/Table";
import { useDepositApprovalMutation } from "../../../services/approval/approval";
import {
  CollectionResponse,
  CollectionDatum,
} from "../../../services/collection/type";
import PuffLoader from "../../../components/Loader";
import TransactionModal from "../Modal/TransactionModal";
import DropDownC from "../../../components/PrimaryInput/dropDown";
import {
  usePendingPaymentMutation,
  useBuaPendingPaymentMutation,
} from "../../../services/collection/collection";
import NotFound from "../../../components/Not Found";
import { collectionTypeList } from "../../../utils/config";
import DateComponent from "../../../components/Date/Date";

const CollectionApproval = () => {
  const [dataContainer, setDataContainer] = useState<
    CollectionResponse | undefined
  >();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [openView, setOpenView] = useState<boolean>(false);
  const [details, setDetails] = useState<CollectionDatum>();
  const [collectionType, setCollectionType] = useState("Dangote");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [collectionList, mutationState] = usePendingPaymentMutation();
  const [buaCollectionList, buaMutationState] = useBuaPendingPaymentMutation();

  const handleFetch = async () => {
    const response = await collectionList().unwrap();
    setDataContainer(response);
  };

  const handleBUAFetch = async () => {
    const response = await buaCollectionList({
      startDate: startDate,
      endDate: endDate,
    }).unwrap();
    setDataContainer(response);
  };

  useEffect(() => {
    if (collectionType === "Dangote") {
      handleFetch();
    } else {
      handleBUAFetch();
    }
  }, [collectionType]);

  const handleClose = () => {
    setOpenView(false);
  };

  const option = [
    {
      id: 1,
      value: 5,
    },
    {
      id: 2,
      value: 10,
    },
    {
      id: 3,
      value: 20,
    },
    {
      id: 4,
      value: 50,
    },
    {
      id: 5,
      value: 100,
    },
  ];

  const handleSelect = (e: any) => {
    const selectedOption = e.target.value;
    setItemsPerPage(selectedOption);
  };

  const handleView = async (id: string) => {
    dataContainer?.data?.filter((item: CollectionDatum) => {
      if (item.transactionId === id) {
        setDetails(item);
        setOpenView(true);
      }
      return null;
    });
  };

  const tableHeader: any = [
    {
      id: 1,
      title: "S/N",
    },
    {
      id: 2,
      title: "Transaction ID",
    },
    {
      id: 3,
      title: "Account Number",
    },
    {
      id: 4,
      title: "Depositor's Name",
    },
    {
      id: 5,
      title: "Amount",
    },
    {
      id: 6,
      title: "Created By",
    },
    {
      id: 7,
      title: "Date",
    },

    {
      id: 8,
      title: "Action",
    },
  ];

  const dataBody = dataContainer?.data?.map((item: any, index: any) => [
    index + 1,
    item?.transactionId,
    <DataDiv key={item.bankNumber}>{item.bankNumber}</DataDiv>,
    item.depositorName,
    <>
      {new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: item?.currency,
      }).format(item?.amount)}
    </>,
    item.createdBy,
    item.dateCreated.slice(0, 10),
    <TableBtn>
      <PrimaryButton
        background={"#ffffff"}
        color={"#5c2684"}
        width={150}
        title={"View"}
        icon={""}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleView(item.transactionId)}
        leftIcon={false}
        border={"1px solid #5c2684"}
      />
    </TableBtn>,
  ]);

  return (
    <>
      <Header title="Collection Approval" />
      <ComponentWrapper>
        <TopTitle>Pending Transactions</TopTitle>
        <FieldWrap>
          <DropDownC
            value={collectionType}
            placeholder="CollectionType"
            options={collectionTypeList}
            // errorMessage={error?.selectedCurrencyType}
            label={"Collection Type"}
            setSelectedItem={setCollectionType}
            setSelectedItems={() => {}}
            setOptionValue={() => {}}
          />
        </FieldWrap>

        {collectionType === "BUA" && (
          <>
            <FieldWrap>
              <DateComponent
                showModal={false}
                onClosed={() => {}}
                value={startDate}
                handleDateChange={() => {}}
                selectDate={setStartDate}
                label={"Start Date"}
              />

              <DateComponent
                showModal={false}
                onClosed={() => {}}
                value={endDate}
                handleDateChange={() => {}}
                selectDate={setEndDate}
                label={"End Date"}
              />
            </FieldWrap>
            <FieldWrap>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={"150px"}
                title={"Submit"}
                icon={""}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleBUAFetch}
                leftIcon={false}
                border={"1px solid #5c2684"}
              />
            </FieldWrap>
          </>
        )}

        {collectionType === "BUA" ? (
          buaMutationState.isLoading ? (
            // Render loading state for BUA mutation
            <LoaderContainer>
              <PuffLoader />
            </LoaderContainer>
          ) : (
            // Render content for BUA collection
            <>
              {dataContainer?.statusCode === 200 ? (
                <PrimaryTable
                  header={tableHeader}
                  body={dataBody}
                  arrOfObject={true}
                  itemsPerPage={itemsPerPage}
                />
              ) : (
                <Container>
                  <NotFound notFound message="No Record Found!." />
                </Container>
              )}
            </>
          )
        ) : // Render content for other collection types
        mutationState.isLoading ? (
          // Render loading state for other mutations
          <LoaderContainer>
            <PuffLoader />
          </LoaderContainer>
        ) : (
          // Render content for other collection types
          <>
            {dataContainer?.statusCode === 200 ? (
              <PrimaryTable
                header={tableHeader}
                body={dataBody}
                arrOfObject={true}
                itemsPerPage={itemsPerPage}
              />
            ) : (
              <Container>
                <NotFound notFound message="No Record Found!." />
              </Container>
            )}
          </>
        )}
      </ComponentWrapper>

      {openView && (
        <TransactionModal
          details={details}
          handleClose={handleClose}
          type="Collection"
          setOpenView={setOpenView}
          handleFetch={handleFetch}
        />
      )}
    </>
  );
};

export default CollectionApproval;
