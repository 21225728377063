import styled from "styled-components";

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  border-radius: 24px;
  background: white;
`;
export const DataDiv = styled.div``;
export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 16px;
`;

export const FieldWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin-top: 24px;
`;

export const ContainerDepo = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 16px;
`;

export const LeftContainerDepo = styled.form`
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
`;

export const RightContainerDepo = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 45%;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ButtonWrap1 = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Instruction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vw;
  font-size: 13px;
`;
export const Radio = styled.div`
  display: flex;
  gap: 0.2vw;
`;
export const Input = styled.input`
  accent-color: #5c2684;
`;
export const InputDiv = styled.div`
  display: flex;
  gap: 5vw;
`;

export const LeftD = styled.div`
  width: 45%;
`;
export const FieldWrapD = styled.div`
  margin-bottom: 24px;
  display: flex;
  gap: 14px;
  width: 100%;
  flex-direction: column;
`;
export const RightD = styled.div`
  width: 50%;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #2d2235;
`;
export const DetailsWrap = styled.div`
  width: 100%;
  display: flex;
`;
export const LoaderContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
`;
export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const TopLeftContainer = styled.div`
  display: flex;
`;
export const TopRightContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 10px;
`;
export const TopTitle = styled.p`
  font-weight: 700;
  font-size: 29px;
  line-height: 120%;
  color: #2d2235;
`;
export const TopSelect = styled.select`
  width: 50px;
  border: none;
`;

export const TableBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  flex-direction: row;
`;
