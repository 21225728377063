import React, { useState, ChangeEvent } from "react";
import PrimaryInput from "../../components/PrimaryInput";
import { FieldWrap } from "../CashDeposit/style";
import { ModalHeaderText, UserButtonWrap } from "./styles/styles";
import PrimaryButton from "../../components/Buttons/Button";
import toast from "react-hot-toast";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { approveOptions, ErrorHandler } from "../../utils/config";
import { useAddRoleMutation } from "../../services/admin/adminService";

interface FormData {
  roleName: string;
  roleDescription: string;
  debitLimt: number | string;
  debitLimtFCY: number | string;
  creditLimit: number | string;
  creditLimitFCY: number | string;
}

const AddRole: React.FC = () => {
  const [formData, setFormData] = useState<any>({
    roleName: "",
    roleDescription: "",
    debitLimt: "",
    debitLimtFCY: "",
    creditLimit: "",
    creditLimitFCY: "",
  });
  const [booleanValue, setValue] = useState(true);
  const [approve, setApprove] = useState("");
  const [selectedRoles, setSelectedRoles] = useState<{
    value: boolean | string;
    label: string;
  }>({
    value: true,
    label: "",
  });

  const [inputErrors, setInputErrors] = useState<FormData>({
    roleName: "",
    roleDescription: "",
    debitLimt: "",
    debitLimtFCY: "",
    creditLimit: "",
    creditLimitFCY: "",
  });

  const [addRole] = useAddRoleMutation();

  const validateForm = (): boolean => {
    let isValid = true;
    const newErrors: any = { ...inputErrors };

    for (const field in formData) {
      if (formData[field] === "" || formData[field] === 0) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
        isValid = false;
      } else {
        newErrors[field] = "";
      }
    }

    setInputErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (
    field: keyof FormData,
    value: string | number | boolean
  ) => {
    setFormData({ ...formData, [field]: value });
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      ...formData,
      canApprove: booleanValue,
      tillAccount: !booleanValue,
    };

    try {
      const response = await addRole(payload).unwrap();
      if (response !== undefined) {
        setSelectedRoles({
          value: "",
          label: "",
        });

        response?.data === undefined
          ? toast.success(response?.message)
          : toast.success(response?.data?.Message);
        setFormData({
          roleName: "",
          roleDescription: "",
          debitLimt: "",
          debitLimtFCY: "",
          creditLimit: "",
          creditLimitFCY: "",
        });
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  return (
    <section>
      <ModalHeaderText>Add Role</ModalHeaderText>
      <FieldWrap>
        <PrimaryInput
          name="roleName"
          type="text"
          placeholder="Role Name"
          rightIcon={<></>}
          error={inputErrors.roleName}
          label="Role Name"
          value={formData.roleName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange("roleName", e.target.value)
          }
        />
        <PrimaryInput
          name="roleDescription"
          type="text"
          placeholder="Role Description"
          rightIcon={<></>}
          error={inputErrors.roleDescription}
          label="Role Description"
          value={formData.roleDescription}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange("roleDescription", e.target.value)
          }
        />
      </FieldWrap>
      <FieldWrap>
        <PrimaryInput
          name="debitLimt"
          type="number"
          inputMode="numeric"
          placeholder="Debit Limit"
          rightIcon={<></>}
          error={inputErrors.debitLimt}
          label="Debit Limit"
          value={formData.debitLimt}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange("debitLimt", Number(e.target.value))
          }
        />
        <PrimaryInput
          name="debitLimtFCY"
          type="number"
          inputMode="numeric"
          placeholder="DebitLimit FCY"
          rightIcon={<></>}
          error={inputErrors.debitLimtFCY}
          label="DebitLimit FCY"
          value={formData.debitLimtFCY}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange("debitLimtFCY", Number(e.target.value))
          }
        />
      </FieldWrap>
      <FieldWrap>
        <PrimaryInput
          name="creditLimit"
          type="number"
          inputMode="numeric"
          placeholder="Credit Limit"
          rightIcon={<></>}
          error={inputErrors.creditLimit}
          label="Credit Limit"
          value={formData.creditLimit}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange("creditLimit", Number(e.target.value))
          }
        />
        <PrimaryInput
          name="creditLimitFCY"
          type="number"
          inputMode="numeric"
          placeholder="Credit Limit FCY"
          rightIcon={<></>}
          error={inputErrors.creditLimitFCY}
          label="Credit Limit FCY"
          value={formData.creditLimitFCY}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange("creditLimitFCY", Number(e.target.value))
          }
        />
        <DropDownC
          value={approve}
          placeholder={"Select"}
          options={approveOptions}
          errorMessage={""}
          label={"Can Approve"}
          setSelectedItem={() => {}}
          setSelectedItems={setApprove}
          setOptionValue={setValue}
        />
        <PrimaryInput
          value={!booleanValue}
          placeholder={"Select"}
          label={"Till Account"}
          name={""}
          type={"input"}
          rightIcon={<></>}
          check={""}
          disabled={true}
          padding={""}
          leftIcon={<></>}
        />
      </FieldWrap>
      <UserButtonWrap>
        <PrimaryButton
          background={""}
          color={""}
          width=""
          title="Add Role"
          loading={false}
          type="submit"
          leftIconName={<></>}
          rightIconName={<></>}
          onClick={onSubmit}
          border={"#5C2684"}
        />
      </UserButtonWrap>
    </section>
  );
};

export default AddRole;
