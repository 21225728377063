import styled from "styled-components";
interface StatusProp {
	color: string;
	backgroundColor: string;
}
export const ComponentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
`;
export const StatusHolder = styled.div`
	width: 150px;
`;

export const Status = styled.div<StatusProp>`
	background-color: ${(props) => props.backgroundColor} !important;
	color: ${(props) => props.color};
	text-align: center;
	border-radius: 12px !important;
	font-size: 14px !important;
	font-weight: 600;
	padding: 8px;
`;
export const CardContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
`;

export const DenominatorDiv = styled.div`
	position: static;
	width: 2rem;
`;
export const Container = styled.div`
	border-radius: 20px;
	background: #fff;
	padding: 10px 30px 20px 30px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin-top: 16px;
`;
export const LeftDiv = styled.div`
	width: 45%;
	padding: 12px 14px;
	border-right: 1px solid #dfe2e6;
`;
export const FieldWrap = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
	margin-top: 16px;
`;

export const HeaderText = styled.h3`
	font-family: "Poppins", sans-serif;
	font-size: 25px;
	font-weight: bold;
	margin-top: 24px;
`;

export const ContainerDepo = styled.div`
	border-radius: 20px;
	background: #fff;
	padding: 10px 30px 20px 30px;
	display: flex;
	justify-content: space-between;
	gap: 30px;
	margin-top: 16px;
`;

export const LeftContainerDepo = styled.form`
	border-radius: 20px;
	background: #fff;
	padding: 10px 30px 20px 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 45%;
`;

export const RightContainerDepo = styled.div`
	border-radius: 20px;
	background: #fff;
	padding: 10px 30px 20px 30px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	width: 45%;
`;

export const ButtonWrap = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
`;

export const Instruction = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2vw;
	font-size: 13px;
`;
export const Radio = styled.div`
	display: flex;
	gap: 0.2vw;
`;
export const Input = styled.input`
	accent-color: #5c2684;
`;
export const InputDiv = styled.div`
	display: flex;
	gap: 5vw;
`;
export const FieldWrapDen = styled.div`
	margin-bottom: 24px;
	display: flex;
	width: 100%;
	flex-direction: column;
	background-color: #fafbfb;
`;
export const LeftD = styled.div`
	width: 45%;
`;
export const FieldWrapD = styled.div`
	margin-bottom: 24px;
	display: flex;
	gap: 14px;
	width: 100%;
	flex-direction: column;
`;
export const RightD = styled.div`
	width: 50%;
	text-align: right;
`;
export const RightDiv = styled.div`
	width: 50%;
	padding: 12px 14px;
`;

export const Text = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 120%;
	flex: 0 0 auto; /* Prevent the text from growing */
	//margin-right: 10px;
	//width: 50px;
	flex-wrap: wrap;
	color: #2d2235;
`;
export const DetailsWrap = styled.div`
	width: 100%;
	display: flex;
	border: 1px solid #dfe2e6;
`;
export const DetailsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;
export const DenominationDiv = styled.div`
	width: 10px !important;
`;
export const DenominationContainer = styled.div``;

export const TopContainer = styled.div`
	display: flex;
	background-color: #5c2684;
	color: white;
	padding: 5px 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	flex-direction: row;
	justify-content: space-between;
`;
export const DenominationWrap = styled.div`
	display: flex;
	color: white;
	align-items: center;
	padding: 5px;
	justify-content: space-between;
`;

export const DHeader = styled.p``;

export const TopTitle = styled.p`
	font-weight: 700;
	font-size: 24px;
	line-height: 120%;
	color: #2d2235;
`;
export const Top = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
`;
export const IconWrap = styled.div`
	cursor: pointer;
	display: flex;
	gap: 10px;
	flex-direction: row;
`;
