import { useState, useCallback, useEffect } from "react";
import PrimaryButton from "../../components/Buttons/Button";
import Modal from "../../components/Modal/CustomModal";
import { ErrorHandler, tableHeader } from "../../utils/config";
import GeneralModal from "../../components/Modal/GeneralModal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
	ComponentWrapper,
	HeaderText,
	Container,
	ButtonWrap,
	DataDiv,
	Status,
	ButtonDiv,
	LoaderDiv,
	TableDiv,
	InputWidth,
	TopWrapper,
	TopModal,
} from "./styles/styles";
import ViewAddNewRole from "./ViewAddNewRole";
import ViewAddUser from "./ViewAddUser";
import {
	useApproveRolesMutation,
	useChangeStatusMutation,
	useDeclineRolesMutation,
	useDeleteRoleMutation,
	useGetAdminRolesQuery,
	useGetAllUsersQuery,
	useGetPendingRolesQuery,
	useGetUserMutation,
	useGetUsersInRoleQuery,
} from "../../services/admin/adminService";
import PrimaryTable from "../../components/Table";
import { Oval } from "react-loading-icons";
import toast from "react-hot-toast";
import UpdateUser from "./UpdateUser";
import PrimaryInput from "../../components/PrimaryInput";
import { FaSearch } from "react-icons/fa";
import UpdateRole from "./UpdateRole";
import ViewUsers from "./ViewUsers";
import {
	LeftD,
	RightD,
	Text,
	FieldWrapD,
	DetailsWrap,
	Top,
	TopTitle,
	IconWrap,
} from "../CashWithdrawals/style";

const Admin = () => {
	//@ts-ignore
	const localInfo = JSON.parse(localStorage.getItem("staffInfo"));
	let email = localInfo?.user?.email;
	const [onOpenUserModal, setOpenUserModal] = useState<boolean>(false);
	const [isOpenRoleModal, setOpenRoleModal] = useState<boolean>(false);
	const [isRoleUpdatedModal, setIsRoleUpdatedModal] =
		useState<boolean>(false);
	const [onOpenUpdateUserModal, setOpenUpdateUserModal] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [username, setNameOfUser] = useState("");
	const [openInfo, setOpenInfo] = useState(false);
	const [roleId, setRoleId] = useState(0);
	const [togglePendingRoles, setTogglePendingRoles] = useState(false);
	const [changeStatus] = useChangeStatusMutation();
	const getAllUsers = useGetAllUsersQuery({ pageNumber });
	const userData = getAllUsers?.data?.data?.data;
	const loading = getAllUsers?.isLoading;
	const getPendingRoles = useGetPendingRolesQuery();
	const getAdminRoles = useGetAdminRolesQuery();
	const roleNames = getAdminRoles?.data?.data;
	const roleLoading = getAdminRoles?.isLoading;
	const [deleteRole] = useDeleteRoleMutation();
	const [getUser] = useGetUserMutation();
	const [usersTableData, setUsersTableData] = useState([]);
	const [itemId, setItemId] = useState<number>(0);
	const getUsersInRole = useGetUsersInRoleQuery({ roleId: itemId });
	const [showTable, setShowTable] = useState(true);
	const [showRoleTable, setShowRoleTable] = useState(true);
	const [result, setResult] = useState<any>();
	const [value, setValue] = useState<string>("");
	const [userDetails, setUserDetails] = useState<any>();
	const [approveRoles] = useApproveRolesMutation();
	const [declineRoles] = useDeclineRolesMutation();
	const handleUsername = (name: string, role: number) => {
		setNameOfUser(name);
		setRoleId(role);
		const getUser = userData?.find((user: any) => user.username === name);
		setUserDetails(getUser);
	};

	const usersTableHeader: any = [
		{
			id: 1,
			title: "S/N",
		},
		{
			id: 2,
			title: "Username",
		},

		{
			id: 3,
			title: "Status",
		},
		{
			id: 4,
			title: "Date Created",
		},
		{
			id: 5,
			title: "Action",
		},
	];

	const roleHeader: any = [
		{
			id: 1,
			title: "S/N",
		},
		{
			id: 1,
			title: "Role",
		},
		{
			id: 2,
			title: "Credit Limit",
		},
		{
			id: 3,
			title: "Credit Limit FCY",
		},
		{
			id: 4,
			title: "Debit Limit",
		},
		{
			id: 5,
			title: "Date Created",
		},
		{
			id: 6,
			title: "Action",
		},
	];

	const getRoleUsersLoading = getUsersInRole.isLoading;
	const handleRoleUpdate = () => {
		openRoleUpdateModal();
	};
	const pendingDataRoles = getPendingRoles?.data?.data;
	const filteredPendingData = pendingDataRoles
		? pendingDataRoles.filter((item: any) => item.createdBy !== email)
		: [];

	const approveRoleFunc = async () => {
		setItemId(roleId);
		try {
			const approve = await approveRoles({ roleId: itemId }).unwrap();
			if (approve?.statusCode === 200) {
				toast.success(approve?.message);
			} else toast.success("An error occurred,try again later");
		} catch (err: any) {
			ErrorHandler(err);
		}
	};
	const declineRoleFunc = async () => {
		setItemId(roleId);
		try {
			const decline = await declineRoles({ roleId: itemId }).unwrap();

			if (decline?.statusCode === 200) {
				toast.success(decline?.message);
			}
		} catch (err: any) {
			ErrorHandler(err);
		}
	};
	const pendingOptions = [
		{
			title: "Approve",
			action: approveRoleFunc,
			id: 1,
		},
		// {
		//   title: "Decline",
		//   action: declineRoleFunc,
		//   id: 2,
		// }
	];
	const listOfPendingRoles = filteredPendingData.map((item: any) => [
		<DataDiv>{item?.roleName}</DataDiv>,
		item?.creditLimit,
		item?.creditLimitFCY,
		item?.debitLimit,
		item?.debitLimitFCY,
		item?.dateCreated?.slice(0, 10),
		<>
			<PrimaryButton
				background={"#fff"}
				color={"#5c2684"}
				width={"100%"}
				title={"Action"}
				icon={""}
				rightIcon={true}
				loading={false}
				leftIconName={<></>}
				rightIconName={<></>}
				onClick={() => handleUsername(item?.username, item?.roleId)}
				leftIcon={false}
				border={"1px solid #5c2684"}
				action={true}
				options={pendingOptions}
				item={item}
			/>
		</>,
	]);

	const handleInfoModal = () => {
		setOpenInfo((prevState) => !prevState);
	};
	const onUpdateModal = () => {
		setOpenUpdateUserModal(true);
	};
	const openRoleUpdateModal = () => {
		setIsRoleUpdatedModal((prevState) => !prevState);
	};
	const closeUpdateModal = () => {
		setOpenUpdateUserModal(false);
	};

	const handleStatusChange = async () => {
		try {
			const response = await changeStatus({
				username: username,
			}).unwrap();
			if (response?.message !== "" || response !== undefined)
				toast.success(response?.message);
			getAllUsers.refetch();
		} catch (err: any) {
			ErrorHandler(err);
		}
	};

	const reloadAllRoles = () => {
		setTogglePendingRoles(false);
		setShowRoleTable(true);
	};
	const pendingRoles = () => {
		setTogglePendingRoles(true);
	};
	const handleUserSearch: any = async () => {
		try {
			const response: any = await getUser({
				username: value?.toLowerCase(),
			}).unwrap();
			if (response?.data) {
				setResult(response?.data);
				setValue("");
				setShowTable(false);
				toast.success(response?.message);
			}
		} catch (err: any) {
			ErrorHandler(err);
		}
	};
	const onHandleUser = useCallback(() => {
		setOpenUserModal((prevState) => !prevState);
	}, []);

	// useEffect(() => {
	//   setItemId(roleId);
	// }, [roleId])

	const handleGetUsersInRoleDetails = async () => {
		setItemId(roleId);
		try {
			// Call the asynchronous function getUsersInRole
			const response: any = await getUsersInRole;
			// Check if response is successful
			if (
				response?.currentData !== undefined &&
				response?.currentData !== null
			) {
				// Handle success
				setUsersTableData(response?.data?.data);
				setOpenInfo(true);
				toast.success(response?.data?.message);
			} else {
				// Handle error when data is undefined or null
				toast.error(response?.error?.data?.message);
			}
		} catch (error) {
			// Handle network or other errors
			toast.error("An error occurred, try again later");
		}
	};

	const handleDeleteRole = async () => {
		setItemId(roleId);
		try {
			const response = await deleteRole(roleId).unwrap();
			const data = await response;
			toast.success(data?.message);
		} catch (err: any) {
			err?.data !== undefined
				? toast.error(err?.data?.message)
				: toast.error(err?.message);
		}
	};
	const optionsList = [
		{
			title: "Change status",
			action: handleStatusChange,
			id: 1,
		},
		{
			title: "View role",
			action: onUpdateModal,
			id: 2,
		},
	];

	const rolesActionList = [
		{
			title: "Update role",
			action: handleRoleUpdate,
			id: 1,
		},

		{
			title: "Delete role",
			action: handleDeleteRole,
			id: 2,
		},
		{
			title: "View users",
			action: handleGetUsersInRoleDetails,
			id: 3,
		},
		// {
		//   title: "Role details",
		//   action:  handleGetRoleDetails,
		//   id: 4,
		// }
	];
	const listOfUsersInRole = usersTableData?.map((item: any) => [
		<DataDiv>{item?.username}</DataDiv>,
		<Status
			color={item?.isActive === true ? "#01B272" : "#C43C20"}
			backgroundColor={
				item?.isActive === true
					? "rgba(1, 178, 114, 0.2)"
					: "rgba(231, 175, 164, 0.3)"
			}
		>
			{item?.isActive === true ? "Active" : "Inactive"}
		</Status>,

		item?.dateCreated?.slice(0, 10),
		// <>
		//   {/* <PrimaryButton
		//     background={"#fff"}
		//     color={"#5c2684"}
		//     width={"100%"}
		//     title={"Action"}
		//     icon={""}
		//     rightIcon={true}
		//     loading={false}
		//     leftIconName={<></>}
		//     rightIconName={<></>}
		//     onClick={() => handleUsername(item?.username, item?.id)}
		//     leftIcon={false}
		//     border={"1px solid #5c2684"}
		//     action={true}
		//     options={usersInRoleList}
		//    // options={item?.isActive === true ? ActivatedUsers : DeactivatedUsers}
		//     item={item}
		//   /> */}
		// </>,
	]);

	const tableData = userData?.map((item: any, index: number) => [
		index + 1,
		<DataDiv>{item?.username}</DataDiv>,
		// <DataDiv>{item?.username.slice(0, item?.username.indexOf("@"))}</DataDiv>,
		<Status
			color={item?.isActive === true ? "#01B272" : "#C43C20"}
			backgroundColor={
				item?.isActive === true
					? "rgba(1, 178, 114, 0.2)"
					: "rgba(231, 175, 164, 0.3)"
			}
		>
			{item?.isActive === true ? "Active" : "Inactive"}
		</Status>,

		item?.dateCreated?.slice(0, 10),
		<>
			<PrimaryButton
				background={"#fff"}
				color={"#5c2684"}
				width={"100%"}
				title={"Action"}
				icon={""}
				rightIcon={true}
				loading={false}
				leftIconName={<></>}
				rightIconName={<></>}
				onClick={() => handleUsername(item?.username, item?.roleId)}
				leftIcon={false}
				border={"1px solid #5c2684"}
				action={true}
				options={optionsList}
				item={item}
			/>
		</>,
	]);

	// const roleTableData = roleNames?.map((item: any, index: number) => [
	//   index + 1,
	//   <DataDiv>{item?.roleName}</DataDiv>,

	//   <>
	//     {new Intl.NumberFormat("ja-JP", {
	//       style: "currency",
	//       currency: "NGN",
	//     }).format(item?.creditLimit)}
	//   </>,
	//   <>
	//     {new Intl.NumberFormat("ja-JP", {
	//       style: "currency",
	//       currency: "NGN",
	//     }).format(item?.creditLimitFCY)}
	//   </>,
	//   <>
	//     {new Intl.NumberFormat("ja-JP", {
	//       style: "currency",
	//       currency: "NGN",
	//     }).format(item?.debitLimit)}
	//   </>,
	//   <>
	//     {new Intl.NumberFormat("ja-JP", {
	//       style: "currency",
	//       currency: "NGN",
	//     }).format(item?.debitLimitFCY)}
	//   </>,

	//   item?.dateCreated?.slice(0, 10),
	//   <>
	//     <PrimaryButton
	//       background={"#fff"}
	//       color={"#5c2684"}
	//       width={"100%"}
	//       title={"Action"}
	//       icon={""}
	//       rightIcon={true}
	//       loading={false}
	//       leftIconName={<></>}
	//       rightIconName={<></>}
	//       onClick={() => handleUsername(item?.username, item?.id)}
	//       leftIcon={false}
	//       border={"1px solid #5c2684"}
	//       action={true}
	//       options={rolesActionList}
	//       item={item}
	//     />
	//   </>,
	// ]);

	const myArray = [result];

	const searchBodyData = myArray?.map((item: any) => [
		<DataDiv>{item?.username}</DataDiv>,
		<Status
			color={item?.isActive === true ? "#01B272" : "#C43C20"}
			backgroundColor={
				item?.isActive === true
					? "rgba(1, 178, 114, 0.2)"
					: "rgba(231, 175, 164, 0.3)"
			}
		>
			{item?.isActive === true ? "Active" : "Inactive"}
		</Status>,

		item?.dateCreated?.slice(0, 10),
		<>
			<PrimaryButton
				background={"#fff"}
				color={"#5c2684"}
				width={"100%"}
				title={"Action"}
				icon={""}
				rightIcon={true}
				loading={false}
				leftIconName={<></>}
				rightIconName={<></>}
				onClick={() => handleUsername(item?.username, item?.id)}
				leftIcon={false}
				border={"1px solid #5c2684"}
				action={true}
				options={optionsList}
				// options={item?.isActive === true ? ActivatedUsers : DeactivatedUsers}
				item={item}
			/>
		</>,
	]);

	const onOpenRoleModal = () => {
		setOpenRoleModal((prevState) => !prevState);
	};
	const setTable = () => {
		setShowTable(true);
	};
	return (
		<>
			<ComponentWrapper>
				<Container>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							paddingTop: "20px",
						}}
					>
						<HeaderText>Admin Information</HeaderText>
						<ButtonWrap>
							<PrimaryButton
								background={""}
								color={""}
								width={150}
								title="Add New User"
								loading={false}
								type="button"
								leftIconName={<></>}
								rightIconName={<></>}
								onClick={onHandleUser}
								border="#5C2684"
							/>
						</ButtonWrap>
					</div>
					<TopWrapper>
						<InputWidth>
							<PrimaryInput
								name={"username"}
								type={"text"}
								placeholder={"Search User"}
								rightIcon={
									<FaSearch onClick={handleUserSearch} />
								}
								password={false}
								error={""}
								label={"Search"}
								check={""}
								padding={""}
								leftIcon={<></>}
								value={value}
								onChange={(e) => {
									setValue(e.target.value);
								}}
								disabled={undefined}
							/>
						</InputWidth>
					</TopWrapper>
					{showTable &&
						(loading ? (
							<LoaderDiv>
								<Oval
									stroke="#5c2684"
									fill="white"
									width={24}
									height={24}
								/>
							</LoaderDiv>
						) : (
							<TableDiv>
								<PrimaryTable
									header={usersTableHeader}
									body={tableData}
									arrOfObject={false}
									TopComponent={false}
									itemsPerPage={10}
								/>
							</TableDiv>
						))}
					{!showTable && (
						<PrimaryTable
							header={usersTableHeader}
							body={searchBodyData}
							arrOfObject={false}
							itemsPerPage={10}
							TopComponent={false}
						/>
					)}
					{showTable && (
						<ButtonDiv>
							<PrimaryButton
								onClick={() => setPageNumber(pageNumber - 1)}
								isDisabled={pageNumber === 1 ? true : false}
								background={""}
								color={""}
								width={""}
								title={"Previous"}
								loading={false}
								leftIconName={<></>}
								rightIconName={<></>}
								border={""}
							/>
							<PrimaryButton
								onClick={() => setPageNumber(pageNumber + 1)}
								isDisabled={false}
								background={""}
								color={""}
								width={""}
								title={"Next"}
								loading={false}
								leftIconName={<></>}
								rightIconName={<></>}
								border={""}
							/>
						</ButtonDiv>
					)}
					{!showTable && (
						<ButtonDiv>
							<PrimaryButton
								onClick={setTable}
								isDisabled={false}
								background={""}
								color={""}
								width={""}
								title={"Load all users"}
								loading={false}
								leftIconName={<></>}
								rightIconName={<></>}
								border={""}
							/>
						</ButtonDiv>
					)}
				</Container>

				{/* <Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "28px 0",
            }}
          >
            <HeaderText>Admin Role</HeaderText>
            <ButtonWrap>
              <PrimaryButton
                background={""}
                color={""}
                width={150}
                title="Add New Role"
                loading={false}
                type="button"
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onOpenRoleModal}
                border={"#5C2684"}
              />
            </ButtonWrap>
          </div>
          {showRoleTable &&
            (roleLoading ? (
              <LoaderDiv>
                <Oval stroke="#5c2684" fill="white" width={24} height={24} />
              </LoaderDiv>
            ) : (
              <TableDiv>
                <PrimaryTable
                  header={roleHeader}
                  itemsPerPage={10}
                  body={
                    togglePendingRoles === true
                      ? listOfPendingRoles
                      : roleTableData
                  }
                  arrOfObject={false}
                  TopComponent={false}
                />
              </TableDiv>
            ))}
          <ButtonDiv>
            <PrimaryButton
              onClick={pendingRoles}
              isDisabled={false}
              background={""}
              color={""}
              width={""}
              title={"Pending roles"}
              loading={false}
              leftIconName={<></>}
              rightIconName={<></>}
              border={""}
            />
            <PrimaryButton
              onClick={reloadAllRoles}
              isDisabled={false}
              background={""}
              color={""}
              width={""}
              title={"Show Roles"}
              loading={false}
              leftIconName={<></>}
              rightIconName={<></>}
              border={""}
            />
          </ButtonDiv>
        </Container> */}

				{/* <Modal
          open={onOpenUpdateUserModal}
          closeIcon
          onClose={closeUpdateModal}
          size="small"
          title=""
          className=""
        >
          <UpdateUser usernameValue={username} roleId={roleId} />
        </Modal>
 */}

				{onOpenUpdateUserModal && (
					<GeneralModal general>
						<TopModal>
							<div>
								<TopTitle>User Details</TopTitle>
								<p>{username}</p>
							</div>
							<IconWrap>
								<CloseIcon onClick={closeUpdateModal} />
							</IconWrap>
						</TopModal>
						<FieldWrapD>
							<DetailsWrap>
								<LeftD>
									<Text>Role</Text>{" "}
								</LeftD>
								<RightD>
									<Text>
										{userDetails?.roleName
											? userDetails?.roleName
											: "N/A"}
									</Text>{" "}
								</RightD>
							</DetailsWrap>
							<DetailsWrap>
								<LeftD>
									<Text>Created By</Text>{" "}
								</LeftD>
								<RightD>
									<Text>{userDetails?.createdBy}</Text>{" "}
								</RightD>
							</DetailsWrap>
							<DetailsWrap>
								<LeftD>
									<Text>Date Created </Text>{" "}
								</LeftD>
								<RightD>
									<Text>
										{userDetails?.dateCreated?.slice(0, 10)}
									</Text>{" "}
								</RightD>
							</DetailsWrap>
							<DetailsWrap>
								<LeftD>
									<Text>Date Modified</Text>{" "}
								</LeftD>
								<RightD>
									<Text>
										{userDetails?.dateModified?.slice(
											0,
											10
										)}
									</Text>{" "}
								</RightD>
							</DetailsWrap>
							<DetailsWrap>
								<LeftD>
									<Text>Updated By</Text>{" "}
								</LeftD>
								<RightD>
									<Text>{userDetails?.updatedBy}</Text>{" "}
								</RightD>
							</DetailsWrap>

							<DetailsWrap>
								<LeftD>
									<Text>Account Locked</Text>{" "}
								</LeftD>
								<RightD>
									<Text>
										{userDetails?.isAccountLocked === true
											? "True"
											: "False"}
									</Text>{" "}
								</RightD>
							</DetailsWrap>
							{/* <DetailsWrap>
                <LeftD>
                  <Text>Narration</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{userDetails?.narration}</Text>{" "}
                </RightD>
              </DetailsWrap> */}
							<DetailsWrap>
								<LeftD>
									<Text>Last Login</Text>{" "}
								</LeftD>
								<RightD>
									<Text>
										{" "}
										{userDetails?.lastLogin?.slice(0, 10)}
									</Text>{" "}
								</RightD>
							</DetailsWrap>
						</FieldWrapD>
					</GeneralModal>
				)}

				<Modal
					open={onOpenUserModal}
					closeIcon
					onClose={onHandleUser}
					size="small"
					title=""
					className=""
				>
					<ViewAddUser />
				</Modal>

				<Modal
					open={isOpenRoleModal}
					closeIcon
					onClose={onOpenRoleModal}
					size="small"
					title=""
					className=""
				>
					<ViewAddNewRole />
				</Modal>

				<Modal
					open={openInfo}
					closeIcon
					onClose={handleInfoModal}
					size="small"
					title=""
					className=""
				>
					{!listOfUsersInRole ? (
						<LoaderDiv>
							<Oval
								stroke="#5c2684"
								fill="white"
								width={24}
								height={24}
							/>
						</LoaderDiv>
					) : (
						<ViewUsers
							body={listOfUsersInRole}
							header={usersTableHeader}
						/>
					)}
				</Modal>

				<Modal
					open={isRoleUpdatedModal}
					closeIcon
					onClose={openRoleUpdateModal}
					size="small"
					title=""
					className=""
				>
					<UpdateRole
						id={roleId}
						roleList={
							togglePendingRoles === true
								? filteredPendingData
								: roleNames
						}
						setIsRoleUpdatedModal={setIsRoleUpdatedModal}
					/>
				</Modal>
			</ComponentWrapper>
		</>
	);
};

export default Admin;
