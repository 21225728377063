import React from "react";

export const IntraTransFerIcon = ({
  iconColor = "#FCBF24",
  iconBg = "#FFE198",
}) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24.5" r="24.5" fill={iconBg} fill-opacity="0.6" />
      <path
        d="M30.8086 18.2505H32.1686C31.9786 17.9805 31.7786 17.7305 31.5686 17.4805L30.8086 18.2505Z"
        fill={iconColor}
      />
      <path
        d="M30.52 16.4203C30.27 16.2103 30.02 16.0103 29.75 15.8203V17.1803L30.52 16.4203Z"
        fill={iconColor}
      />
      <path
        d="M31.5795 17.4795L34.5295 14.5295C34.8195 14.2395 34.8195 13.7595 34.5295 13.4695C34.2395 13.1795 33.7595 13.1795 33.4695 13.4695L30.5195 16.4195C30.8995 16.7495 31.2495 17.1095 31.5795 17.4795Z"
        fill={iconColor}
      />
      <path
        d="M29.7517 15C29.7517 14.59 29.4117 14.25 29.0017 14.25C28.6017 14.25 28.2817 14.57 28.2617 14.96C28.7817 15.21 29.2817 15.49 29.7517 15.82V15Z"
        fill={iconColor}
      />
      <path
        d="M33.7519 19C33.7519 18.59 33.4119 18.25 33.0019 18.25H32.1719C32.5019 18.72 32.7919 19.22 33.0319 19.74C33.4319 19.72 33.7519 19.4 33.7519 19Z"
        fill={iconColor}
      />
      <path
        d="M24.75 26.7508H25.05C25.44 26.7508 25.75 26.4008 25.75 25.9708C25.75 25.4308 25.6 25.3508 25.26 25.2308L24.75 25.0508V26.7508Z"
        fill={iconColor}
      />
      <path
        d="M33.04 19.74C33.03 19.74 33.02 19.75 33 19.75H29C28.9 19.75 28.81 19.73 28.71 19.69C28.53 19.61 28.38 19.47 28.3 19.28C28.27 19.19 28.25 19.1 28.25 19V15C28.25 14.99 28.26 14.98 28.26 14.96C26.96 14.35 25.52 14 24 14C18.48 14 14 18.48 14 24C14 29.52 18.48 34 24 34C29.52 34 34 29.52 34 24C34 22.48 33.65 21.04 33.04 19.74ZM25.75 23.82C26.39 24.04 27.25 24.51 27.25 25.98C27.25 27.23 26.26 28.26 25.05 28.26H24.75V28.51C24.75 28.92 24.41 29.26 24 29.26C23.59 29.26 23.25 28.92 23.25 28.51V28.26H23.17C21.84 28.26 20.75 27.14 20.75 25.76C20.75 25.34 21.09 25 21.5 25C21.91 25 22.25 25.34 22.25 25.75C22.25 26.3 22.66 26.75 23.17 26.75H23.25V24.53L22.25 24.18C21.61 23.96 20.75 23.49 20.75 22.02C20.75 20.77 21.74 19.74 22.95 19.74H23.25V19.5C23.25 19.09 23.59 18.75 24 18.75C24.41 18.75 24.75 19.09 24.75 19.5V19.75H24.83C26.16 19.75 27.25 20.87 27.25 22.25C27.25 22.66 26.91 23 26.5 23C26.09 23 25.75 22.66 25.75 22.25C25.75 21.7 25.34 21.25 24.83 21.25H24.75V23.47L25.75 23.82Z"
        fill={iconColor}
      />
      <path
        d="M22.25 22.03C22.25 22.57 22.4 22.65 22.74 22.77L23.25 22.95V21.25H22.95C22.57 21.25 22.25 21.6 22.25 22.03Z"
        fill={iconColor}
      />
    </svg>
  );
};
