import React, { useCallback, useEffect, useState, useMemo } from "react";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  ButtonWrap1,
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  ContainerDepo,
  LeftContainerDepo,
  RightContainerDepo,
  ButtonWrap,
  Input,
  Radio,
  LeftD,
  RightD,
  LeftDen,
  RightDen,
  FieldWrapDen,
  DHeader,
  Text,
  FieldWrapD,
  DetailsWrap,
  DetailsWrapDen,
  DenominationContainer,
  TopContainer,
  DenominationWrap,
  Top,
  TopTitle,
  IconWrap,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import {
  collectionInfoData,
  collectionyType,
  currencyType,
  denominationList,
  ErrorHandler,
  tableDatas,
} from "../../utils/config";
import TopCards from "../../components/Cards/TopCards";
import toast from "react-hot-toast";
import { convertAmountToWords } from "../../utils/amountWriter";
import { calculateTotalPrice } from "../../utils";
import { CollectionInfoProp, DeProp } from "../../types/Types";
import DenominationInput from "../../components/PrimaryInput/denInput";
import { useSelector } from "react-redux";
import {
  usePostDangoteMutation,
  useValidateCustomerMutation,
} from "../../services/collection/collection";
import GeneralModal from "../../components/Modal/GeneralModal";
import { TransactionRequest } from "../../services/collection/type";

const Collections = () => {
  const branchCode = useSelector(
    (state: any) => state?.StaffDataReducer?.userDetails?.data?.branchCode
  );
  const user = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo?.user?.displayName
  );
  const currentDate = new Date();

  const [selectedType, setSelectedType] = useState<string>("");
  const [newDenomination, setNewDenomination] = useState<DeProp[]>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [selecteCurrencyType, setSelecteCurrencyType] = useState("");
  const [customerInfo, setCustomerInfo] = useState<any>({});
  const [initialList, setInitialList] = useState(denominationList);
  const [customerNo, setCustomerNumber] = useState<string>("");
  const [error, setError] = useState<any>({});
  const [responseName, setResponseName] = useState<string>("");
  const [details, setDetails] = useState<TransactionRequest>({});
  const [amount, setAmount] = useState<string>("");
  const [isOpenTransModal, setIsOpenTransModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [customerCode, setCustomerCode] = useState("");
  const [collectionInfo, setCollectionInfo] =
    useState<CollectionInfoProp>(collectionInfoData);
  const [debitAccountNumber, setAccountNumber] = useState<string>("");

  const [validateCharge] = useValidateCustomerMutation();
  const [postTransaction, postMutation] = usePostDangoteMutation();

  const header = ["Denomination", "Quantity", "Amount"];
  //const { data: dashboardAnalysis } = useGetDashboardAnalysisQuery();
  const dataBody = tableDatas?.map((item: any) => [
    item.sn,
    item.firstname,
    item.lastname,
  ]);

  const handleChange = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setIsDisabled(false);
    }
  };

  const copyToClipBoard = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast.success(" copied successfully!");
    } catch (err) {
      toast.error("Failed to copy!");
    }
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionInfo((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const updateDenomination = (index: any, quantity: number) => {
    const updatedDenomination = [...denominationList];
    updatedDenomination[index].quantity = quantity;
    updatedDenomination[index].amount =
      quantity * updatedDenomination[index].denomination;

    updatedDenomination.forEach((item: DeProp) => {
      if (item.amount !== 0) {
        const newList: DeProp[] = denominationList.filter(
          (denomination) => denomination.amount !== 0
        );
        setNewDenomination(newList);
      }
    });
  };

  useEffect(() => {
    const getTotal = calculateTotalPrice(newDenomination as DeProp[]);
    setTotalAmount(getTotal as number);
  }, [newDenomination]);

  const handleCustomerSearch = async () => {
    try {
      const requiredValue = {
        category: selectedType,
        customerNumber: customerNo.toString(),
      };

      const response = await validateCharge(requiredValue).unwrap();
      toast.success(response?.message);
      setResponseName(response?.data);
      setCustomerCode(requiredValue?.customerNumber);
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const payload = useMemo(
    () => ({
      depositorName: responseName,
      customerCode: customerNo,
      tellerNum: collectionInfo?.tellerNum,
      currencyType: selecteCurrencyType,
      depositorPhoneNo: collectionInfo?.depositorPhoneNo,
      debitAccountNumber: debitAccountNumber,
      amount: parseInt(amount),
      denominations: newDenomination,
      category: selectedType,
    }),
    [
      selecteCurrencyType,
      newDenomination,
      collectionInfo,
      amount,
      selectedType,
      responseName,
      customerNo,
    ]
  );

  const closeReceipt = () => {
    setResponseName("");
    setCustomerCode("");
    setAccountNumber("");
    setCollectionInfo({
      ...collectionInfo,
      tellerNum: "",
      depositorPhoneNo: "",
    });
    setAmount("");
    setCustomerNumber("");
  };

  const onOpenTransDetail = useCallback(() => {
    const { depositorPhoneNo, tellerNum } = collectionInfo;
    const updatedError: any = {};

    if (!selecteCurrencyType) {
      updatedError.selectedCurrencyType = "Select currency type";
    }
    if (!amount) {
      updatedError.amount = "Amount is required, SELECT DENOMINATION ALSO";
    }

    if (!debitAccountNumber) {
      updatedError.debitAccountNumber = "Account number is required";
    }
    if (!responseName) {
      updatedError.responseName = "Depositor's name is required";
    }
    if (!customerCode) {
      updatedError.customerNumber = "Customer's code is required";
    }
    if (!tellerNum) {
      updatedError.tellerNum = "teller number is required";
    }
    if (!depositorPhoneNo || depositorPhoneNo.length !== 11) {
      updatedError.depositorPhoneNo = "Phone is required, 11 digits";
    }
    if (newDenomination?.length === 0) {
      updatedError.denomination = "Fill denomination";
      toast.error("Fill Denominations");
    }

    const getTotal = calculateTotalPrice(newDenomination as DeProp[]);
    if (getTotal !== parseInt(amount)) {
      updatedError.match = "Not match";
      toast.error("Ensure amount and total amount in denominations are equal");
    }
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    setError(updatedError);

    if (Object.keys(updatedError).length === 0) {
      setDetails(payload);
      setIsOpenTransModal(true);
      setIsDisabled(true);
    }
  }, [customerInfo, selecteCurrencyType, newDenomination, payload, amount]);

  const onSubmit = async () => {
    try {
      const response = await postTransaction(payload).unwrap();

      if (response.statusCode === 200) {
        setIsOpenTransModal(false);
        toast.success(response?.message);
        closeReceipt();
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else if (error?.status === 500) {
        ErrorHandler(error);
      } else {
        ErrorHandler(error);
      }
    }
  };

  const handleClose = () => {
    setIsOpenTransModal(false);
  };

  return (
    <>
      <Header title="Collections" />
      <ComponentWrapper>
        <TopCards />

        <Container>
          <HeaderText>Collections/Post Collections</HeaderText>
          <FieldWrap>
            <DropDownC
              value={selectedType}
              placeholder={"Select Type"}
              options={collectionyType}
              errorMessage={""}
              setOptionValue={() => {}}
              label={"Collection Type"}
              setSelectedItem={setSelectedType}
              setSelectedItems={() => {}}
            />

            <PrimaryInput
              name={"comp_Code"}
              type={"text"}
              check={""}
              padding={""}
              error={error?.customerNumber}
              placeholder={"123456"}
              label="Customer Code"
              leftIcon={<InputClose />}
              value={undefined}
              onChange={(e: any) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setCustomerNumber(value);
              }}
            />
          </FieldWrap>

          <ButtonWrap1>
            <PrimaryButton
              background={"#5c2684"}
              color={"#fff"}
              width={150}
              title={"Search"}
              icon={""}
              loading={false}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={handleCustomerSearch}
              leftIcon={false}
              border={""}
            />
          </ButtonWrap1>
        </Container>

        <ContainerDepo>
          <LeftContainerDepo>
            <HeaderText>Transaction Information</HeaderText>
            <PrimaryInput
              name="tellerNum"
              type="number"
              inputMode="numeric"
              placeholder="Teller Number"
              rightIcon={<></>}
              error={error?.tellerNum}
              label="Teller Number  *"
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={onHandleChange}
              value={undefined}
            />

            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount  *"
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setAmount(value);
              }}
              value={amount}
            />

            <PrimaryInput
              name="accountNumber"
              type="text"
              rightIcon={<></>}
              placeholder="Enter Account Number"
              error={error?.debitAccountNumber}
              label="Account Number"
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setAccountNumber(value);
              }}
              value={undefined}
            />

            <DropDownC
              value={selecteCurrencyType}
              placeholder="Currency Type"
              options={currencyType}
              errorMessage={error?.selectedCurrencyType}
              label={"Currency Type"}
              setSelectedItem={setSelecteCurrencyType}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />

            <PrimaryInput
              name="depositorName"
              type="text"
              placeholder="Depositor's Name"
              rightIcon={<></>}
              error={error?.responseName}
              label="Depositor's Name *"
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={onHandleChange}
              value={responseName}
            />
            <PrimaryInput
              name="depositorPhoneNo"
              maxLength={11}
              type="number"
              inputMode="numeric"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error?.depositorPhoneNo}
              label="Depositor's Phone Number"
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={onHandleChange}
              value={undefined}
            />

            <ButtonWrap>
              <div>
                <PrimaryButton
                  background={""}
                  type="button"
                  color={""}
                  width={150}
                  title={"Post Transaction"}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={onOpenTransDetail}
                  border={"#5C2684"}
                />
              </div>
            </ButtonWrap>
          </LeftContainerDepo>
          <RightContainerDepo>
            <DenominationContainer>
              <TopContainer>
                <DHeader>Denomination</DHeader>
                <DHeader>Quantity</DHeader>
                {/* <DHeader>Amt</DHeader> */}
              </TopContainer>
              {initialList.map((denomination, index) => (
                <DenominationWrap key={denomination.denomination}>
                  <Text>{denomination.denomination}</Text>
                  <DenominationInput
                    name={""}
                    type={""}
                    placeholder={""}
                    rightIcon={<></>}
                    label={""}
                    value={denomination.quantity}
                    onChange={(e) =>
                      updateDenomination(index, parseInt(e.target.value))
                    }
                    check={""}
                    padding={""}
                    leftIcon={<></>}
                  />{" "}
                  {/* <Text>{1000*thousand}</Text> */}
                </DenominationWrap>
              ))}
            </DenominationContainer>

            <FieldWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Total Amount:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {selecteCurrencyType}{" "}
                    {Number.isNaN(totalAmount) ? 0 : totalAmount}.00
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Amount in words:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {convertAmountToWords(totalAmount ? totalAmount : 0)}{" "}
                    {selecteCurrencyType === "NGN"
                      ? "Naira"
                      : selecteCurrencyType === "GBP"
                      ? "Pound Sterling"
                      : "Dollar"}
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Transaction Date:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{currentDate.toJSON().slice(0, 10)}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Branch Code:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{branchCode}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Paid By:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{responseName}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Teller:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{user}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
            </FieldWrapDen>
          </RightContainerDepo>
        </ContainerDepo>
      </ComponentWrapper>

      {isOpenTransModal && (
        <GeneralModal general>
          <Top>
            <TopTitle>Collection Transaction</TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleClose} />
            </IconWrap>
          </Top>
          <FieldWrapD>
            <DetailsWrap>
              <LeftD>
                <Text>Payment Type</Text>{" "}
              </LeftD>
              <RightD>
                <Text>Dangote - {details?.category}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Depositor's Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.depositorName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Customer Code</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.customerCode}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Teller Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.tellerNum}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Currency Type</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.currencyType}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Account Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.debitAccountNumber}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Phone Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text> {details?.depositorName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Amount</Text>{" "}
              </LeftD>
              <RightD>
                <Text>
                  {" "}
                  {new Intl.NumberFormat("ja-JP", {
                    style: "currency",
                    currency: details?.currencyType,
                  }).format(details?.amount as any)}
                </Text>{" "}
              </RightD>
            </DetailsWrap>
          </FieldWrapD>
          <Radio>
            <Input
              type="radio"
              id="html"
              name="fav_language"
              value="acc"
              onChange={handleChange}
            />
             {" "}
            <label>
              I have read through all details displayed above and have ensured
              the information is accurate.
            </label>
          </Radio>
          <ButtonWrap>
            <div>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={100}
                title={"Post Transaction"}
                icon={""}
                isLoading={postMutation?.isLoading}
                loading={false}
                isDisabled={isDisabled}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onSubmit}
                leftIcon={false}
                border={""}
              />
            </div>
          </ButtonWrap>
        </GeneralModal>
      )}
    </>
  );
};

export default Collections;
