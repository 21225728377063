import styled from "styled-components";
export const DetailsWrap = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #dfe2e6;
`;
export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #2d2235;
`;
export const RightD = styled.div`
  width: 50%;
  padding: 12px 14px;
`;
export const LeftD = styled.div`
  width: 45%;
  padding: 12px 14px;
  border-right: 1px solid #dfe2e6;
`;

export const FieldWrapD = styled.div`
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fafbfb;
`;
