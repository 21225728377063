import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Container,
  HeaderText,
  BottomContainer,
  TableContainer,
  ContentLayout,
  FieldWrap,
  BiometricButtonWrap,
} from "./style";

import { useSelector } from "react-redux";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { collectionyType, fingerType } from "../../utils/config";
import PrimaryButton from "../../components/Buttons/Button";

const Biometric = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [selectedFinger, setSelectedFinger] = useState<string>("");

  const handleBiometric = () => {
    window.open("http://localhost:2017/Fingerenrolment", "_blank");
  };
  return (
    <>
      <Header title="Biometric" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            <HeaderText>Biometric</HeaderText>
          </BottomContainer>
          <FieldWrap>
            <DropDownC
              value={selectedFinger}
              placeholder={"Select Finger"}
              options={fingerType}
              errorMessage={""}
              setOptionValue={() => {}}
              label={"Finger Type"}
              setSelectedItem={setSelectedFinger}
              setSelectedItems={() => {}}
            />
            <BiometricButtonWrap>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={150}
                title={"Search"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleBiometric}
                leftIcon={false}
                border={""}
              />
            </BiometricButtonWrap>
          </FieldWrap>
        </Container>
      </ContentLayout>
    </>
  );
};

export default Biometric;
