import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../layout/DashboardLayout/Layout";
import Home from "../pages/Home/Home";
import CashDeposit from "../pages/CashDeposit/index";
import CashWithdrawal from "../pages/CashWithdrawals";
import BioWithdrawal from "../pages/BioWithdrawal";
import FcmbToFcmb from "../pages/FcmbToFcmb";
import FcmbToOther from "../pages/FcmbToOther";
import Collections from "../pages/Collections";
import Remittance from "../pages/Remittance";
import Admin from "../pages/Admin/admin";
import Protected from "./Protected";
import { useSelector } from "react-redux";
import DepositReports from "../pages/Reports/Deposit";
import WithdrawalLimitApproval from "../pages/LimitApproval/withdrawal";
import TransferLimitApproval from "../pages/LimitApproval/transfer";
import DepositLimitApproval from "../pages/LimitApproval/deposit";
import DailyTransactions from "../pages/DailyTransaction";
import EasyAccount from "../pages/EasyAccount";
import CollectionApproval from "../pages/LimitApproval/collectionApproval";
import SpecialCollections from "../pages/SpecialCollection/index";
import Process from "../pages/Process";
import UserApproval from "../pages/Admin/userApproval";
import Biometric from "../pages/biometric";

const Login = lazy(() => import("../pages/Auth/Login/login"));

const AppRouter = () => {
  const staffInfo = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo
  );

  return (
    <Suspense>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <Protected isVerified={staffInfo}>
              {" "}
              <Layout children={<></>} title={""} />{" "}
            </Protected>
          }
        >
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="remittance" element={<Remittance />} />
          <Route path="cash-deposit" element={<CashDeposit />} />
          <Route path="cash-withdrawal" element={<CashWithdrawal />} />
          <Route path="bio-metrics-withdrawal" element={<BioWithdrawal />} />
          <Route path="collections" element={<Collections />} />
          <Route path="admin" element={<Admin />} />
          <Route path="user-approval" element={<UserApproval />} />
          <Route path="biometric" element={<Biometric />} />
          <Route path="/reports" element={<DepositReports />} />
          <Route path="/process" element={<Process />} />
          <Route path="/daily-transactions" element={<DailyTransactions />} />
          <Route path="/easy-account" element={<EasyAccount />} />

          {/* cash deposit */}
          <Route path="deposit" element={<CashDeposit />}>
            <Route index element={<CashDeposit />} />
            <Route path="cash-deposit" element={<CashDeposit />} />
          </Route>

          {/* cash withdrawal */}
          <Route path="withdrawal" element={<CashWithdrawal />}>
            <Route index element={<CashWithdrawal />} />
            <Route path="cash-withdrawal" element={<CashWithdrawal />} />
            <Route path="bio-metrics-withdrawal" element={<BioWithdrawal />} />
          </Route>

          {/* cash transfer */}
          <Route path="transfer">
            <Route index element={<FcmbToFcmb />} />
            <Route path="fcmb-to-fcmb" element={<FcmbToFcmb />} />
            <Route path="fcmb-to-others" element={<FcmbToOther />} />
          </Route>

          {/* collection */}
          <Route path="collection">
            <Route index element={<Collections />} />
            <Route path="collection" element={<Collections />} />
            <Route path="special-collection" element={<SpecialCollections />} />
          </Route>

          {/* limit approval */}
          <Route path="limit-approval">
            <Route index element={<DepositLimitApproval />} />
            <Route path="deposit" element={<DepositLimitApproval />} />
            <Route path="transfer" element={<TransferLimitApproval />} />
            <Route path="withdrawal" element={<WithdrawalLimitApproval />} />
            <Route path="collection" element={<CollectionApproval />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
