import React, { useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { DropDownOptions } from "../../types/Types";

import {
  DropDown,
  DropDownWrapper,
  ListItem,
  ListItems,
  ShowList,
  ShowListIcon,
  Label,
  ErrMsg,
  Top,
  Items,
  InvisibleBackDrop,
  DropInner,
  Item,
  DWrap,
} from "./style";

interface DropProps {
  value: any;
  placeholder: string;
  options: DropDownOptions[] | undefined;
  errorMessage?: string;
  label: string;
  setSelectedItem?: any;
  setSelectedItems?: any;
  setOptionValue?: any;
  multi?: boolean;
  handleMultiClick?: any;
  list?: any;
}

const DropDownC: React.FC<DropProps> = ({
  value,
  options,
  placeholder,
  errorMessage,
  label,
  setOptionValue,
  setSelectedItem,
  setSelectedItems,
  multi,
  handleMultiClick,
  list,
}) => {
  // const [selected, setSelectedItems] = useState<any>("");

  const [open, setOpen] = useState<boolean>(false);
  const handleOpenDropdown = () => {
    setOpen(!open);
  };

  const handleOptionClick = (value: any) => {
    setOpen(false);
    setSelectedItem(value);

    const display = options?.find((option) => option?.value === value);
    setSelectedItems(display?.label);
    setOptionValue(display?.value);
  };

  return (
    <DWrap>
      <Top>
        <Label>{label}</Label>
      </Top>
      <DropDownWrapper>
        <DropInner onClick={handleOpenDropdown}>
          <ShowList>
            {multi ? (
              <>
                {list?.map((item: any) => (
                  <>
                    <Items key={item}>{item}</Items>
                  </>
                ))}
              </>
            ) : (
              <>
                {value?.length !== 0 ? (
                  <>
                    <Items>{value}</Items>
                  </>
                ) : (
                  <Item>{placeholder}</Item>
                )}
              </>
            )}
          </ShowList>
          <ShowListIcon>
            {open ? (
              <HiChevronUp size={24} color="#4E5152" />
            ) : (
              <HiChevronDown size={24} color="#4E5152" />
            )}
          </ShowListIcon>
        </DropInner>
        {open && (
          <>
            <InvisibleBackDrop onClick={() => setOpen(false)} />
            <DropDown>
              <ListItems>
                {options?.map((option: any) => (
                  <>
                    <ListItem
                      key={option.value}
                      onClick={() => handleOptionClick(option.value)}
                    >
                      {option.label}
                    </ListItem>
                  </>
                ))}
              </ListItems>
            </DropDown>
          </>
        )}
      </DropDownWrapper>
      {errorMessage ? <ErrMsg>{errorMessage}</ErrMsg> : null}
    </DWrap>
  );
};

export default DropDownC;
