import styled from "styled-components";

interface InputProps {
	padding: string | number | undefined;
	opacity: string;
}

interface Wrapper {
	border: string;
	background: string;
}
export const DWrap = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	gap: 10px;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	gap: clamp(8px, 0.8px, 12px);
	margin-bottom: 24px;
	width: 100%;
	position: relative;

	@media screen and (max-width: 600px) {
		margin-bottom: 20px;
	}
`;

export const InputContainer = styled.div<Wrapper>`
	display: flex;
	gap: 4px;
	background: ${(props: any) =>
		props.background ? props.background : "#fafafa"};
	align-items: center;
	border-radius: 9px;
	border: ${(props: any) =>
		props.border ? props.border : "1px solid #dfe2e6"};
	padding-inline: 24px;
`;

export const DenoInputContainer = styled.div<Wrapper>`
	display: flex;
	gap: 4px;
	background: ${(props: any) =>
		props.background ? props.background : "#fafafa"};
	align-items: center;
	border-radius: 9px;
	border: ${(props: any) =>
		props.border ? props.border : "1px solid #dfe2e6"};
	padding-inline: 5px;
	width: 100%;
`;

export const CheckWrap = styled.div`
	width: 100%;
	display: flex;
	gap: 10px;
	flex-flow: column;
`;
export const DeWrap = styled.div`
	width: 100px;
	display: flex;
	gap: 10px;
	flex-flow: column;
`;

export const Input = styled.input<InputProps>`
	border-radius: 3px;
	width: 85%;
	font-size: 16px;
	border: none;
	font-family: "Poppins", sans-serif;
	padding: ${(props: any) => (props.padding ? props.padding : "12px 18px")};
	opacity: ${(props: any) => props.opacity};
	background: none;
	color: black;
	outline: none;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&::placeholder {
		font-size: 14px;
		color: black;
	}

	&:focus {
		outline: 1px solid #784b99;
	}
`;
export const DInput = styled.input<InputProps>`
	border-radius: 3px;
	width: 100%;
	font-size: 16px;

	border: none;
	font-family: "Poppins", sans-serif;
	padding: ${(props: any) => (props.padding ? props.padding : "12px 18px")};
	opacity: ${(props: any) => props.opacity};
	background: none;
	color: black;
	outline: none;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&::placeholder {
		font-size: 12px;
	}

	&:focus {
		outline: 1px solid #784b99;
	}
`;
export const Show = styled.div`
	cursor: pointer;
`;

export const Error = styled.p`
	font-size: 11px;
	// margin-top: -0.5rem;
	color: red;
`;

export const Label = styled.p`
	font-weight: 600;
	font-size: 16px;
	margin: 0;
`;
export const Iconwrapper = styled.div`
	margin-right: 20px;
	@media screen and (max-width: 600px) {
		margin-right: 18px;
	}
`;

export const DropDownWrapper = styled.div`
	position: relative;
	padding: 8px 4px;
	background: #fafafa;
	/* height: 48px; */
	border-radius: 8px;
	border: 1px solid #dfe2e6;
`;
export const DropInner = styled.div`
	display: flex;
	margin: 0 !important;
	padding: 3px 5px;
	background: #fafafa;
	align-items: center;
`;
export const CheckInputBox = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
	&:checked + .checkmark {
		background-color: #70279d;
		border-color: #70279d;
	}

	&:checked + .checkmark:after {
		display: block;
	}
`;

export const LabelText = styled.span`
	margin-left: 8px;
	font-size: 14px;
`;

export const Checkmark = styled.span`
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 2px solid #70279d;
	border-radius: 4px;
	transition: background-color 0.2s, border-color 0.2s;

	&:after {
		content: "";
		position: absolute;
		display: none;
		left: 6px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
`;

export const PurpleCheckbox = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;
	position: relative;
`;
export const ShowList = styled.div`
	background: #fafafa;
	width: 90%;
	align-items: center;
	display: flex;
	gap: 10px;
	margin-top: 4px;
	padding-inline: 24px;
`;

export const ShowListIcon = styled.div`
	background: #fafafa;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10%;
	cursor: pointer;
`;

export const DropDown = styled.div`
	background: #fafafa;
	border: 1px solid #dfe2e6;
	position: absolute;
	width: 100%;
	top: 50px;
	max-height: 10.7rem;
	scroll-behavior: smooth;
	overflow-y: auto !important;
	right: 1px;
	z-index: 5;
	border-radius: 0 0 8px 8px;
`;
export const PaginatorWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
export const ListItems = styled.ul`
	display: flex;
	height: 100%;
	margin: 0px !important;
	flex-direction: column;
	padding: 0px !important;
`;

export const ListItem = styled.li`
	display: flex;
	padding: 12px 20px;
	align-items: center;
	font-size: 11px;
	border: 1px solid #dfe2e6;

	&:hover {
		background-color: #5c2684;
		cursor: pointer;
		color: white;
	}
`;

export const Item = styled.p`
	font-size: 13px;
	color: #98a1b0;
	font-family: "Poppins", sans-serif;
`;

export const MultipleItem = styled.p`
	font-size: 13px;
	color: white;
	font-family: "Poppins", sans-serif;
	background-color: #5c2684;
	padding: 2px 5px;
	border-radius: 5px;
`;
export const Items = styled.p`
	font-size: 14px;
	color: black;
	// font-family: "Poppins", sans-serif;
`;
export const DefaultItem = styled.p`
	font-size: 14px;
	color: #727474;
`;
export const OtherInput = styled.input`
	height: 80%;
	width: 80%;
	border: none;
	font-size: 14px;
	outline: none;
`;

export const InvisibleBackDrop = styled.div`
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 3;
`;

export const ErrMsg = styled.div`
	color: red;
	font-size: 12px;
	justify-content: center;
	align-items: center;
`;

export const Top = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;
