import styled from "styled-components";

export const Dashboard = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  top: 100px;
  height: 100%;
  flex: 1;
`;

export const Body = styled.div`
  display: flex;
  flex-flow: row nowrap;
  background-color: #f1e9f5;
  height: 100%;
`;

export const BodyLeft = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: none !important;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const BodyRight = styled.div`
  display: flex;
  flex-flow: column;
  flex: 4;
  width: 100%;
  padding: 24px 50px;
  gap: 32px;
  height: 100%;
  overflow-x: hidden;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
