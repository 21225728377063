import React, { useState } from "react";
import Header from "../../components/Header/Header";
import DropDownC from "../../components/PrimaryInput/dropDown";
import {
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  TopTitle,
  Top,
  TableBtn,
  TopLeftContainer,
  TopSelect,
  IconWrap,
  TopRightContainer,
  Status,
  ButtonWrap1,
  NContainer,
  TableDiv,
  ReceiptDiv,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import {
  ApprovedReportTableHeader,
  ErrorHandler,
  transactionTypes,
} from "../../utils/config";
import PrimaryTable from "../../components/Table";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  useGetReportsMutation,
  useGetTransferReportsMutation,
  useGetWithdrawalReportsMutation,
} from "../../services/reports/reportService";
import { toast } from "react-hot-toast";
import PDFGenerator from "../../components/PDFGenerator/PdfGenerator";
import { GetReceipt } from "../../services/transaction/types";
import { useGetReceiptMutation } from "../../services/transaction/transaction";
import GeneralModal from "../../components/Modal/GeneralModal";
import ReceiptView from "../../components/Receipt/Receipt";
import NotFound from "../../components/Not Found";

const DailyTransactions = () => {
  const [openTable, setOpenTable] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [reportType, setReportType] = useState("");
  const [postReports, { isLoading }] = useGetReportsMutation();
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [reqErrorMsg, setReqErrMssg] = useState<string>("");
  const [getWithdrawalReports, withdrawalMutationState] =
    useGetWithdrawalReportsMutation();
  const withdrawalLoading = withdrawalMutationState.isLoading;
  const [getTransferReports, transferMutation] =
    useGetTransferReportsMutation();
  const transferLoading = transferMutation.isLoading;
  const [arrayData, setArrayData] = useState<any>([]);
  const queryDate = new Date();
  const currentDate = queryDate.toJSON().slice(0, 10);

  //receipt
  const [transReceipt, setTransReceiptData] = useState<
    GetReceipt | undefined
  >();
  const [generatePDF, setGeneratePDF] = useState(false);
  const [transactionReceipt, setTransactionReceipt] = useState(false);
  const [getReceipt] = useGetReceiptMutation();

  const onClearFields = () => {
    setTransactionType("");
    setReportType("");
  };

  const onSubmit = async () => {
    let payload = {
      transactionType: reportType,
      startDate: currentDate,
      endDate: currentDate,
    };

    let responseData = null;

    try {
      switch (payload.transactionType) {
        case "Cash":
        case "Cheque Deposit":
          const apiResponse = await postReports(payload).unwrap();
          responseData = apiResponse?.data;
          setTransactionType("");
          onClearFields();
          let responseMessage = apiResponse?.message;
          toast.success(responseMessage);
          break;
        case "Cash Withdrawal":
        case "Cheque Withdrawal":
          const apiWithdrawalResponse = await getWithdrawalReports(
            payload
          ).unwrap();
          responseData = apiWithdrawalResponse?.data;
          setTransactionType("");
          onClearFields();
          let withdrawalResponseMessage = apiWithdrawalResponse?.message;
          toast.success(withdrawalResponseMessage);
          break;
        case "InterBank":
        case "IntraBank":
          const apiTransferResponse = await getTransferReports(
            payload
          ).unwrap();

          responseData = apiTransferResponse?.data;
          setTransactionType("");
          onClearFields();
          let transferResponseMessage = apiTransferResponse?.message;
          toast.success(transferResponseMessage);
          break;
        default:
          break;
      }

      if (responseData) {
        setArrayData(responseData);
        setOpenTable(true);
      }
    } catch (err: any) {
      setReqErrMssg(err?.data?.Message);
      //  ErrorHandler(err);
      setArrayData([]);
      onClearFields();
    }
  };

  const handleClose = () => {
    setTransactionReceipt(false);

    // setIsOpenTransModal(false);
    // setTransactionStatus(false);
  };

  const generateReceipt = async (transId: string) => {
    const data = {
      transactionType: "Withdrawal",
      transactionId: transId,
    };
    try {
      const response = await getReceipt(data).unwrap();

      if (response.statusCode === 200) {
        setTransReceiptData(response);
        toast.success(response?.message);
        // setTransactionStatus(false);
        setTransactionReceipt(true);
        setGeneratePDF(true);
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else {
        ErrorHandler(error);
      }
    }
  };

  const dataBody = arrayData?.map((item: any) => [
    <ReceiptDiv>{item.recepitNo}</ReceiptDiv>,
    item.sourceAccount,
    item.destinationAccount,
    <>
      {new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: item?.currency,
      }).format(item?.amount)}
    </>,
    item.currency.toUpperCase(),
    item.dateCreated.slice(0, 10),
    <>
      {item?.completed ? (
        <TableBtn>
          <PrimaryButton
            background={"#ffffff"}
            color={"#5c2684"}
            width={150}
            title={"Print Receipt"}
            icon={""}
            loading={false}
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={() => generateReceipt(item.transId)}
            leftIcon={false}
            border={"1px solid #5c2684"}
          />
          ,
        </TableBtn>
      ) : (
        <>
          <Status
            key={item.id}
            color={"#C43C20"}
            backgroundColor={"rgba(231, 175, 164, 0.3)"}
          >
            {"Not Approved"}
          </Status>
          ,
        </>
      )}
    </>,
  ]);

  const option = [
    {
      id: 1,
      value: 5,
    },
    {
      id: 2,
      value: 10,
    },
    {
      id: 3,
      value: 20,
    },
    {
      id: 4,
      value: 50,
    },
    {
      id: 5,
      value: 100,
    },
  ];

  const handleSelect = (e: any) => {
    const selectedOption = e.target.value;
    setItemsPerPage(selectedOption);
  };

  return (
    <>
      <Header title="Daily Transaction" />
      <ComponentWrapper>
        <Container>
          <HeaderText>Transaction History</HeaderText>
          <FieldWrap>
            <DropDownC
              value={transactionType}
              placeholder={"Select Type"}
              options={transactionTypes}
              errorMessage={""}
              label={"Transaction Type"}
              setSelectedItem={() => {}}
              setOptionValue={setReportType}
              setSelectedItems={setTransactionType}
            />
          </FieldWrap>

          <ButtonWrap1>
            <PrimaryButton
              background={"#fff"}
              color={"#5c2684"}
              width={"150px"}
              title={"Clear Fields"}
              icon={""}
              isLoading={false}
              isDisabled={reportType === ""}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={onClearFields}
              leftIcon={false}
              border={"1px solid #5c2684"}
            />
            <PrimaryButton
              background={"#5c2684"}
              color={"#fff"}
              width={"150px"}
              isDisabled={reportType === ""}
              title={"Search"}
              icon={""}
              isLoading={isLoading || transferLoading || withdrawalLoading}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={onSubmit}
              leftIcon={false}
              border={"1px solid #5c2684"}
            />
          </ButtonWrap1>
        </Container>

        {openTable && (
          <TableDiv>
            <Top>
              <TopLeftContainer>
                <TopTitle>Transaction Table</TopTitle>
              </TopLeftContainer>
              <TopRightContainer>
                {/* <PrimaryInput
              name={"search"}
              type={"text"}
              placeholder={"Search"}
              rightIcon={<></>}
              label={""}
              check={""}
              padding={""}
              leftIcon={<></>}
              password={false}
            /> */}
                Show
                <TopSelect onChange={handleSelect} value={itemsPerPage}>
                  {option?.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </TopSelect>
                Entries
              </TopRightContainer>
            </Top>

            <PrimaryTable
              header={ApprovedReportTableHeader}
              body={dataBody}
              arrOfObject={true}
              itemsPerPage={itemsPerPage}
            />
          </TableDiv>
        )}

        {reqErrorMsg && arrayData.length === 0 && (
          <NContainer>
            <NotFound notFound message={reqErrorMsg} />
          </NContainer>
        )}
      </ComponentWrapper>

      {transactionReceipt && (
        <GeneralModal general>
          <Top>
            <TopTitle></TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleClose} />
            </IconWrap>
          </Top>

          {generatePDF && (
            <PDFGenerator
              componentToRender={
                <ReceiptView
                  data={transReceipt?.data}
                  title="Withdrawal Receipt"
                />
              }
            />
          )}
        </GeneralModal>
      )}
    </>
  );
};

export default DailyTransactions;
