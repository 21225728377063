import React, { useState } from "react";
import { useGetAnalyticsQuery } from "../../../services/dashboardAnalysis/dashboardService";
import { formatCurrency } from "../../../utils";
import { TillBalance } from "../../../assets/svgComponent/TillBalanceIcon";
import { InterTransfer } from "../../../assets/svgComponent/InterTransfer";
import { ReactComponent as CloseIcon } from "../../../assets/svg/close.svg";
import { WithDrawalIcon } from "../../../assets/svgComponent/WithDrawalIcon";
import { IntraTransFerIcon } from "../../../assets/svgComponent/IntraTransferIcon";
import {
  CardContainer,
  TopTitle,
  MTop,
  IconWrap,
  FieldWrapD,
  LeftD,
  RightD,
  DetailsWrap,
  Text,
  FieldWrap,
} from "./style";
import TransactionCard from "../TransactionCard";
import { cardComponentType } from "../../../types/Types";
import { useSelector } from "react-redux";
import GeneralModal from "../../Modal/GeneralModal";

const TopCards = () => {
  const staffInfo = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo
  );
  const [openModal, setOpenModal] = useState(false);
  const username: string = staffInfo?.user?.staffName;

  const getAnalytics = useGetAnalyticsQuery({ modifiedFullName: username });

  const detail = getAnalytics?.data?.data;

  const cardData: cardComponentType[] = [
    {
      title: "Till Balance",
      amount: formatCurrency(detail?.tillBalance) ?? "0",
      img: <TillBalance />,
      count: detail?.tillCount ?? 0,
    },
    {
      title: "Deposits",
      amount: formatCurrency(detail?.totalDeposits) ?? "0",
      img: <InterTransfer />,
      count: detail?.depositCount ?? 0,
    },
    {
      title: "Withdrawal",
      amount: formatCurrency(detail?.totalWithdrawals) ?? "0",
      img: <WithDrawalIcon />,
      count: detail?.depositCount ?? 0,
    },
    {
      title: "Inter Transfer",
      amount: formatCurrency(detail?.totalInterTransfers) ?? "0",
      img: <InterTransfer />,
      count: detail?.accountWalletTransferCount ?? 0,
    },
    {
      title: "Intra Transferssss",
      amount: formatCurrency(detail?.totalIntraTransfers) ?? "0",
      img: <IntraTransFerIcon />,
      count: detail?.intraTransferCount ?? 0,
    },
  ];

  const handleClick = (type: string) => {
    if (detail?.tellerTills && type === "Till Balance") {
      setOpenModal(true);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <CardContainer>
        {cardData?.map((data: any, index: any) => (
          <TransactionCard
            key={index}
            data={data}
            width={"18%"}
            handleClick={() => handleClick(data.title as string)}
          />
        ))}
      </CardContainer>

      {openModal && (
        <GeneralModal general>
          <MTop>
            <TopTitle>Till Balance</TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleClose} />
            </IconWrap>
          </MTop>

          <FieldWrapD>
            <FieldWrap>
              {detail.tellerTills?.map((data: any, index: any) => (
                <>
                  <DetailsWrap key={index}>
                    <LeftD>
                      <Text>{data?.currency}</Text>{" "}
                    </LeftD>
                    <RightD>
                      <Text>
                        {" "}
                        {new Intl.NumberFormat("ja-JP", {
                          style: "currency",
                          currency: `${data?.currency}`,
                        }).format(data?.tillBalance)}
                      </Text>{" "}
                    </RightD>
                  </DetailsWrap>
                </>
              ))}
            </FieldWrap>
          </FieldWrapD>
        </GeneralModal>
      )}
    </>
  );
};

export default TopCards;
