import React, { useState } from "react";
import PrimaryInput from "../../components/PrimaryInput";
import { FieldWrap } from "../CashDeposit/style";
import { ModalHeaderText, UserButtonWrap } from "./styles/styles";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import PrimaryTable from "../../components/Table";

const ViewUsers = ({ body, header }: any) => {
  return (
    <section>
      <ModalHeaderText>Users in role</ModalHeaderText>
      <>
        <>
          {/* <FieldWrap> */}
          <PrimaryTable
            header={header}
            body={body}
            arrOfObject={false}
            TopComponent={false}
            itemsPerPage={5}
          />

          {/* </FieldWrap> */}
        </>
      </>
    </section>
  );
};

export default ViewUsers;
