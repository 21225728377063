import React, { useState, useCallback } from "react";
import {
  Input,
  Show,
  InputContainer,
  Error,
  Label,
  CheckWrap,
  DenoInputContainer,
  DeWrap,
  DInput,
} from "./style";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { HiOutlineEyeOff } from "react-icons/hi";
import { InputProps } from "../../types/Types";

const DenominationInput = ({
  name,
  type,
  placeholder,
  rightIcon,
  error,
  padding,
  label,
  value,
  leftIcon,
  onChange,
  password,
  disabled,
  inputMode,
  defaultValue,
  register,
  readOnly,
  maxLength,
}: InputProps) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);

  const onActive = useCallback(() => {
    setActive(true);
  }, []);

  const onActiveFalse = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <>
      <DeWrap>
        <DenoInputContainer
          border={active ? "1px solid #784b99" : "1px solid #dfe2e6"}
          onFocus={onActive}
          onBlur={onActiveFalse}
          background={disabled ? "#eeeeee" : ""}
        >
          <DInput
            type={"number"}
            name={name}
            value={value}
            opacity={disabled ? "0.3" : ""}
            placeholder={placeholder}
            padding={"8px"}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            readOnly={readOnly}
            maxLength={maxLength}
            min={0}
          />
        </DenoInputContainer>
      </DeWrap>
    </>
  );
};

export default DenominationInput;
