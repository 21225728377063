import styled from "styled-components";

interface StatusProp {
  color: string;
  backgroundColor: string;
}


export const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const FlexWrap = styled.div`
  display: flex;
  padding: 0.5rem;
  width:100%;
  gap: 1rem !important;
  flex-direction: column;
  div{
    display: flex;
    gap: 1rem;
  }
`
export const InputWidth = styled.div`
  width: 45%;
  padding-left: 1rem;
  
`
export const TableDiv = styled.div`
 // margin-top: 5rem;
`
export const Top = styled.div`
  position: relative;
  margin-bottom: 10rem !important;
  top: 80% !important;
  width: 100%  !important;
  left: 17% !important;
`
export const FlexWrapper = styled.div`

display: grid;
grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
grid-gap: 10px;
  /* display: flex;
  padding: 0.5rem;
  gap: 1rem !important;
  flex-wrap: wrap; */
`

export const Status = styled.div<StatusProp>`
  background-color: ${(props) => props.backgroundColor} !important;
  color: ${(props) => props.color};
  text-align: center;
  border-radius: 12px;
  padding: 4px 8px;
`;
export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding-left: 1rem;
`
export const DataDiv = styled.div`
  word-wrap: break-word;
`
export const TopWrapper = styled.div`
  display: flex;
  
`
export const LoaderDiv = styled.div`
  position: relative;
  left: 50%;
  top: 40%;
`

export const HeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

export const Container = styled.div`
  border-radius: 20px;
  background: #fff;
  padding: 10px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 16px;
`;

export const FieldWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const ModalHeaderText = styled.h3`
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 24px 0;
  white-space: nowrap;
`;

export const UserButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 20px;
`;

