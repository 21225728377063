import React from "react";

export const TillBalance = ({ iconColor = "#FCBF24", iconBg = "#FFE198" }) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24.5" r="24.5" fill={iconBg} fill-opacity="0.6" />
      <path
        d="M33 28.25H27C26.59 28.25 26.25 27.91 26.25 27.5C26.25 27.09 26.59 26.75 27 26.75H33C33.41 26.75 33.75 27.09 33.75 27.5C33.75 27.91 33.41 28.25 33 28.25Z"
        fill={iconColor}
      />
      <path
        d="M33 32.25H27C26.59 32.25 26.25 31.91 26.25 31.5C26.25 31.09 26.59 30.75 27 30.75H33C33.41 30.75 33.75 31.09 33.75 31.5C33.75 31.91 33.41 32.25 33 32.25Z"
        fill={iconColor}
      />
      <path
        d="M34 20.52V15.98C34 14.57 33.36 14 31.77 14H27.73C26.14 14 25.5 14.57 25.5 15.98V20.51C25.5 21.93 26.14 22.49 27.73 22.49H31.77C33.36 22.5 34 21.93 34 20.52Z"
        fill={iconColor}
      />
      <path
        d="M22.5 20.52V15.98C22.5 14.57 21.86 14 20.27 14H16.23C14.64 14 14 14.57 14 15.98V20.51C14 21.93 14.64 22.49 16.23 22.49H20.27C21.86 22.5 22.5 21.93 22.5 20.52Z"
        fill={iconColor}
      />
      <path
        d="M22.5 31.77V27.73C22.5 26.14 21.86 25.5 20.27 25.5H16.23C14.64 25.5 14 26.14 14 27.73V31.77C14 33.36 14.64 34 16.23 34H20.27C21.86 34 22.5 33.36 22.5 31.77Z"
        fill={iconColor}
      />
    </svg>
  );
};
