import React, { useEffect, useState, useMemo, useCallback } from "react";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as ExIcon } from "../../assets/svg/f.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { useForm } from "react-hook-form";
import { BuaInfoError, BuaInfoProp, DeProp } from "../../types/Types";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { Oval } from "react-loading-icons";
import {
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  ButtonWrap1,
  ButtonWrap,
  Status,
  LoaderContainer,
  Text,
  ContainerDepo,
  LeftContainerDepo,
  RightContainerDepo,
  Input,
  Radio,
  LeftD,
  RightD,
  LeftDen,
  RightDen,
  FieldWrapDen,
  DHeader,
  FieldWrapD,
  DetailsWrap,
  DetailsWrapDen,
  DenominationContainer,
  TopContainer,
  DenominationWrap,
  Top,
  TopTitle,
  IconWrap,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import {
  collectionIdType,
  collectionType,
  buaSchema,
  buaInfoData,
  BuaErrorStruct,
  currencyType,
  denominationList,
  ErrorHandler,
} from "../../utils/config";
import TopCards from "../../components/Cards/TopCards";
import SpecialModal from "./specialModal";
import {
  useGetCollectionListMutation,
  usePostBuaPaymentMutation,
  useValidateIdMutation,
} from "../../services/collection/collection";
import { useGetAccDetailsMutation } from "../../services/transaction/transaction";
import {
  CollectionDatum,
  PostBUAPayload,
} from "../../services/collection/type";
import DenominationInput from "../../components/PrimaryInput/denInput";
import { convertAmountToWords } from "../../utils/amountWriter";
import { useSelector } from "react-redux";
import { calculateTotalPrice } from "../../utils";

const SpecialCollections = () => {
  const branchCode = useSelector(
    (state: any) => state?.StaffDataReducer?.userDetails?.data?.branchCode
  );
  const user = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo?.user?.displayName
  );

  // global variables
  let totalSelectedItem: any = {};
  let productType: any[] = [];
  let collectionItem: any[] = [];

  //states
  const [totalCollection, updateTotalCollection] = useState<any>();
  const [collectionTopDataStruct, setCollectionTopDataStruct] = useState<any>();
  const [selectedType, setSelectedType] = useState<string>("");
  const [isFectched, setIsFectched] = useState<boolean>(false);
  const [newDenomination, setNewDenomination] = useState<DeProp[]>();
  const [initialList, setInitialList] = useState(denominationList);
  const [modalDetails, setModalDetails] = useState<PostBUAPayload>();
  const [selecteCurrencyType, setSelecteCurrencyType] = useState("");
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [defaultDenomination, setDefaultDenomination] =
    useState<boolean>(false);
  const [openTransactionModal, setOpenTransactionModal] =
    useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [selectedProductType, setSelectedProductType] = useState<string>("");
  const [selectedCollectionItem, setSelectedCollectionItem] =
    useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [openFields, setOpenFields] = useState<boolean>(false);
  const [isStatus, setStatus] = useState<string>("");
  const [accName, setName] = useState<string>("");
  const [customerInfo, setCustomerInfo] = useState<BuaInfoProp>(buaInfoData);
  const [error, setError] = useState<BuaInfoError>(BuaErrorStruct);
  const [orderID, setOrderID] = useState<string>("");
  const currentDate = new Date();
  const [selectedTopCollectionItem, setSelectedTopCollectionItem] =
    useState<string>("");

  //useForm
  const { register, setValue } = useForm({
    resolver: yupResolver(buaSchema),
  });

  // endpoints
  const [getCollections] = useGetCollectionListMutation();
  const [postBuaPayment, { isLoading }] = usePostBuaPaymentMutation();
  const [getAccount, mutationState] = useGetAccDetailsMutation();
  const [validateId] = useValidateIdMutation();

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerInfo((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const updateDenomination = (index: any, quantity: number) => {
    const updatedDenomination = [...denominationList];
    updatedDenomination[index].quantity = quantity;
    updatedDenomination[index].amount =
      quantity * updatedDenomination[index].denomination;

    updatedDenomination.forEach((item: DeProp) => {
      if (item.amount !== 0) {
        const newList: DeProp[] = denominationList.filter(
          (denomination) => denomination.amount !== 0
        );
        setNewDenomination(newList);
      }
    });
  };

  useEffect(() => {
    const getTotal = calculateTotalPrice(newDenomination as DeProp[]);
    setTotalAmount(getTotal as number);
  }, [newDenomination]);

  const getAllCollections = useCallback(async () => {
    try {
      const response = await getCollections({
        collectionType: "SPECIAL_COLLECTIONS",
      }).unwrap();

      if (response?.statusCode === 200) {
        const collectionData = response?.data;
        setCollectionTopDataStruct(collectionData);
        const totalList = collectionData?.map(
          (item: CollectionDatum, index: number) => ({
            id: index + 1,
            value: item?.name,
            label: item?.name,
          })
        );
        updateTotalCollection(totalList);
      }
    } catch (error) {
      toast.error("Error loading collection items");
    } finally {
      setIsFectched(true);
    }
  }, [getCollections]);

  if (selectedTopCollectionItem) {
    const newList = collectionTopDataStruct?.find(
      (item: any) => item?.name === selectedTopCollectionItem
    );

    totalSelectedItem = newList;

    const itemList = Object.keys(newList?.items as object)?.map(
      (item: string, index: number) => ({
        id: index + 1,
        value: item,
        label: item,
      })
    );

    collectionItem = itemList;
  }

  if (totalSelectedItem?.name === selectedTopCollectionItem) {
    const findItem = Object.keys(totalSelectedItem?.items as object)?.find(
      (item: string) => item === selectedCollectionItem
    )
      ? totalSelectedItem?.items[selectedCollectionItem]
      : [];

    if (Array.isArray(findItem)) {
      const typeList = findItem?.map((item: string, index: number) => ({
        id: index + 1,
        value: item,
        label: item,
      }));

      productType = typeList;
    }
  }

  useEffect(() => {
    if (!isFectched) {
      getAllCollections();
    }
  }, [getAllCollections, isFectched]);

  // get account details
  const handleCFetchAccount = async (e: any) => {
    try {
      const { value } = e.target;

      if (value.length === 10) {
        try {
          const response = await getAccount({
            accountNo: value,
          }).unwrap();
          setOpenFields(true);

          setAccountNumber(value);
          const firstName =
            response?.firstName !== null ? response?.firstName : "";
          const lastName =
            response?.lastName !== null ? response?.lastName : "";

          setStatus(response?.status as string);
          const beneficiaryName = `${firstName} ${lastName}`;
          setName(beneficiaryName);
          setValue("debitAccountName", beneficiaryName, {
            shouldValidate: true,
          });
        } catch (error: any) {
          setOpenFields(false);
          ErrorHandler(error);
        }
      } else {
        setOpenFields(false);
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  // verify orderID
  const onHandleVerifyID = async (e: any) => {
    try {
      const { value } = e.target;

      if (value.length === 3) {
        try {
          const response = await validateId({
            orderid: value,
            category: selectedCollectionItem,
          }).unwrap();

          if (response?.statusCode === 200) {
            setOrderID(value);
            setValue("debitAccountName", response?.data as string, {
              shouldValidate: true,
            });
            toast.success(response?.message as string);
          }
        } catch (error: any) {
          setOpenFields(false);
          ErrorHandler(error);
        }
      } else {
        setOpenFields(false);
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const payload = useMemo(
    () => ({
      orderid: orderID,
      amount: parseFloat(amount),
      currency: "NGN",
      tellerNumber: customerInfo?.tellerNumber,
      depositorName: accName ? accName : customerInfo?.depositorName,
      depositorPhoneNo: customerInfo?.depositorPhoneNo,
      debitAccountNumber: accountNumber,
      narration: "string",
      remark: "string",
      denomination: newDenomination,
      productType: selectedProductType,
    }),
    [
      accountNumber,
      customerInfo,
      accName,
      selectedProductType,
      amount,
      orderID,
      newDenomination,
    ]
  );

  const submitForm = async () => {
    try {
      const response = await postBuaPayment({
        body: payload,
        category: selectedCollectionItem,
      }).unwrap();
      if (response?.statusCode === 200) {
        toast.success(response?.message as string);
        setOpenTransactionModal(false);
        window.location.reload();
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  //validation
  const handleOpenTransactionModal = useCallback(() => {
    const { depositorName, tellerNumber, depositorPhoneNo } = customerInfo;

    const updatedError: any = {};

    if (!accountNumber) {
      updatedError.accountNumber = "Account number is required";
    }
    if (!depositorPhoneNo || depositorPhoneNo.length !== 11) {
      updatedError.depositorPhoneNo = "Phone is required, 11 digits";
    }
    if (!amount) {
      updatedError.amount = "Amount is required";
    }
    if (!tellerNumber) {
      updatedError.tellerNumber = "Teller Number is required";
    }
    if (!depositorName && !accName) {
      updatedError.depositorName = "Depositor's name is required";
    }
    if (!selectedType) {
      updatedError.idType = "Please select ID type";
    }
    if (!selecteCurrencyType) {
      updatedError.currencyType = "Please select currency type";
    }
    if (selectedType === "Order ID" && !orderID) {
      updatedError.orderId = "Order ID is required";
    }

    if (totalAmount !== parseFloat(amount)) {
      updatedError.match = "Not match";
      toast.error("Ensure amount and total amount in denominations are equal");
    }
    setError(updatedError);

    if (Object.keys(updatedError).length === 0) {
      setModalDetails(payload);
      setOpenTransactionModal(true);
      setIsDisabled(true);
    }
  }, [payload, customerInfo, accountNumber, totalAmount, amount]);

  //clear fields
  const closeReceipt = () => {
    setCustomerInfo({
      ...customerInfo,
      depositorPhoneNo: "",
      depositorName: "",
      remark: "",
      narration: "",
      tellerNumber: "",
    });
    setAccountNumber("");
    setAmount("");
    setNewDenomination(denominationList);
    setSelectedProductType("");
    setSelectedCollectionItem("");
    setSelectedTopCollectionItem("");
    setSelectedType("");
    setOpenFields(false);
    setName("");
  };

  //handle radio click
  const handleRadioChange = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setIsDisabled(false);
    }
  };

  //close modal
  const handleClose = () => {
    setOpenTransactionModal(false);
  };

  return (
    <>
      <Header title="Special Collection" />
      <ComponentWrapper>
        <TopCards />

        <Container>
          <HeaderText>Collection Details</HeaderText>
          <FieldWrap>
            <DropDownC
              value={selectedTopCollectionItem}
              placeholder={"Select Item"}
              options={totalCollection}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
              errorMessage={""}
              label={"Collection Item"}
              setSelectedItem={setSelectedTopCollectionItem}
            />
          </FieldWrap>
          <FieldWrap>
            <DropDownC
              value={selectedCollectionItem}
              placeholder={"Select Item"}
              options={collectionItem}
              errorMessage={""}
              label={"Category"}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
              setSelectedItem={setSelectedCollectionItem}
            />
            <DropDownC
              value={selectedProductType}
              placeholder={"Select Item"}
              options={productType}
              errorMessage={""}
              label={"Product Type"}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
              setSelectedItem={setSelectedProductType}
            />
          </FieldWrap>
        </Container>

        <ContainerDepo>
          <LeftContainerDepo>
            <HeaderText>Customer Details</HeaderText>

            <DropDownC
              value={selectedType}
              placeholder={"ID Type"}
              options={collectionIdType}
              errorMessage={error?.idType}
              label={"ID Type"}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
              setSelectedItem={setSelectedType}
            />
            {selectedType === "No ID" ? (
              <>
                <PrimaryInput
                  name="fullName"
                  type="text"
                  placeholder="Full Name"
                  rightIcon={<></>}
                  // error={error.fullName}
                  label="Full Name"
                  check={""}
                  padding={""}
                  leftIcon={<UserIcon />}
                  // onChange={onHandleChange}
                  // value={accountOwner ? withdrawal : customerInfo?.depositorName}
                />
              </>
            ) : (
              <PrimaryInput
                name="orderId"
                type="text"
                placeholder="ID"
                rightIcon={<></>}
                error={error.orderId}
                label="Please provide ID"
                check={""}
                padding={""}
                leftIcon={<UserIcon />}
                onChange={onHandleVerifyID}
                value={undefined}
              />
            )}

            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount  *"
              check={""}
              padding={""}
              leftIcon={<ExIcon />}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setAmount(value);
              }}
              value={amount}
            />
            <DropDownC
              value={selecteCurrencyType}
              placeholder="Currency Type"
              options={currencyType}
              errorMessage={error?.currencyType}
              label={"Currency Type"}
              setSelectedItem={setSelecteCurrencyType}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />
            <PrimaryInput
              name="tellerNumber"
              type="text"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error?.tellerNumber}
              label="Teller Number"
              check={""}
              padding={""}
              value={undefined}
              leftIcon={<ExIcon />}
              onChange={onHandleChange}
            />
            <PrimaryInput
              name="accountNumber"
              maxLength={10}
              type="number"
              inputMode="numeric"
              placeholder="Debit Account"
              rightIcon={<></>}
              error={error?.accountNumber}
              label="Debit Account"
              check={""}
              padding={""}
              leftIcon={<SearchIcon />}
              onChange={handleCFetchAccount}
              value={undefined}
              register={register}
            />
            {mutationState.isLoading ? (
              <LoaderContainer>
                <Oval stroke="#5C2684" fill="white" width={20} height={20} />
              </LoaderContainer>
            ) : (
              <>
                {openFields && (
                  <FieldWrap>
                    <Text>{accName}</Text>
                    <Status
                      color={isStatus === "ACTIVE" ? "#01B272" : "#C43C20"}
                      backgroundColor={
                        isStatus === "ACTIVE"
                          ? "rgba(1, 178, 114, 0.2)"
                          : "rgba(231, 175, 164, 0.3)"
                      }
                    >
                      {isStatus === "ACTIVE" ? "Active" : "Inactive"}
                    </Status>
                  </FieldWrap>
                )}
              </>
            )}
            <PrimaryInput
              name="depositorName"
              type="text"
              placeholder="Depositor's Name"
              rightIcon={<></>}
              error={error.depositorName}
              label="Depositor First/Last Name  *"
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={onHandleChange}
              value={accName ? accName : customerInfo?.depositorName}
            />

            <PrimaryInput
              name="depositorPhoneNo"
              maxLength={11}
              type="number"
              value={undefined}
              inputMode="numeric"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error?.depositorPhoneNo}
              label="Depositor's Phone Number"
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={onHandleChange}
            />

            <ButtonWrap>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={150}
                title={"Post Transaction"}
                icon={""}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={handleOpenTransactionModal}
                leftIcon={false}
                border={""}
              />
            </ButtonWrap>
          </LeftContainerDepo>

          <RightContainerDepo>
            <DenominationContainer>
              <TopContainer>
                <DHeader>Denomination</DHeader>
                <DHeader>Quantity</DHeader>
                {/* <DHeader>Amt</DHeader> */}
              </TopContainer>
              {initialList.map((denomination: any, index: number) => (
                <DenominationWrap key={denomination.denomination}>
                  <Text>{denomination.denomination}</Text>
                  <DenominationInput
                    name={""}
                    type={""}
                    placeholder={""}
                    rightIcon={<></>}
                    label={""}
                    value={denomination.quantity}
                    onChange={(e) =>
                      updateDenomination(index, parseInt(e.target.value))
                    }
                    check={""}
                    padding={""}
                    leftIcon={<></>}
                  />{" "}
                  {/* <Text>{1000*thousand}</Text> */}
                </DenominationWrap>
              ))}
            </DenominationContainer>

            <FieldWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Total Amount:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {selecteCurrencyType}{" "}
                    {Number.isNaN(totalAmount) ? 0 : totalAmount}.00
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Amount in words:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>
                    {convertAmountToWords(totalAmount ? totalAmount : 0)}{" "}
                    {selecteCurrencyType === "NGN"
                      ? "Naira"
                      : selecteCurrencyType === "GBP"
                      ? "Pound Sterling"
                      : "Dollar"}
                  </Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Transaction Date:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{currentDate.toJSON().slice(0, 10)}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Branch Code:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{branchCode}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Paid By:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{accName ? accName : customerInfo?.depositorName}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
              <DetailsWrapDen>
                <LeftDen>
                  <Text>Teller:</Text>{" "}
                </LeftDen>
                <RightDen>
                  <Text>{user}</Text>{" "}
                </RightDen>
              </DetailsWrapDen>
            </FieldWrapDen>
          </RightContainerDepo>
        </ContainerDepo>
      </ComponentWrapper>

      {openTransactionModal && (
        <SpecialModal
          handleClose={handleClose}
          selectedType={selectedType}
          orderID={orderID}
          accountNumber={accountNumber}
          modalDetails={modalDetails}
          handleRadioChange={handleRadioChange}
          isLoading={isLoading}
          isDisabled={isDisabled}
          submitForm={submitForm}
        />
      )}
    </>
  );
};

export default SpecialCollections;
