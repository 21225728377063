import React from "react";
import { textProps } from "../../types/Types";
import { CheckWrap, Input, InputContainer, Label, Error } from "./style";

const TextArea: React.FC<textProps> = ({
  label,
  register,
  name,
  padding,
  placeholder,
  onChange,
  value,
  error,
}) => {
  return (
    <CheckWrap>
      <Label>{label}</Label>
      <InputContainer>
        {register ? (
          <Input
            name={name}
            padding={padding}
            placeholder={placeholder}
            {...register(name)}
          />
        ) : (
          <Input
            name={name}
            padding={padding}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        )}
      </InputContainer>
      <Error>{error}</Error>
    </CheckWrap>
  );
};

export default TextArea;
