import React from "react";

export const WalletTransferIcn = ({
  iconBg = "#FFE198",
  iconColor = "#FEB604",
}) => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24.5" r="24.5" fill={iconBg} fill-opacity="0.6" />
      <path
        d="M33.97 29.08C33.73 31.75 31.8 33.5 29 33.5H20C17.24 33.5 15 31.26 15 28.5V21.5C15 18.78 16.64 16.88 19.19 16.56C19.45 16.52 19.72 16.5 20 16.5H29C29.26 16.5 29.51 16.51 29.75 16.55C32.14 16.83 33.76 18.5 33.97 20.92C34 21.21 33.76 21.45 33.47 21.45H31.92C30.96 21.45 30.07 21.82 29.43 22.48C28.67 23.22 28.29 24.26 28.38 25.3C28.54 27.12 30.14 28.55 32.04 28.55H33.47C33.76 28.55 34 28.79 33.97 29.08Z"
        fill={iconColor}
      />
      <path
        d="M35.0002 23.9692V26.0292C35.0002 26.5792 34.5602 27.0292 34.0002 27.0492H32.0402C30.9602 27.0492 29.9702 26.2592 29.8802 25.1792C29.8202 24.5492 30.0602 23.9592 30.4802 23.5492C30.8502 23.1692 31.3602 22.9492 31.9202 22.9492H34.0002C34.5602 22.9692 35.0002 23.4192 35.0002 23.9692Z"
        fill={iconColor}
      />
    </svg>
  );
};
