import React from "react";
import Header from "../../components/Header/Header";
import { ComponentErrorBody, ComponentTitle, ComponentWrapper } from "./style";
import { ReactComponent as CloseIcn } from "../../assets/images/close-square.svg";

const Remittance = () => {
  return (
    <>
      <Header title="TSA Remittance" />
      <ComponentWrapper>
        <ComponentTitle>Manual Notification</ComponentTitle>
        <ComponentErrorBody>
          <CloseIcn />
          <ComponentTitle>Access Denied</ComponentTitle>
          <p>Contact Support to grant you either checker/marker access</p>
        </ComponentErrorBody>
      </ComponentWrapper>
    </>
  );
};

export default Remittance;
