import React from "react";
import { handleLogout } from "../../utils";
import PrimaryButton from "../Buttons/Button";
import { BtnHolder, Container, Content, ModalTexts, BodyText } from "./style";

interface InactiveProps {
  handleCancel: () => void;
  counter: number;
}
const InactiveContent: React.FC<InactiveProps> = ({
  handleCancel,
  counter,
}) => {
  return (
    <Container>
      <Content>
        <ModalTexts>You have been idle for 10mins!</ModalTexts>
        <BodyText>
          You are about to force log off your session in {counter} sec. Press
          cancel to stay ?
        </BodyText>
      </Content>
      <BtnHolder>
        <PrimaryButton
          background={"#ffffff"}
          color={"#5c2684"}
          width={150}
          title={"Cancel"}
          icon={""}
          loading={false}
          leftIconName={<></>}
          rightIconName={<></>}
          onClick={handleCancel}
          leftIcon={false}
          border={"solid 1px #5c2684"}
        />
        <PrimaryButton
          background={"#5c2684"}
          color={"#fff"}
          width={150}
          title={"Logout"}
          icon={""}
          loading={false}
          leftIconName={<></>}
          rightIconName={<></>}
          onClick={handleLogout}
          leftIcon={false}
          border={""}
        />
      </BtnHolder>
    </Container>
  );
};

export default InactiveContent;
