import { useCallback, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import GeneralModal from "../../components/Modal/GeneralModal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { HeaderComponent } from "../BioWithdrawal/style";
import {
  HeaderText,
  FieldWrap,
  CardContainer,
  ComponentWrapper,
  Container,
  InputDiv,
  Instruction,
  Top,
  TopTitle,
  IconWrap,
  ButtonWrap,
  FieldWrapD,
  ButtonWrapLeft,
  Text,
  DetailsWrap,
  LeftD,
  RightD,
} from "./style";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ReactComponent as SuccessIcon } from "../../assets/svg/transactionSuccess.svg";
import { ReactComponent as FailedIcon } from "../../assets/svg/transFailed.svg";
import { useSelector } from "react-redux";
import { useGetAnalyticsQuery } from "../../services/dashboardAnalysis/dashboardService";
import {
  IntraBankDeposit,
  useGetAccDetailsMutation,
  useGetReceiptMutation,
  usePostDepositMutation,
  usePostIntraBankMutation,
  useViewMandateMutation,
} from "../../services/transaction/transaction";
import {
  currencyType,
  depositSchema,
  ErrorHandler,
  withdrawalType,
} from "../../utils/config";
import Header from "../../components/Header/Header";
import TransactionCard from "../../components/Cards/TransactionCard";
import { cardComponentType } from "../../types/Types";
import { formatCurrency } from "../../utils";
import DateComponent from "../../components/Date/Date";
import PrimaryButton from "../../components/Buttons/Button";
import { convertAmountToWords } from "../../utils/amountWriter";
import Modal from "../../components/Modal/CustomModal";
// import ReceiptView from "../CashWithdrawals/Receipt";
import { KeyValueMapping } from "../CashWithdrawals/ViewTransDetails";
import PDFGenerator from "../../components/PDFGenerator/PdfGenerator";
import TopCards from "../../components/Cards/TopCards";
import { GetReceipt } from "../../services/transaction/types";
import PuffLoader from "../../components/Loader";

const FcmbToFcmb = () => {
  const [getReceipt] = useGetReceiptMutation();
  const [error, setError] = useState<{
    amount: string;
    narration?: string;
    currency: string;
    beneAccNum: string;
    accountNumber: string;
    selectDate: string;
    phoneNumber: string | undefined;
  }>({
    amount: "",
    narration: "",
    currency: "",
    phoneNumber: "",
    selectDate: "",
    beneAccNum: "",
    accountNumber: "",
  });
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(depositSchema),
  });
  const staffInfo = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo
  );
  const [transStatus, setTransStatus] = useState(false);
  const [transReceipt, setTransReceiptData] = useState<
    GetReceipt | undefined
  >();
  const branchcode = useSelector(
    (state: any) => state?.StaffDataReducer?.userDetails?.data?.branchCode
  );
  const currentDate = new Date();
  const [transId, setTransId] = useState<string>("");
  const [result, setResult] = useState<{
    statusCode: number;
    Message: string;
    message?: string;
    data: any;
  }>({
    statusCode: 0,
    Message: "",
    message: "",
    data: null,
  });
  const [generatePDF, setGeneratePDF] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [postIntraBank, { isLoading }] = usePostIntraBankMutation();
  const [isOpenTransModal, setIsOpenTransModal] = useState(false);
  const [beneName, setBeneName] = useState("");
  const [selectedInstrumentType, setInstrumentType] = useState("");
  const [getAccount, mutationState] = useGetAccDetailsMutation();
  const [imageData, setImageData] = useState("");
  const [openMandate, setOpenMandate] = useState(false);
  const accFetchLoading = mutationState.isLoading;
  const [chequeNo, setChequeNo] = useState(0);
  const [openFields, setOpenFields] = useState(false);
  const [beneOpenFields, setBeneOpenFields] = useState(false);
  const bankUser = staffInfo?.user?.displayName ?? ";";
  const [accountNumber, setAccountNumber] = useState("");
  const [narration, setNarration] = useState("");
  const [transactionReceipt, setTransactionReceipt] = useState(false);
  const [selectDate, setSelectedDate] = useState(
    currentDate.toJSON().slice(0, 10).toString()
  );
  const [accountName, setAccountName] = useState("");
  const [amount, setAmount] = useState(0);
  const [phoneNumber, setPhone] = useState("");
  const [beneAccNum, setBeneAccNum] = useState("");
  const [viewMandate, mandateMutationState] = useViewMandateMutation();
  const username = staffInfo?.user?.displayName;
  const getAnalytics = useGetAnalyticsQuery(username);
  const [currency, setCurrency] = useState("");

  const formatAmount = (currency: string, amount: number) => {
    if (currency === "NGN") {
      return `${convertAmountToWords(amount)} Naira`;
    }
    if (currency === "USD") {
      return `${convertAmountToWords(amount)} Dollars`;
    }
    if (currency === "GBP") {
      return `${convertAmountToWords(amount)} Pounds`;
    }
    if (currency === "EUR") {
      return `${convertAmountToWords(amount)} Euros`;
    }
  };
  const [activeTrack, setActiveTrack] = useState<
    null | "customer" | "beneficiary"
  >(null);
  useEffect(() => {
    const handleCFetchAccount = async () => {
      try {
        if (accountNumber.length === 10) {
          setActiveTrack("customer");
          const response = await getAccount({
            accountNo: accountNumber,
          }).unwrap();

          setOpenFields(true);
          const beneficiaryName = `${response?.firstName} ${response?.middleName} ${response?.lastName}`;
          setValue("accountName", beneficiaryName, {
            shouldValidate: true,
          });
          setAccountName(beneficiaryName);
          setValue("depositType", response?.customerType as string, {
            shouldValidate: true,
          });
          setValue("bvn", response?.bvn as string, {
            shouldValidate: true,
          });
          setValue(
            "customerAccountDescription",
            response?.accountType as string,
            {
              shouldValidate: true,
            }
          );
          setValue("availableBalance", response?.availableBalance as any, {
            shouldValidate: true,
          });
          setValue("schemaCode", response?.schemeCode as string, {
            shouldValidate: true,
          });
        }
      } catch (error: any) {
        setOpenFields(false);
        ErrorHandler(error);
      } finally {
        setActiveTrack(null);
      }
    };
    handleCFetchAccount();
  }, [accountNumber, getAccount, setValue]);
  const generateReceipt = async () => {
    const data = {
      transactionType: "Deposit",
      transactionId: transId,
    };
    try {
      const response = await getReceipt(data).unwrap();
      if (response.statusCode === 200) {
        setTransReceiptData(response?.data);
        setTransStatus(false);
        setTransactionStatus(false);
        setTransactionReceipt(true);
        setGeneratePDF(true);
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else {
        toast.error(error?.data?.title);
      }
    }
  };
  useEffect(() => {
    const handleBFetchAccount = async () => {
      //setIsApiLoading(true)
      try {
        if (beneAccNum.length === 10) {
          setActiveTrack("beneficiary");
          const response = await getAccount({
            accountNo: beneAccNum,
          }).unwrap();

          setBeneOpenFields(true);
          const beneficiaryName = `${response?.firstName} ${response?.middleName} ${response?.lastName}`;
          setValue("beneficiaryAccountName", beneficiaryName as string, {
            shouldValidate: true,
          });
          setBeneName(beneficiaryName);
          setValue("beneficiaryDepositType", response?.customerType as string, {
            shouldValidate: true,
          });
          setValue("beneficiaryBvn", response?.bvn as string, {
            shouldValidate: true,
          });
          setValue(
            "beneficiaryAccountDescription",
            response?.accountType as string,
            {
              shouldValidate: true,
            }
          );
          setValue(
            "beneficiaryAvailableBalance",
            response?.availableBalance as any,
            {
              shouldValidate: true,
            }
          );
          setValue("beneficiarySchemaCode", response?.schemeCode as string, {
            shouldValidate: true,
          });
          //setIsApiLoading(false)
        } else {
        }
      } catch (error: any) {
        //setIsApiLoading(false)
        setBeneOpenFields(false);
        ErrorHandler(error);
      } finally {
        setActiveTrack(null);
      }
    };
    handleBFetchAccount();
  }, [beneAccNum, getAccount, setValue]);

  const onOpenTransDetail = useCallback(() => {
    const updatedError: any = {};
    if (!narration) {
      updatedError.narration = "Field can not be blank";
    }
    if (!phoneNumber || phoneNumber.length < 11) {
      updatedError.phoneNumber = "Phone is required, 11 digits";
    }
    if (!beneAccNum) {
      updatedError.beneAccNum = "Required";
    }
    if (!accountNumber) {
      updatedError.accountNumber = "Required";
    }
    if (!currency) {
      updatedError.currency = "Select currency";
    }
    if (!amount || amount === 0) {
      updatedError.amount = "Amount is required";
    }

    if (!selectDate) {
      updatedError.selectDate = "Date is required";
    }

    setError(updatedError);
    setIsOpenTransModal(Object.keys(updatedError).length === 0);
  }, [
    accountNumber,
    amount,
    beneAccNum,
    currency,
    narration,
    phoneNumber,
    selectDate,
  ]);
  const onSubmit = async () => {
    let payload: IntraBankDeposit = {
      isOwner: true,
      currency: currency,
      narration: narration,
      remark: narration,
      chequeRefNo: "string",
      instrumentType: selectedInstrumentType,
      phoneNumber: phoneNumber,
      benefactorAccountNumber: accountNumber,
      beneficiaryAccountNumber: beneAccNum,
      amount: amount,
      instrumentDate: selectDate,
    };
    onOpenTransDetail();
    try {
      const response = await postIntraBank(payload).unwrap();
      setResult(response);
      setTransactionStatus(true);
      if (response) {
        setTransId(response?.data?.tran_Id);
        setIsOpenTransModal(false);
      }
    } catch (err: any) {
      setTransactionStatus(true);
      setResult(err);
      if (err) {
        setIsOpenTransModal(false);
      }
    }
  };
  const closeReceipt = () => {
    setCurrency("");
    setNarration("");
    setPhone("");
    setAmount(0);
    setAccountNumber("");
    setBeneAccNum("");
    setTransactionStatus(false);
    setTransactionReceipt(false);
  };
  const dataReceipt = {
    Sender: accountName,
    "Sender's Account Number": accountNumber,
    Beneficiary: beneName,
    "Beneficiary Account Number": beneAccNum,
    "Phone Number": phoneNumber,
    Amount: amount,
    "Branch Code": branchcode,
    Currency: currency,
    Narration: narration,
    Date: selectDate,
  };

  const allowedTypes = ["CHQ", "LCHQ", "CMS"];

  const handleViewMandate = async () => {
    const value = {
      accountNumber: accountNumber,
    };

    const response = await viewMandate(value).unwrap();
    if (response?.statusCode === 200) {
      toast.success(response.message);
      if (response?.data !== null) {
        const imageString: string | undefined = response?.data?.images;
        setImageData(imageString as string);
        setOpenMandate(true);
      } else {
        setImageData("");
        setOpenMandate(false);
      }
    }
  };

  const handleCloseMandate = () => {
    setOpenMandate(false);
  };

  return (
    <>
      <Header title="FCMB-to-FCMB" />
      <ComponentWrapper>
        <TopCards />
        <Container>
          <HeaderComponent>
            <HeaderText>Customer Information</HeaderText>
            <div style={{ marginTop: "24px", width: "338px" }}>
              <PrimaryInput
                name="accountNumber"
                type="text"
                rightIcon={<></>}
                placeholder="Enter Account Number"
                label="Account Number"
                check={""}
                error={error?.accountNumber}
                padding={""}
                leftIcon={<SearchIcon />}
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
                register={register}
              />
            </div>
          </HeaderComponent>
          {activeTrack === "customer" && accFetchLoading ? (
            <PuffLoader />
          ) : openFields && accountNumber.length === 10 ? (
            <>
              <FieldWrap>
                <PrimaryInput
                  name="accountName"
                  type={"text"}
                  placeholder="Account Name"
                  rightIcon={<></>}
                  error={undefined}
                  label="Account Name"
                  check={""}
                  padding={""}
                  disabled={true}
                  leftIcon={<InputClose />}
                  onChange={() => {}}
                  value={undefined}
                  register={register}
                />
                <PrimaryInput
                  name="depositType"
                  type={"text"}
                  placeholder="customerType"
                  rightIcon={<></>}
                  label="Customer Type"
                  check={""}
                  register={register}
                  padding={""}
                  disabled={true}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>
              <FieldWrap>
                <PrimaryInput
                  name="customerAccountDescription"
                  type={"text"}
                  placeholder="Customer Account Description"
                  rightIcon={<></>}
                  disabled={true}
                  label="Customer Account Description"
                  check={""}
                  padding={""}
                  register={register}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
                <PrimaryInput
                  name="bvn"
                  type="text"
                  placeholder={"Customer Bvn"}
                  rightIcon={<></>}
                  label="Customer Bvn"
                  disabled={true}
                  check={""}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>

              <FieldWrap>
                <PrimaryInput
                  name={"availableBalance"}
                  type="text"
                  placeholder={"Available Balance"}
                  rightIcon={<></>}
                  label="Available Balance"
                  check={""}
                  disabled={true}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
                <PrimaryInput
                  name={"schemaCode"}
                  type="text"
                  placeholder="Schema Code"
                  rightIcon={<></>}
                  disabled={true}
                  label="Schema Code"
                  check={""}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>

              <ButtonWrapLeft>
                <PrimaryButton
                  background={""}
                  color={""}
                  width={"150px"}
                  title={"View Mandate"}
                  onClick={handleViewMandate}
                  loaderColor="white"
                  loading={false}
                  isLoading={mandateMutationState.isLoading}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  border={""}
                />
              </ButtonWrapLeft>
            </>
          ) : null}
        </Container>

        <Container>
          <HeaderComponent>
            <HeaderText>Beneficiary Information</HeaderText>
            <div style={{ marginTop: "24px", width: "338px" }}>
              <PrimaryInput
                name="beneficiaryAccountNumber"
                type="text"
                rightIcon={<></>}
                placeholder="Enter Account Number"
                error={error?.beneAccNum}
                label="Account Number"
                check={""}
                padding={""}
                leftIcon={<SearchIcon />}
                onChange={(e) => setBeneAccNum(e.target.value)}
                value={beneAccNum}
                register={register}
              />
            </div>
          </HeaderComponent>
          {activeTrack === "beneficiary" && accFetchLoading ? (
            <PuffLoader />
          ) : beneOpenFields && beneAccNum.length === 10 ? (
            <>
              <FieldWrap>
                <PrimaryInput
                  name="beneficiaryAccountName"
                  type={"text"}
                  placeholder="Account Name"
                  rightIcon={<></>}
                  error={undefined}
                  label="Account Name"
                  check={""}
                  padding={""}
                  disabled={true}
                  leftIcon={<InputClose />}
                  onChange={() => {}}
                  value={undefined}
                  register={register}
                />
                <PrimaryInput
                  name="beneficiaryDepositType"
                  type={"text"}
                  placeholder="customerType"
                  rightIcon={<></>}
                  label="Beneficiary Type"
                  check={""}
                  register={register}
                  padding={""}
                  disabled={true}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>
              <FieldWrap>
                <PrimaryInput
                  name="beneficiaryAccountDescription"
                  type={"text"}
                  placeholder="Customer Account Description"
                  rightIcon={<></>}
                  disabled={true}
                  label="Beneficiary Account Description"
                  check={""}
                  padding={""}
                  register={register}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
                <PrimaryInput
                  name="beneficiaryBvn"
                  type="text"
                  placeholder={"Customer Bvn"}
                  rightIcon={<></>}
                  label="Beneficiary Bvn"
                  disabled={true}
                  check={""}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>

              <FieldWrap>
                <PrimaryInput
                  name={"beneficiaryAvailableBalance"}
                  type="text"
                  placeholder={"Available Balance"}
                  rightIcon={<></>}
                  label="Available Balance"
                  check={""}
                  disabled={true}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
                <PrimaryInput
                  name={"beneficiarySchemaCode"}
                  type="text"
                  placeholder="Schema Code"
                  rightIcon={<></>}
                  disabled={true}
                  label="Schema Code"
                  check={""}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>
            </>
          ) : null}
        </Container>

        <Container>
          <HeaderComponent>
            <HeaderText>Funds Transfer</HeaderText>
          </HeaderComponent>
          <FieldWrap>
            <DropDownC
              value={currency}
              placeholder="Select Currency"
              options={currencyType}
              errorMessage={error.currency}
              label={"Currency"}
              setSelectedItem={(value: any) => setCurrency(value)}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />

            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount *"
              check={""}
              padding={""}
              leftIcon={<></>}
              onChange={(e) => {
                const newValue = Math.max(0, parseInt(e.target.value) || 0);
                setAmount(newValue);
              }}
              value={amount}
            />
          </FieldWrap>
          <FieldWrap>
            <PrimaryInput
              name="phoneNumber"
              maxLength={11}
              type="number"
              inputMode="numeric"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error?.phoneNumber}
              label="Depositor’s Phone Number"
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={(e) => setPhone(e.target.value)}
              value={phoneNumber}
            />
            <PrimaryInput
              name={"Narration"}
              type={"string"}
              error={error?.narration}
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
              label="Narration/Remarks"
              placeholder={"Enter description here"}
            />
          </FieldWrap>
          <FieldWrap>
            <DropDownC
              value={selectedInstrumentType}
              placeholder="Instrument Type"
              options={withdrawalType}
              errorMessage={undefined}
              label={"Instrument Type"}
              setSelectedItem={setInstrumentType}
              setSelectedItems={() => {}}
              setOptionValue={() => {}}
            />

            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectDate}
              handleDateChange={(e: any) => setSelectedDate(e.target.value)}
              selectDate={setSelectedDate}
              disabled={!allowedTypes.includes(selectedInstrumentType)}
              label={"Instrument Date"}
              error={error?.selectDate}
            />
          </FieldWrap>
          <FieldWrap>
            <PrimaryInput
              name="chequeNo"
              type="text"
              placeholder="Enter Cheque number"
              rightIcon={<></>}
              error={undefined}
              label="Cheque/Ref No"
              disabled={!allowedTypes.includes(selectedInstrumentType)}
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              value={chequeNo}
              onChange={(e) => setChequeNo(e.target.value)}
            />
          </FieldWrap>
          <FieldWrap>
            <ButtonWrap>
              <PrimaryButton
                background={""}
                type="button"
                color={""}
                width={150}
                title={"Post Transaction"}
                isLoading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onOpenTransDetail}
                border={"#5C2684"}
              />
            </ButtonWrap>

            <FieldWrapD>
              <DetailsWrap>
                <LeftD>
                  <Text>Total Amount:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{amount}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Amount in words:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{formatAmount(currency, amount)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Transaction Date:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{currentDate.toJSON().slice(0, 10)}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Branch Code:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{branchcode}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Paid By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{bankUser}</Text>{" "}
                </RightD>
              </DetailsWrap>
              <DetailsWrap>
                <LeftD>
                  <Text>Cash Received By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{bankUser}</Text>{" "}
                </RightD>
              </DetailsWrap>
            </FieldWrapD>
          </FieldWrap>
        </Container>
      </ComponentWrapper>

      <Modal
        open={transactionStatus}
        closeIcon
        onClose={closeReceipt}
        size="tiny"
        style={{
          width: "483px",
          heigth: "517.5px",
        }}
        title=""
        className=""
        contentPadding="2.5rem"
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {result?.statusCode === 200 || result?.statusCode === 201 ? (
              <SuccessIcon />
            ) : (
              <FailedIcon />
            )}
          </div>

          <p
            style={{
              fontWeight: "700",
              fontSize: "13px",
              margin: "10px 30px",
            }}
          >
            {result?.data?.Message !== undefined
              ? result?.data?.Message
              : result?.message}
          </p>
          {/* {(result?.statusCode === 200 || result?.statusCode === 201) && (
            <PrimaryButton
              background={""}
              color={""}
              width={150}
              title="Generate Receipt"
              loading={false}
              type="button"
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={generateReceipt}
              border={"#5C2684"}
            />
          )} */}
        </div>
      </Modal>
      <Modal
        open={isOpenTransModal}
        closeIcon
        onClose={() => {
          setIsOpenTransModal(false);
        }}
        size="small"
        title=""
        className=""
        contentPadding="3.5rem"
      >
        <KeyValueMapping
          onSubmit={onSubmit}
          title={"Funds Transfer"}
          postWithdrawalLoading={isLoading}
          data={dataReceipt}
        />
      </Modal>

      {/* <Modal
        open={transactionReceipt}
        closeIcon
        onClose={closeReceipt}
        size="tiny"
        style={{
          width: "944px",
          heigth: "auto",
        }}
        title=""
        className=""
        contentPadding="2.5rem"
      >
         
         {
          generatePDF && (
        // <PDFGenerator
        //   componentToRender={ <ReceiptView data={transReceipt}
        //   //onTriggerDownload = {() => setGeneratePDF(true)}
        //   title="Cash Transfer"  />}
        // />
          )
         }
    
       
      </Modal> */}

      {openMandate && imageData !== "" && (
        <>
          <GeneralModal general>
            <Top>
              <TopTitle>Mandate </TopTitle>
              <IconWrap>
                <CloseIcon onClick={handleCloseMandate} />
              </IconWrap>
            </Top>

            <>
              {" "}
              <img src={`data:image/jpeg;base64,${imageData}`} alt="ch" />
            </>
          </GeneralModal>
        </>
      )}
    </>
  );
};
export default FcmbToFcmb;
