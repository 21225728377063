import {
  HiHome,
  HiOutlineUserGroup,
  HiOutlineGlobeAlt,
  HiTrendingUp,
  HiOutlineBadgeCheck,
  HiPencilAlt,
  HiCreditCard,
  HiArchive,
  HiCloudDownload,
  HiOutlineCalendar,
  HiClipboardList,
  HiCloudUpload,
  HiPrinter,
} from "react-icons/hi";

import { SidebarProps } from "../../types/Types";

export const adminData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "TSA Remittance",
    path: "/remittance",
    icon: HiOutlineUserGroup,
  },
  {
    title: "Process",
    path: "/process",
    icon: HiOutlineBadgeCheck,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: HiCloudUpload,
  },
  {
    title: "Admin",
    path: "/admin",
    icon: HiArchive,
  },
];

export const opsSystemControlData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Admin",
    path: "/admin",
    icon: HiArchive,
  },
  {
    title: "User Approval",
    path: "/user-approval",
    icon: HiOutlineCalendar,
  },
];

export const tellerData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Deposits",
    path: "deposit",
    icon: HiCloudDownload,
    subNav: [
      {
        title: "Cash Deposits",
        path: "/cash-deposit",
        icon: HiOutlineCalendar,
      },
    ],
  },
  {
    title: "Withdrawals",
    path: "/withdrawal",
    icon: HiCreditCard,

    subNav: [
      {
        title: "Cash Withdrawals",
        path: "/withdrawal/cash-withdrawal",
        icon: HiCloudDownload,
      },
      {
        title: "Bio-metrics",
        path: "/withdrawal/bio-metrics-withdrawal",
        icon: HiTrendingUp,
      },
    ],
  },
  {
    title: "Transfer",
    path: "/transfer",
    icon: HiTrendingUp,
    subNav: [
      {
        title: "FCMB to FCMB",
        path: "/transfer/fcmb-to-fcmb",
        icon: HiOutlineGlobeAlt,
      },
      {
        title: "FCMB to Others",
        path: "/transfer/fcmb-to-others",
        icon: HiOutlineGlobeAlt,
      },
    ],
  },
  {
    title: "Daily Transaction",
    path: "/daily-transactions",
    icon: HiPrinter,
  },
  {
    title: "Easy Account",
    path: "/easy-account",
    icon: HiPencilAlt,
  },

  {
    title: "Reports",
    path: "/reports",
    icon: HiCloudUpload,
  },
];

export const ftoData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Deposits",
    path: "deposit",
    icon: HiCloudDownload,
    subNav: [
      {
        title: "Cash Deposits",
        path: "/cash-deposit",
        icon: HiOutlineCalendar,
      },
    ],
  },
  {
    title: "Withdrawals",
    path: "/withdrawal",
    icon: HiCreditCard,

    subNav: [
      {
        title: "Cash Withdrawals",
        path: "/withdrawal/cash-withdrawal",
        icon: HiCloudDownload,
      },
      {
        title: "Bio-metrics",
        path: "/withdrawal/bio-metrics-withdrawal",
        icon: HiTrendingUp,
      },
    ],
  },
  {
    title: "Transfer",
    path: "/transfer",
    icon: HiTrendingUp,
    subNav: [
      {
        title: "FCMB to FCMB",
        path: "/transfer/fcmb-to-fcmb",
        icon: HiOutlineGlobeAlt,
      },
      {
        title: "FCMB to Others",
        path: "/transfer/fcmb-to-others",
        icon: HiOutlineGlobeAlt,
      },
    ],
  },

  {
    title: "Easy Account",
    path: "/easy-account",
    icon: HiPencilAlt,
  },

  {
    title: "Reports",
    path: "/reports",
    icon: HiCloudUpload,
  },
];

export const tellerNewData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },
  {
    title: "Deposits",
    path: "deposit",
    icon: HiCloudDownload,
    subNav: [
      {
        title: "Cash Deposits",
        path: "/cash-deposit",
        icon: HiOutlineCalendar,
      },
    ],
  },
  {
    title: "Withdrawals",
    path: "/withdrawal",
    icon: HiCreditCard,

    subNav: [
      {
        title: "Cash Withdrawals",
        path: "/withdrawal/cash-withdrawal",
        icon: HiCloudDownload,
      },
      {
        title: "Bio-metrics",
        path: "/withdrawal/bio-metrics-withdrawal",
        icon: HiTrendingUp,
      },
    ],
  },
  {
    title: "Transfer",
    path: "/transfer/fcmb-to-fcmb",
    icon: HiTrendingUp,
    subNav: [
      {
        title: "FCMB to FCMB",
        path: "/transfer/fcmb-to-fcmb",
        icon: HiOutlineGlobeAlt,
      },
      {
        title: "FCMB to Others",
        path: "/transfer/fcmb-to-others",
        icon: HiOutlineGlobeAlt,
      },
    ],
  },
  {
    title: "Collection",
    path: "/collection",
    icon: HiArchive,
    subNav: [
      {
        title: "Collection",
        path: "/collection",
        icon: HiCloudUpload,
      },
      {
        title: "Special Collection",
        path: "/collection/special-collection",
        icon: HiCloudUpload,
      },
    ],
  },
  {
    title: "Process",
    path: "/process",
    icon: HiOutlineBadgeCheck,
  },
  {
    title: "Daily Transaction",
    path: "/daily-transactions",
    icon: HiPrinter,
  },
  {
    title: "Easy Account",
    path: "/easy-account",
    icon: HiPencilAlt,
  },

  {
    title: "Reports",
    path: "/reports",
    icon: HiClipboardList,
  },
];

export const hopData: SidebarProps[] = [
  {
    title: "Home",
    path: "/",
    icon: HiHome,
  },

  {
    title: "Limit Approvals",
    path: "/limit-approval",
    icon: HiArchive,
    subNav: [
      {
        title: "Deposit Limit",
        path: "/limit-approval/deposit",
        icon: HiCloudDownload,
      },
      {
        title: "Transfer Limit",
        path: "/limit-approval/transfer",
        icon: HiTrendingUp,
      },
      {
        title: "Withdrawal Limit",
        path: "/limit-approval/withdrawal",
        icon: HiTrendingUp,
      },
      {
        title: "Collection Limit",
        path: "/limit-approval/collection",
        icon: HiTrendingUp,
      },
    ],
  },

  {
    title: "Easy Account",
    path: "/easy-account",
    icon: HiPencilAlt,
  },

  {
    title: "Reports",
    path: "/reports",
    icon: HiClipboardList,
  },
];
