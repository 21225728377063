import React, { useState, useCallback } from "react";
import { Input, Show, InputContainer, Error, Label, CheckWrap } from "./style";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { HiOutlineEyeOff } from "react-icons/hi";
import { InputProps } from "../../types/Types";

const PrimaryInput = ({
  name,
  type,
  placeholder,
  rightIcon,
  error,
  padding,
  label,
  value,
  leftIcon,
  onChange,
  password,
  pattern,
  disabled,
  inputMode,
  defaultValue,
  register,
  readOnly,
  maxLength,
  min,
  validator,
}: InputProps) => {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);

  const onActive = useCallback(() => {
    setActive(true);
  }, []);

  const onActiveFalse = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <>
      <CheckWrap>
        <Label>{label}</Label>
        <InputContainer
          border={active ? "1px solid #784b99" : "1px solid #dfe2e6"}
          onFocus={onActive}
          onBlur={onActiveFalse}
          background={disabled ? "#eeeeee" : ""}
        >
          {leftIcon && <>{leftIcon}</>}
          {register ? (
            <Input
              type={!show ? type || "password" : "text"}
              name={name}
              pattern={pattern}
              value={value}
              inputMode={inputMode}
              opacity={disabled ? "0.3" : ""}
              placeholder={placeholder}
              padding={padding}
              onChange={onChange}
              disabled={disabled}
              defaultValue={defaultValue}
              readOnly={readOnly}
              maxLength={maxLength}
              {...register(name, { onChange })}
            />
          ) : (
            <Input
              type={!show ? type || "password" : "text"}
              name={name}
              value={value}
              opacity={disabled ? "0.3" : ""}
              placeholder={placeholder}
              padding={padding}
              onChange={onChange}
              disabled={disabled}
              defaultValue={defaultValue}
              readOnly={readOnly}
              maxLength={maxLength}
              min={min}
            />
          )}

          {password && (
            <div onClick={() => setShow(!show)}>
              <Show>{!show ? <EyeIcon /> : <HiOutlineEyeOff size={24} />}</Show>
            </div>
          )}
          {rightIcon && (
            <div onClick={() => setShow(!show)}>
              <Show>{rightIcon}</Show>
            </div>
          )}
        </InputContainer>
        <Error>
          {validator ? error?.slice(0, error?.indexOf(",")) : error}
        </Error>
      </CheckWrap>
    </>
  );
};
export default PrimaryInput;
