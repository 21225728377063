import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { authApi } from "../services/auth/authService";
import { dashboardApi } from "../services/dashboardAnalysis/dashboardService";
import { transactionApi } from "../services/transaction/transaction";
import { adminApi } from "../services/admin/adminService";
import { persistStore } from "redux-persist";
import { reportsApi } from "../services/reports/reportService";
import { approvalApi } from "../services/approval/approval";
import { collectionApi } from "../services/collection/collection";

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat([
      authApi.middleware,
      dashboardApi.middleware,
      adminApi.middleware,
      reportsApi.middleware,
      transactionApi.middleware,
      approvalApi.middleware,
      collectionApi.middleware,
    ]),
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
