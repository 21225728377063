import React from "react";
import { cardProps } from "../../types/Types";
import {
  CardComponent,
  CardContents,
  CardCount,
  CardCountSpan,
  CardPrice,
  CardTitle,
  CountWrap,
} from "./style";

const TransactionCard: React.FC<cardProps> = ({ data, width, handleClick }) => {
  return (
    <CardComponent key={data.title} width={width} onClick={handleClick}>
      <CardContents>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {data.img}
          <CardTitle>{data.title}</CardTitle>
        </div>

        <CardPrice>NGN {data.amount}</CardPrice>
        <CardCount>
          <CountWrap>
            <CardCountSpan>{data.count}</CardCountSpan>
          </CountWrap>
          Count
        </CardCount>
      </CardContents>
    </CardComponent>
  );
};

export default TransactionCard;
