import React, { useEffect, useState } from "react";
import PrimaryInput from "../../components/PrimaryInput";
import {
  FlexWrap,
  FlexWrapper,
  ModalHeaderText,
  UserButtonWrap,
} from "./styles/styles";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import PrimaryButton from "../../components/Buttons/Button";
//import { usePostNewUserMutation } from "./service/adminApi";
import toast from "react-hot-toast";
//import { uid } from "../../utils/uids";
import { FormProvider, useForm } from "react-hook-form";
import DropDownC from "../../components/PrimaryInput/dropDown";
import {
  useGetRoleQuery,
  useGetRolesQuery,
  useUpdateUserRoleMutation,
} from "../../services/admin/adminService";
import { Oval } from "react-loading-icons";
import { ErrorHandler } from "../../utils/config";

interface PropsType {
  usernameValue?: any;
  roleId?: any;
}
const UpdateUser: React.FC<PropsType> = (usernameValue) => {
  // const [addUser, addUserProps] = usePostNewUserMutation();
  const [roleValue, setRoleValue] = useState("");
  const [newRole, setNewRole] = useState("");
  const [roleName, setRoleName] = useState("");
  const [updateUserRole, { isLoading }] = useUpdateUserRoleMutation();

  const roleNo: any = {
    roleId: usernameValue?.roleId,
  };
  const getRole: any = useGetRoleQuery(roleNo);
  const errorMsg = getRole?.error?.data?.Message;
  const roleTitle = getRole?.data?.data?.roleName;
  useEffect(() => {
    roleTitle !== undefined ? setRoleName(roleTitle) : setRoleName(errorMsg);

    // (
    //     getRole?.isLoading ? setRoleName("Loading...") :
    // )
  }, [errorMsg, roleTitle]);
  //getRole?.isLoading ? setRoleName("Loading...") : setRoleName(errorMsg);

  const { data } = useGetRolesQuery();
  const listofRoles = data
    ? data?.data?.map((item: any) => ({
        value: item.id,
        label: item.roleName,
      }))
    : [];

  const handleUserUpdate = async () => {
    const payload = {
      username: usernameValue?.usernameValue,
      roleId: roleValue,
    };
    try {
      const response = await updateUserRole(payload).unwrap();
      toast.success(response?.data?.Message);
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  return (
    <div>
      <ModalHeaderText>Update User</ModalHeaderText>
      <FormProvider {...methods}>
        <form>
          <FlexWrapper>
            <PrimaryInput
              name=""
              type="text"
              placeholder="Username"
              rightIcon={<></>}
              error={undefined}
              label="User Name"
              check={""}
              disabled={true}
              padding={""}
              leftIcon={<InputClose />}
              value={usernameValue?.usernameValue}
              onChange={() => {}}
              // register={register}
            />
            <PrimaryInput
              name=""
              type="text"
              placeholder="Role"
              rightIcon={<></>}
              error={undefined}
              label="Role"
              check={""}
              padding={""}
              disabled={true}
              leftIcon={<InputClose />}
              value={roleName}
              onChange={() => {}}
              // register={register}
            />
            <DropDownC
              value={newRole}
              placeholder={"Select Roles"}
              options={listofRoles}
              errorMessage={""}
              label={"Update Role"}
              setOptionValue={setRoleValue}
              setSelectedItem={() => {}}
              //onChange={(e: any) => {handleUserId(selectedRoles?.value)}}
              setSelectedItems={setNewRole}
            />
          </FlexWrapper>

          <UserButtonWrap>
            <PrimaryButton
              background={""}
              color={""}
              width="150px"
              title="Update User"
              loading={false}
              type="submit"
              isLoading={isLoading}
              leftIconName={<></>}
              rightIconName={<></>}
              onClick={handleUserUpdate}
              border={"#5C2684"}
            />
          </UserButtonWrap>
        </form>
      </FormProvider>
    </div>
  );
};

export default UpdateUser;
