import React, { useCallback, useEffect, useState, useMemo } from "react";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import {
  ButtonWrap1,
  Container,
  FieldWrap,
  ComponentWrapper,
  HeaderText,
  ButtonWrap,
  Input,
  Radio,
  LeftD,
  RightD,
  Text,
  FieldWrapD,
  DetailsWrap,
  Top,
  TopTitle,
  IconWrap,
  InputDiv,
  Instruction,
  AmountFieldWrap,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import {
  collectionInfoData,
  collectionyType,
  ErrorHandler,
} from "../../utils/config";
import TopCards from "../../components/Cards/TopCards";
import toast from "react-hot-toast";
import { CollectionInfoProp } from "../../types/Types";
import {
  usePostDangoteMutation,
  useValidateCustomerMutation,
} from "../../services/collection/collection";
import GeneralModal from "../../components/Modal/GeneralModal";
import { TransactionRequest } from "../../services/collection/type";
import MultipleDropDown from "../../components/PrimaryInput/multiDropDown";

const Process = () => {
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedBox, setSelectedBox] = useState<string>("ID");
  const [customerInfo, setCustomerInfo] = useState<any>({});
  const [customerNo, setCustomerNumber] = useState<string>("");

  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [error, setError] = useState<any>({});
  const [responseName, setResponseName] = useState<string>("");
  const [details, setDetails] = useState<TransactionRequest>({});
  const [amount, setAmount] = useState<string>("");
  const [isOpenTransModal, setIsOpenTransModal] = useState(false);
  const [lists, setList] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [customerCode, setCustomerCode] = useState("");
  const [collectionInfo, setCollectionInfo] =
    useState<CollectionInfoProp>(collectionInfoData);
  const [debitAccountNumber, setAccountNumber] = useState<string>("");

  const [validateCharge] = useValidateCustomerMutation();
  const [postTransaction, postMutation] = usePostDangoteMutation();

  const copyToClipBoard = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      toast.success(" copied successfully!");
    } catch (err) {
      toast.error("Failed to copy!");
    }
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionInfo((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCustomerSearch = async () => {
    try {
      const requiredValue = {
        category: selectedType,
        customerNumber: customerNo.toString(),
      };

      const response = await validateCharge(requiredValue).unwrap();
      toast.success(response?.message);
      setResponseName(response?.data);
      setCustomerCode(requiredValue?.customerNumber);
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const payload = useMemo(
    () => ({
      depositorName: responseName,
      customerCode: customerNo,
      tellerNum: collectionInfo?.tellerNum,
      depositorPhoneNo: collectionInfo?.depositorPhoneNo,
      debitAccountNumber: debitAccountNumber,
      amount: parseInt(amount),
      category: selectedType,
    }),
    [collectionInfo, amount, selectedType, responseName, customerNo]
  );

  const closeReceipt = () => {
    setResponseName("");
    setCustomerCode("");
    setAccountNumber("");
    setCollectionInfo({
      ...collectionInfo,
      tellerNum: "",
      depositorPhoneNo: "",
    });
    setAmount("");
    setCustomerNumber("");
  };

  const onOpenTransDetail = useCallback(() => {
    const { depositorPhoneNo, tellerNum } = collectionInfo;
    const updatedError: any = {};

    if (!amount) {
      updatedError.amount = "Amount is required, SELECT DENOMINATION ALSO";
    }

    if (!debitAccountNumber) {
      updatedError.debitAccountNumber = "Account number is required";
    }
    if (!responseName) {
      updatedError.responseName = "Depositor's name is required";
    }
    if (!customerCode) {
      updatedError.customerNumber = "Customer's code is required";
    }
    if (!tellerNum) {
      updatedError.tellerNum = "teller number is required";
    }
    if (!depositorPhoneNo || depositorPhoneNo.length !== 11) {
      updatedError.depositorPhoneNo = "Phone is required, 11 digits";
    }

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    setError(updatedError);

    if (Object.keys(updatedError).length === 0) {
      setDetails(payload);
      setIsOpenTransModal(true);
      setIsDisabled(true);
    }
  }, [customerInfo, payload, amount]);

  const onSubmit = async () => {
    try {
      const response = await postTransaction(payload).unwrap();

      if (response.statusCode === 200) {
        setIsOpenTransModal(false);
        toast.success(response?.message);
        closeReceipt();
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else if (error?.status === 500) {
        ErrorHandler(error);
      } else {
        ErrorHandler(error);
      }
    }
  };

  const handleClose = () => {
    setIsOpenTransModal(false);
  };

  const handleChange = (e: any) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedBox(value);
    }
  };

  const handleMultipleClick = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      if (!checkedItems.includes(value)) {
        setCheckedItems((prev) => [...prev, value]);
        collectionyType.forEach((list: any) => {
          if (value === list.label) {
            setList((prev: any) => [...prev, list?.value]);
          }
        });
      }
    } else {
      setCheckedItems((prev) => prev.filter((item) => item !== value));
      collectionyType?.forEach((list: any) => {
        if (value === list.label) {
          setList((prev: any) => {
            return [...prev.filter((item: any) => item !== list.value)];
          });
        }
      });
    }
  };

  return (
    <>
      <Header title="Prrocess Transaction" />
      <ComponentWrapper>
        <TopCards />

        <Container>
          <HeaderText>Transaction</HeaderText>
          <FieldWrap>
            <DropDownC
              value={selectedType}
              placeholder={"Select Type"}
              options={collectionyType}
              errorMessage={""}
              setOptionValue={() => {}}
              label={"Merchant Type"}
              setSelectedItem={setSelectedType}
              setSelectedItems={() => {}}
            />
          </FieldWrap>

          <Instruction>
            Payment Options
            <InputDiv>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="ID"
                  checked={selectedBox === "ID"}
                  onChange={handleChange}
                />
                  <label>ID</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="No ID"
                  onChange={handleChange}
                />
                  <label>No ID</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="Accessment Reference"
                  onChange={handleChange}
                />
                  <label>Accessment Reference</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="Invoice Number"
                  onChange={handleChange}
                />
                  <label>Invoice Number</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="Payment Code"
                  onChange={handleChange}
                />
                  <label>Payment Code</label>
              </Radio>
            </InputDiv>
          </Instruction>

          {selectedBox !== "No ID" && (
            <FieldWrap>
              <PrimaryInput
                name={selectedBox}
                type={"text"}
                placeholder={`Enter ${selectedBox}`}
                rightIcon={<></>}
                error={""}
                disabled={undefined}
                label={selectedBox}
                check={""}
                padding={""}
                leftIcon={<UserIcon />}
                value={undefined}
                // onChange={handleInputChange}
                password={false}
              />
              <ButtonWrap>
                <div>
                  <PrimaryButton
                    background={""}
                    type="button"
                    color={""}
                    width={150}
                    title={"Retrieve"}
                    loading={false}
                    leftIconName={<></>}
                    rightIconName={<></>}
                    onClick={onOpenTransDetail}
                    border={"#5C2684"}
                  />
                </div>
              </ButtonWrap>
            </FieldWrap>
          )}

          {selectedBox === "No ID" && (
            <>
              <FieldWrap>
                <PrimaryInput
                  name="fullName"
                  type="text"
                  placeholder="Full Name"
                  rightIcon={<></>}
                  // error={error.fullName}
                  label="Full Name"
                  check={""}
                  padding={""}
                  leftIcon={<UserIcon />}
                  // onChange={onHandleChange}
                  // value={accountOwner ? withdrawal : customerInfo?.depositorName}
                />
                <PrimaryInput
                  name="phoneNo"
                  type="text"
                  placeholder="Phone Number"
                  rightIcon={<></>}
                  // error={error.fullName}
                  label="phoneNo"
                  check={""}
                  padding={""}
                  leftIcon={<UserIcon />}
                  // onChange={onHandleChange}
                  // value={accountOwner ? withdrawal : customerInfo?.depositorName}
                />
              </FieldWrap>

              <FieldWrap>
                <PrimaryInput
                  name="email"
                  type="text"
                  placeholder="email"
                  rightIcon={<></>}
                  // error={error.fullName}
                  label="Email"
                  check={""}
                  padding={""}
                  leftIcon={<UserIcon />}
                  // onChange={onHandleChange}
                  // value={accountOwner ? withdrawal : customerInfo?.depositorName}
                />
                <PrimaryInput
                  name="address"
                  type="text"
                  placeholder="Address"
                  rightIcon={<></>}
                  // error={error.fullName}
                  label="Address"
                  check={""}
                  padding={""}
                  leftIcon={<UserIcon />}
                  // onChange={onHandleChange}
                  // value={accountOwner ? withdrawal : customerInfo?.depositorName}
                />
              </FieldWrap>
              <ButtonWrap1>
                <PrimaryButton
                  background={"#5c2684"}
                  color={"#fff"}
                  width={150}
                  title={"Validate"}
                  icon={""}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={handleCustomerSearch}
                  leftIcon={false}
                  border={""}
                />
              </ButtonWrap1>
            </>
          )}
        </Container>

        <Container>
          <HeaderText>Transaction Information</HeaderText>
          <FieldWrap>
            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount  *"
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setAmount(value);
              }}
              value={amount}
            />

            <ButtonWrap>
              <div>
                <PrimaryButton
                  background={""}
                  type="button"
                  color={""}
                  width={150}
                  title={"Retrieve"}
                  loading={false}
                  leftIconName={<></>}
                  rightIconName={<></>}
                  onClick={onOpenTransDetail}
                  border={"#5C2684"}
                />
              </div>
            </ButtonWrap>
          </FieldWrap>
          <FieldWrap>
            <MultipleDropDown
              name={""}
              type={""}
              placeholder={""}
              options={collectionyType}
              list={lists}
              handleClick={handleMultipleClick}
              checkedItems={checkedItems}
            />
          </FieldWrap>
          <AmountFieldWrap>
            {lists?.length > 0 && (
              <>
                {lists?.map((item: any) => (
                  <>
                    <PrimaryInput
                      name={item}
                      type="number"
                      inputMode="numeric"
                      placeholder={`Enter ${item} amount`}
                      rightIcon={<></>}
                      error={error?.amount}
                      label={` ${item} amount`}
                      check={""}
                      padding={""}
                      leftIcon={<InputClose />}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        setAmount(value);
                      }}
                      value={item}
                    />
                  </>
                ))}
              </>
            )}
          </AmountFieldWrap>
          <Instruction>
            Payment Methods
            <InputDiv>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="internal"
                  // checked={selectedType === "ID"}
                  onChange={handleChange}
                />
                  <label>Cash Transfer</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="internal"
                  // checked={selectedType === "ID"}
                  onChange={handleChange}
                />
                  <label>Internal Transfer</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="own"
                  onChange={handleChange}
                />
                  <label>Own Bank Cheque</label>
              </Radio>
              <Radio>
                <Input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="other"
                  onChange={handleChange}
                />
                  <label>Other Bank Cheque</label>
              </Radio>
            </InputDiv>
          </Instruction>

          <FieldWrap>
            <PrimaryInput
              name="accountNumber"
              type="text"
              rightIcon={<></>}
              placeholder="Deposit Slip|Cheque No"
              error={error?.debitAccountNumber}
              label="Deposit Slip|Cheque No"
              check={""}
              padding={""}
              leftIcon={<InputClose />}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setAccountNumber(value);
              }}
              value={undefined}
            />
          </FieldWrap>
          <ButtonWrap>
            <div>
              <PrimaryButton
                background={""}
                type="button"
                color={""}
                width={150}
                title={"Post Transaction"}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onOpenTransDetail}
                border={"#5C2684"}
              />
            </div>
          </ButtonWrap>
        </Container>
      </ComponentWrapper>

      {isOpenTransModal && (
        <GeneralModal general>
          <Top>
            <TopTitle>Collection Transaction</TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleClose} />
            </IconWrap>
          </Top>
          <FieldWrapD>
            <DetailsWrap>
              <LeftD>
                <Text>Payment Type</Text>{" "}
              </LeftD>
              <RightD>
                <Text>Dangote - {details?.category}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Depositor's Name</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.depositorName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Customer Code</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.customerCode}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Teller Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.tellerNum}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Currency Type</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.currencyType}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Account Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text>{details?.debitAccountNumber}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Phone Number</Text>{" "}
              </LeftD>
              <RightD>
                <Text> {details?.depositorName}</Text>{" "}
              </RightD>
            </DetailsWrap>
            <DetailsWrap>
              <LeftD>
                <Text>Amount</Text>{" "}
              </LeftD>
              <RightD>
                <Text>
                  {" "}
                  {new Intl.NumberFormat("ja-JP", {
                    style: "currency",
                    currency: details?.currencyType,
                  }).format(details?.amount as any)}
                </Text>{" "}
              </RightD>
            </DetailsWrap>
          </FieldWrapD>
          <Radio>
            <Input
              type="radio"
              id="html"
              name="fav_language"
              value="acc"
              onChange={handleChange}
            />
             {" "}
            <label>
              I have read through all details displayed above and have ensured
              the information is accurate.
            </label>
          </Radio>
          <ButtonWrap>
            <div>
              <PrimaryButton
                background={"#5c2684"}
                color={"#fff"}
                width={100}
                title={"Post Transaction"}
                icon={""}
                isLoading={postMutation?.isLoading}
                loading={false}
                isDisabled={isDisabled}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onSubmit}
                leftIcon={false}
                border={""}
              />
            </div>
          </ButtonWrap>
        </GeneralModal>
      )}
    </>
  );
};

export default Process;
