import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Content = styled.div`
  width: 500px;
`;
export const BtnHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const CloseWrapper = styled.div`
  cursor: pointer;
`;
export const Top = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;
export const ModalTexts = styled.h4`
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-align: center;
  padding: 15px;
  color: #2d2235;
  font-size: 28px;
`;
export const BodyText = styled.p`
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 15px;
  color: #2d2235;
`;
