import React from "react";
import { Top, TopTitle, IconWrap } from "../../pages/CashWithdrawals/style";
import GeneralModal from "./GeneralModal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { ReactComponent as SuccessIcon } from "../../assets/svg/transactionSuccess.svg";
import PrimaryButton from "../Buttons/Button";

interface SuccessProps {
  handleClose?: any;
  message?: string;
  approval?: boolean;
  generateReceipt?: any;
}
const SuccessModal: React.FC<SuccessProps> = ({
  handleClose,
  message,
  approval,
  generateReceipt,
}) => {
  return (
    <GeneralModal general>
      <Top>
        <TopTitle></TopTitle>
        <IconWrap>
          <CloseIcon onClick={handleClose} />
        </IconWrap>
      </Top>

      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <SuccessIcon />
        </div>

        <p
          style={{
            fontWeight: "700",
            fontSize: "29px",
            margin: "10px 30px",
          }}
        >
          {message}
        </p>
        {approval && (
          <PrimaryButton
            background={""}
            color={""}
            width={150}
            title="Generate Receipt"
            loading={false}
            type="button"
            leftIconName={<></>}
            rightIconName={<></>}
            onClick={generateReceipt}
            border={"#5C2684"}
          />
        )}
      </div>
    </GeneralModal>
  );
};

export default SuccessModal;
