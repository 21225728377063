import { combineReducers } from "@reduxjs/toolkit";
import { authApi } from "../services/auth/authService";
import { StaffDataReducer } from "../services/auth/authSlice";
import { dashboardApi } from "../services/dashboardAnalysis/dashboardService";
import { adminApi } from "../services/admin/adminService";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { reportsApi } from "../services/reports/reportService";
import { transactionApi } from "../services/transaction/transaction";
import { approvalApi } from "../services/approval/approval";
import { collectionApi } from "../services/collection/collection";

const persistConfig = {
  key: "StaffDataReducer", // This is a unique identifier for your slice
  storage, // The storage engine to use (e.g., localStorage or AsyncStorage for React Native)
};
const persistedReducer = persistReducer(persistConfig, StaffDataReducer);

export default combineReducers({
  // openSlice,
  // postSlice,
  StaffDataReducer: persistedReducer,
  [authApi.reducerPath]: authApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [approvalApi.reducerPath]: approvalApi.reducer,
  [collectionApi.reducerPath]: collectionApi.reducer,
});
